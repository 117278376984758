import { BlinktextAnimation } from "./BlinkText"


export const HomeIntroduction =()=> {
    return (
        <section className="home-introduction">
            <div className="container">
                <div className="position-relative" style={{zIndex:'1'}}>
                {/* <h3 className="m-0 d-flex justify-content-end">+919985241238</h3> */}
                   <h4 className="m-0 heading-color">Innovate,</h4>
                   <h4 className="heading-color">Transform, Succeed</h4>
                </div>
                <div className="welcome-text">Welcome</div> 
                <div className="welcome-text d-flex gap-5">To <span className="heading-color">TECH...</span>  <BlinktextAnimation/> </div>
                {/* <div className="d-flex gap-2 mt-4 position-relative justify-content-end" style={{zIndex:'1'}}>
                    <img src="images/linkedin.png" alt="" style={{ width: "48px" }} />
                    <img src="images/facebook.png" alt="" style={{ width: "48px" }} />
                    <img src="images/instagram.png" alt="" style={{ width: "48px" }} />
                </div> */}
            </div>
        </section>
    )
}