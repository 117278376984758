export const reacttech = {
  header : 'React Development Services',
  content: `RaiseProSoft excels in providing a comprehensive array of React development services, backed by an extensive team of experts dedicated to adopting a customer-focused approach to bring your ideas to life.`,
  bgimg : '/images/react-banner.webp',
  abouttech: `As a leading React development company, RaiseProSoft adheres to the best coding standards such as ECMAScript and W3C to craft optimal solutions with appealing user interfaces and immersive user experiences. Our React developers excel at delivering feature-rich applications that are interactive, high-performing, dynamic, scalable, and compatible across all platforms and devices.`,
  servicesheader: 'React Development Services',
  servicecontent: `RaiseProSoft offers a wide range of React development services, each tailored to meet the unique needs and challenges of your organization.`,
  techexperiencebg : '/images/node-js-development-experience-banner.webp',
  techexperience: 'React Development Experience',
  techexperiencecontent: `Our enduring experience in catering to different domains has assisted businesses to develop scalable and sustainable applications with fully managed React development services`,
  advancestech: 'Advanced React Applications',
  advancestechcontent: `Modern and advanced real-world app development services from scratch to end using full-stack advanced React development framework`
}
export const technologies = [
{
  title: "Custom App Development",
  img: "/images/custom_appl_development.svg",
  description: "RaiseProSoft customizes solutions to suit your specific business requirements by utilizing ReactJS's flexibility and adaptability. This approach enables the creation of high-performing, scalable custom solutions.",
  bulletPoints: [
    "Flexible",
    "Scalable",
    "Tailored"
  ]
},
{
  title: "API Development and Integration",
  img: "/images/api_development_and_integration.svg",
  description: "Our React development services specialize in crafting secure APIs across key web protocols (REST, JSON, HTTP/HTTPS, XML, SOAP, and more), enabling seamless integration of third-party.",
  bulletPoints: [
    "Secure",
    "Versatile",
    "Integrative"
  ]
},
{
  title: "Custom UI/UX using React",
  img: "/images/ui_ux_design.svg",
  description: "RaiseProSoft’s UI/UX designers are dedicated to crafting attractive and responsive React applications. By designing custom UI components, we ensure an intuitive interface that enhances user interaction and experience.",
  bulletPoints: [
    "Intuitive",
    "Responsive",
    "Customized"
  ]
},
{
  title: "Web App Modernization",
  img: "/images/web_app_modernization.svg",
  description: "Upgrade to React's modern environment to enhance your application. Our specialists employ advanced tools including Material-UI, Tailwind, and Prime React, guaranteeing an intuitive user experience.",
  bulletPoints: [
    "Modernize",
    "Enhance",
    "Streamline"
  ]
},
{
  title: "Maintenance and Support",
  img: "/images/maintenance-and-support.svg",
  description: "Our React development services extend to maintaining and supporting your app to ensure its stability and longevity. It includes providing version updates, reducing downtimes, fixing bugs, and adding new features.",
  bulletPoints: [
    "Stability",
    "Enhancement",
    "Continuous Support"
  ]
},
{
  title: "Staff Augmentation",
  img: "/images/staff_augmentation.svg",
  description: "Our IT staff augmentation services empower businesses with proficient and adept React developers to enhance their software development capabilities and tap into the flexible and agile app development process.",
  bulletPoints: [
    "Proficient React Developers",
    "Flexible Engagement Model",
    "Agile App Development"
  ]
}
]
export const techstack = [
{
  title: "Core Libraries and Frameworks",
  icon: "/images/core-lib-blue-tech-ic.svg",
  items: [
    "React.js",
    "Next.js"
  ]
},
{
  title: "Backend Frameworks",
  icon: "/images/backend-blue-tech-ic.svg",
  items: [
    ".NET",
    "JAVA",
    "Node",
    "PHP",
    "RoR"
  ]
},
{
  title: "UI Frameworks",
  icon: "/images/ui-frameworks-blue-tech-ic.svg",
  items: [
    "Material-UI",
    "React Bootstrap",
    "Tailwind",
    "Prime React"
  ]
},
{
  title: "State Management",
  icon: "/images/state-manage-blue-tech-ic.svg",
  items: [
    "Context API",
    "Redux Toolkit",
    "Redux Thunk",
    "Redux-Saga"
  ]
},
{
  title: "Code Handling and Optimization",
  icon: "/images/code-optimization-blue-tech-ic.svg",
  items: [
    "TypeScript",
    "ESLint",
    "Prettier"
  ]
},
{
  title: "QA and Testing",
  icon: "/images/qa-testing-blue-tech-ic.svg",
  items: [
    "React Testing Library",
    "Jest",
    "Cypress",
    "Playwright",
    "Selenium",
    "Postman",
    "Apache JMeter",
    "TestRail"
  ]
},
{
  title: "Routing and Form Management",
  icon: "/images/routing-management-blue-tech-ic.svg",
  items: [
    "React Router",
    "Formik",
    "React Hook Form",
    "Yup"
  ]
},
{
  title: "Data Visualization",
  icon: "/images/data-visualization-blue-tech-ic.svg",
  items: [
    "React Charts",
    "ReCharts"
  ]
},
{
  title: "Cloud Hosting",
  icon: "/images/cloud-hosting-blue-tech-ic.svg",
  items: [
    "AWS",
    "Azure",
    "Google Cloud Platform"
  ]
},
{
  title: "Project Management Tools",
  icon: "/images/project-management-blue-tech-ic.svg",
  items: [
    "Github",
    "BitBucket",
    "Code Commit",
    "Jira",
    "Azure DevOps",
    "Trello"
  ]
},
{
  title: "Other Tools and Libraries",
  icon: "/images/other-blue-tech-ic.svg",
  items: [
    "Storybook",
    "React i18next",
    "Lodash",
    "Moment",
    "PWA"
  ]
}
];



export const services = [
  "Custom Web Development",
          "Custom Mobile Apps Development",
          "React Portal Development",
          "React Plug-ins Development",
          "React Enterprise Solution",
          "React API Integrations",
          "Ecommerce Application Development",
          "React Social and Interactive Apps",
];

export const whyReproSoftData = [
{
  title: "Talented React Developers",
  description: "Hire experienced React developers who can navigate through complex challenges. Our React professionals adhere to industry best practices and skillfully leverage modern tools and technologies to deliver high-quality code.",
  imgSrc: "/images/talented-react-dev-icon.svg",
  altText: "Talented React Dev"
},
{
  title: "Customized React Solutions",
  description: "RaiseProSoft has a large team of industry and technology-specific experts who excel at delivering tailored React solutions that align perfectly well with your unique project requirements and business goals.",
  imgSrc: "/images/customize-react-sol-icon.svg",
  altText: "Customized React Sol"
},
{
  title: "Robust Security",
  description: "At RaiseProSoft, security is a top priority. Our QA experts conduct thorough testing of the app and implement security measures to safeguard users' data and address potential vulnerabilities.",
  imgSrc: "/images/robust-security-icon.svg",
  altText: "Robust Security"
},
{
  title: "Faster Onboarding",
  description: "Maximize the efficiency and minimize the development time with a pre-vetted team that is ready to hit the ground running, understand your project, and seamlessly integrate into your workflow.",
  imgSrc: "/images/faster-onboarding-icon.svg",
  altText: "Faster Onboarding"
}
];
