export const clouddevtech = {
  header : 'Cloud & DevOps',
  content: `We offer Cloud and DevOps services that can help your company become more agile by accelerating the process of software development and increasing software quality and productivity through Continuous Integration and Continuous Delivery.`,
  bgimg : '/images/cloud-devops-banner.webp',
  abouttech: `From software development to release & hosting, cloud is utilized in a vast array of business fields. Each implementation requires a unique execution strategy, which is where our extensive industry expertise and skill in bringing the finest technology to practical systems brings desired results.`,
  abouttech2: 'DevOps is one of the ideal illustrations of agile software development. DevOps arose from the necessity to catch up with the rising software acceleration using an agile approach. Its principles implement the extended agile development methodology to streamline the flow of software development & maintenance through the construction, testing, delivery, and various phases of service. It enables the cross-functional teams to take full responsibility for the software product, from design to deployment and maintenance & support.',
  servicesheader: 'Cloud & DevOps Services',
  servicecontent: `We provide the best Cloud and DevOps Services to business leaders and help them to improve agility and drive efficiency`,
  techexperiencebg : '/images/sharepoint-experience-banner.webp',
  techexperience: 'What do We Offer?',
}
export const technologies = [
{
  title: "Cloud Architecture & Design",
  img: "/images/cloud-architecture.svg",
  description: "We utilize the potential of the cloud platforms to design and develop the cloud architecture in order to utilize cloud native features, and also offer fully managed service to build scalable, resilient, and agile cloud solutions."
},
{
  title: "DevOps Implementation",
  img: "/images/cloud-devops-services.svg",
  description: "We examine your on-going practices, and infrastructure to decide the best tools and approach for the transition. We help to structure the success criteria for your business through successful DevOps implementation."
},
{
  title: "Cloud-Native Application Development",
  img: "/images/cloud-native-app.svg",
  description: "We help you to build, run and maintain scalable and resilient cloud native applications using microservices architecture hosted on cloud platforms to take advantage of the distributed workloads and native services."
},
{
  title: "Cloud Migration",
  img: "/images/cloud-migration.svg",
  description: "We assist you in accelerating your cloud migration with almost no interruption. Our professionals harness data with the greatest analytic tools and uncover new business possibilities to help you cut migration risks, time, and expense."
},
{
  title: "Infrastructure Automation",
  img: "/images/cloud-infrastructure.svg",
  description: "We help your business to reduce human interaction with IT systems and infrastructure including servers, storage, network, etc. by automating the processes through various automation tools, repeatable scripts and functions."
},
{
  title: "Maintenance and Support",
  img: "/images/cloud-maintenance-and-support.svg",
  description: "Our Support and Maintenance services aim to keep developed solutions available and compatible with your current business needs and market trends. We provide continuous support for process improvement, maintenance and bug resolution."
}
]
export const techstack = [
{
  title: "SharePoint",
  icon: "/images/sharepoint-blue-tech-ic.svg",
  items: ["SharePoint Server", "Office 365"]
},
{
  title: "Database",
  icon: "/images/database-blue-tech-ic.svg",
  items: ["SQL Server"]
},
{
  title: "Power Platform",
  icon: "/images/power-platform-blue-tech-ic.svg",
  items: ["Power Automate", "Power Apps", "Power BI", "Power Virtual Agent"]
},
{
  title: "Microsoft Azure",
  icon: "/images/micro-azure-blue-tech-ic.svg",
  items: ["Active Directory", "Function", "App Service", "Azure Storage"]
},
{
  title: "Other Frameworks",
  icon: "/images/other-framework-blue-tech-ic.svg",
  items: ["React", "TypeScript", "Node", "jQuery", "C#"]
},
{
  title: "Testing",
  icon: "/images/testing-blue-tech-ic.svg",
  items: ["Selenium", "Katalon Test Studio", "Test Rail", "Apache JMeter", "OWASP ZAP", "BrowserStack", "Postman"]
},
{
  title: "Project Management Tool",
  icon: "/images/project-management-blue-tech-ic.svg",
  items: ["Jira", "Slack", "Trello", "Microsoft Team"]
}
];



export const services = [
  "DevOps engineers who comprehend your company, supervised by a team of technical experts.",
  "A collection of CloudFormation or Terraform templates for constructing best-practice infrastructure stack.",
  "Using best analysis and monitoring tools like ELK (Elasticsearch, Logstash, and Kibana) and many others with the power to identify and eliminate failures.",
  "Robust security, including alarms to monitor user permissions and auditing features to maintain regulatory compliance (NIST, PCI, HIPAA, etc.)",
  "Periodic improvements in services, including the fine tuning of workloads and the construction of CI/CD (Continuous Integration and Continuous Delivery) pipelines",
  "Cloud optimization services that include evaluations and DevOps best practices to save expenses and accelerate the deployment of improvements.",
  "Automation of cloud infrastructure processes by using best devops practices.",
  "Bespoke DevOps work and adaptable, scalable price solutions to meet your specific DevOps needs.",
];

export const whyReproSoftData = [
{
  title: "Flexible Engagement Model",
  description: "With a unique amalgamation of skilled engineers and cutting-edge technologies, we enable transparency and scalability at all engagement levels.",
  imgSrc: "/images/flexible_engagement_model.svg",
  altText: "flexible_engagement_model"
},
{
  title: "Progressive Roadmap",
  description: "We are a result-driven software development company that meticulously comprehends details of the client’s project and offers the best-fit technology Solution.",
  imgSrc: "/images/progressive_roadmap.svg",
  altText: "progressive_roadmap"
},
{
  title: "Scalable Teams",
  description: "With the changing demands of projects, we scale up proficient resources to meet your custom business demand and generate profitable results.",
  imgSrc: "/images/scalable_teams.svg",
  altText: "scalable_teams"
},
{
  title: "Robust Architecture",
  description: "We keep up a pragmatic approach to software architecture and design and develop a component-based robust architecture that fits in for the business on a longer run.",
  imgSrc: "/images/robust_information_architecture.svg",
  altText: "robust_information_architecture"
}
];

export const processSteps = [
  {
    step: "01",
    title: "Discovery",
    description: "Product discovery is the first stage and the baseline for any application development. In this phase, our Cloud & DevOps team researches and defines the core objectives of the project.",
    class: "heading-part",
    bulletPoint: ['Jira Product Discovery','Azure DevOps (TFS)']
  },
  {
    step: "02",
    title: "Plan",
    description: "Planning phase lets development and operations teams divide their work into smaller, more manageable pieces for faster deployments. This allows developers to learn sooner from the users and optimize the product based on their review.",
    class: "heading-part green-box",
    bulletPoint: ['Jira Software','Slack','Confluence']
  },
  {
    step: "03",
    title: "Build",
    description: "We use tools like Kubernetes and Docker to build individual development and test environments. These disposable replicas of the production environment that help us to speed up the overall development process.",
    class: "heading-part purple-box",
    bulletPoint: ['Kubernetes','Docker','CloudFormation','Bitbucket','Github ']
  },
  {
    step: "04",
    title: "Test",
    description: "Early and frequent test automation can improve software quality and decrease overall risk. Multiple instances of tests may be executed by development teams. In addition, we  provide reports and trend graphs that aid in identifying problematic locations.",
    class: "heading-part orange-box",
    bulletPoint: ['Selenium','Katalon','JMeter','Mocha','Chai']
  },
  {
    step: "05",
    title: "Continuous Delivery",
    description: "We follow a practice that enables software development teams to deploy and deliver incremental changes to the production environment quickly and regularly with the help of following tools:",
    class: "heading-part blue-box",
    bulletPoint: ['AWS CodeDeploy',
      'Azure DevOps',
      'AWS CodeBuild',
      'Jenkins',
      'AWS Pipeline']
  }
];