import React from "react";
import { Header } from "../header";
import { Footer } from "../home/footer";



export const Education = () => {
  return (
    <React.Fragment>
      <Header />

      <section className="testing-section product-section">
        <div className="container">
          <div className="banner-content-wrapper">
            <div className="container">
              <div className="banner-content text-center">
              <h1 className="text-white">
                Education Software Development
                </h1>
                <p className="text-white">
                Shape the minds of future leaders by collaborating with us to develop products that change the way we learn.
                </p>
                </div>
            </div>
          </div>
        </div>
      </section>

      <section>
     <div class="container">
    <div class="industry-pension-fund-wrapper">
        <div className="row">
                  <div className="col-xl-5 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        
                      >
                        <h2 className="industry-head-line">Our Expertise</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        >
                        <p>
                        There is a comprehensive shift in the education industry globally with the emergence of e-learning technologies. Our team of educational 
                        software development experts create e-learning solutions that change the course of learning for education institutes, education content creators, 
                        and students. Schools can be managed more efficiently, and education can be improved with regular involvement and awareness of parents. Our eLearning 
                        solutions also enable higher education institutes to provide offline learning for a geographically distributed student base using web applications as well as mobile apps.
                        </p>
                      </div>
                    </div>
                  </div>
        </div>
    </div>
    <div className="fin-background pt-4 pb-4 mt-5">
              <div>
                <p style={{textAlign:"center", fontSize:"25px", color:"white"}}>
                Elearning market has surpassed 200 billion dollars and is growing at over 8% CAGR.
                </p>
              </div>
    </div>
</div>
     </section>

     <section className="energy-bgimg2">
     <div class="container">
    <div class="industry-pension-fund-wrapper">
        <div className="row">
                  <div className="col-xl-5 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                      >
                        <h2 className="industry-head-line">Learning Management System</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        >
                        <p>With the aim of ensuring that the educational content is delivered conveniently to the ones who learn and who educate, 
                          we develop feature-rich and interactive custom education software. Our education software services help you build an education 
                          ecosystem that seamlessly integrates all the processes of planning and creation of educational and training modules, conducting 
                          online courses and making possible the process of imparting education virtually. Strategically designed tools help teachers and 
                          students to participate and collaborate in projects and discussions.</p>
                      </div>
                    </div>
                  </div>
                </div>
        <div class="pension-bottom-block pt-5">
            <ul class="inner-page-listing two-column">
              <li><p>Course catalog management with advanced search options</p></li>
              <li><p>Test preparation by professors with different question types</p></li>
              <li><p>Enrollment into system for professors and students</p></li>
              <li><p>Online test and evaluation by professors</p></li>
              <li><p>Students enrollment with course creation</p></li>
              <li><p>Company module for employee enrolment</p></li>
              <li><p>Professor account with the ability to add colleagues to the platform as author or tutor and share the responsibility</p></li>
              <li><p>Classroom features like knowing your classmates, sharing information, etc.</p></li>
              <li><p>Online certification generation and integration with social media to share</p></li>
              <li><p>Course creation with Chapters and Sub Chapters, uploading materials in different formats</p></li>
              <li><p>Advanced statistics module and reports</p></li>
              <li><p>Real-time student progress check</p></li>
              <li><p>Online payment and finance module for revenue & profit</p></li>
              <li><p>Forum module for interaction between professors and students</p></li>
              <li><p>Customized notification & alerts</p></li>
            </ul>
        </div>
    </div>
</div>
     </section>

     <section>
   <div className="container" >
      <div className="row">
                  <div className="col-xl-5 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                      >
                        <h2 className="industry-head-line">Mobile learning</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        >
                        <p>
                        Utilize our education software development services and design online education platforms that complement your 
                        classroom curriculum at school and reduce the manual load for students and teachers, making learning fun, and interactive. 
                        Custom eLearning applications decrease the communication gap between institutions and students as well as facilitate interaction with the parents. 
                        Enabling push notifications keeps users updated and enhances their learning and growth.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
      <div className="pt-5">
      <div class="forex-bottom-block">
               <div className="row">
                  <div className="col-xl-4 col-lg-6 col-md-12">
                  <div class="forex-bottom-block-inner">
                  <h3>Teacher</h3>
                  <ul class="inner-page-listing checklogo">
                    <li><p>Manage attendance, create reports</p></li>
                    <li><p>Manage and schedule class time table</p></li>
                    <li><p>View lessons, upload videos and share with students, get feedback</p></li>
                    <li><p>Chapter wise exercise, solution, discussions between students and teacher</p></li>
                    <li><p>Conduct tests, share tests and results with students, conduct teacher-student feedback</p></li>
                    <li><p>Upload, view and share class notes</p></li>
                    <li><p>Create and share model exam papers</p></li>
                  </ul>
               </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-12">
                  <div class="forex-bottom-block-inner">
                  <h3>Student</h3>
                  <ul class="inner-page-listing checklogo">
                    <li><p>Maintain time table and instructions shared by teachers</p></li>
                    <li><p>Learn at home</p></li>
                    <li><p>Access video lessons and other educational videos shared by school or teachers</p></li>
                    <li><p>Access and solve exercises shared by teachers, participate in discussions, and Submit Solutions</p></li>
                    <li><p>Take chapter wise tests and practice tests</p></li>
                    <li><p>Check test results</p></li>
                    <li><p>View class notes shared by teachers</p></li>
                  </ul>
               </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-12">
                  <div class="forex-bottom-block-inner">
                  <h3>Parent</h3>
                  <ul class="inner-page-listing checklogo">
                    <li><p>Connect with teachers</p></li>
                    <li><p>Ability to view the schedule, attendance and general instructions for child</p></li>
                    <li><p>Schedule and view phone and physical appointments</p></li>
                    <li><p>Notifications about model exam paper release</p></li>
                    <li><p>Test schedule and result information</p></li>
                    <li><p>Leave application management</p></li>
                  </ul>
               </div>
                  </div>
               </div>
            </div>
      </div>
      <div className="pt-5">
         <div className="row">
          <div className="col-md-6">
          <h2>Gamification</h2>
          <p className="pt-3">
            The best way to make a student learn a complex solution is through an immersive and engaging approach using a 
            game-based learning solution. Our educational software solutions engage learners with interactive lessons and gamification 
            elements that retain their attention during the entire learning cycle, hence making courses and studies more fun.
          </p>
          </div>
          <div className="col-md-6">
          <h2>Educational Platforms</h2>
          <p className="pt-3">
            Become a pioneer in the education industry by converting your ideas and choosing us as the education software development 
            company that brings your learning techniques and tools on screen for the world. We provide completely automated, customizable, 
            cloud-based, or on-premise education software services which integrate all your ideas &amp; vision and provide an interactive online learning platform.
          </p>
          </div>
         </div>
      </div>
   </div>
</section>

<section class="iotsection">
   <div class="text-center">
            <h2 className="text-white">Corporate Learning & Knowledge Management</h2>
         </div>
      <div class="container bg-white p-5" style={{borderRadius:'16px'}}>
         <div class="industry-claim-policy-wrapper">
          <p>
            Utilizing our educational software development services design a one-stop solution that meets your corporate 
            learning needs and helps you streamline the training and onboarding process through our SharePoint solution. 
            As a leading educational software development company, we build on-premise and cloud-based SharePoint solutions 
            which increase efficiency, competency and decision making for recruitment, training, certification and knowledge 
            management for organisations. Knowledge management system helps in sharing corporate data to employees and relevant 
            stakeholders in an efficient and secure way.
          </p>
            <ul class="inner-page-listing two-column">
              <li><p>Content management system (CMS)</p></li>
              <li><p>Document management system</p></li>
              <li><p>Role-based search and access</p></li>
              <li><p>Label Graphics, Images, Videos and Tutorials</p></li>
              <li><p>Course creation and management</p></li>
              <li><p>Quiz, tests, and certifications</p></li>
              <li><p>User and admin dashboards</p></li>
              <li><p>Social media integration</p></li>
              <li><p>Research work submission & publication</p></li>
              <li><p>Organization news and announcement</p></li>
              <li><p>Workflows and approval process</p></li>
              <li><p>Discussion and forum boards</p></li>
            </ul>
         </div>
      </div>
   </section>

   <section>
     <div class="container">
    <div class="industry-pension-fund-wrapper">
        <div className="row">
                  <div className="col-xl-5 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                      >
                        <h2 className="industry-head-line">School Management Software</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        >
                        <p>
                          Web-based school management applications enable schools to manage and grow their educational centers in an efficient and 
                          intellectual manner. Such applications provide a centralized platform to parents, students, teachers, and sponsors. 
                          Seamless user interface and task management boost the performance of teachers and students, helps them execute their 
                          routine tasks in the most efficient way while providing real-time statistics to the school management at the same moment.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
        <div class="pension-bottom-block pt-5">
            <ul class="inner-page-listing two-column">
              <li><p>Admission Management System to streamline and customize the admission process with the option of real-time tracking offline as well as online.</p></li>
              <li><p>Student and Staff Management to maintain a centralized database for all the stakeholders so that sharing documents and ideas becomes hassle-free. Edit, retrieve, and access data on the go from anywhere.</p></li>
              <li><p>Academic Calendar and Timetable Management for one-click, optimized, and customizable academic calendar and time-tables for every class based on teacher availability.</p></li>
              <li><p>Attendance Management System to maintain attendance records online, upload and view leave applications and notify parents about the absence.</p></li>
              <li><p>Fee Tracking and Payment Management System for customized and efficient school fee module and payment collection parameters as per the settings by the institutions. Payment can be made online via wallets or credit cards as well as can be made by physical deposits.</p></li>
              <li><p>Online Report Card & Grade Management that allows teachers, students, and parents to monitor current grades, check results of latest tests and exams, print final & term report cards, and auto-upload the results to the student & parent portal.</p></li>
              <li><p>Exam Scheduler to automate examination and assessment process, schedule exams, book slots, assign papers, notify students, etc.</p></li>
              <li><p>Event Manager to decide and post-school holidays, school events, and distribute the information via various channels.</p></li>
              <li><p>Library Management System for easy and efficient management of library activities. Simplified and quicker library book issue and return process, bulk upload of books based on categories, generating barcodes for the new books and easy tracking of the status of books.</p></li>
              <li><p>Communication Portal for instant information & notification access facility for parents, students, and teachers across multiple platforms along with the facility to send important updates through SMS, emails, and in-app push notifications for timely communication.</p></li>
            </ul>
        </div>
    </div>
</div>
     </section>
      

      <Footer/>
    </React.Fragment>
  );
};
