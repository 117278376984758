export const javatech = {
  header : 'JAVA DEVELOPMENT COMPANY',
  content: 'We create rich web-based java applications for both server and client-side projects. With immense exposure to all the ground-breaking new technologies, we ensure to offer sustainable and high-quality Java Software solutions.',
  bgimg : '/images/java-banner.webp',
  abouttech: `Java follows the Write Once, Run Anywhere concept. Java programs do not require vendor-specific deployment descriptors to port an application from one server to another. RaiseProSoft - A Java development company comprises a team of skilled Java developers who are committed to creating applications using all the latest and reliable technologies to meet scalability and high-performance requirements. We, as a java development company carry expertise in various technologies such as Java, Spring Boot, Spring, J2EE, Hibernate, Microservices, Kafka, and database technologies like Oracle and MySQL. Our credible experience with the required skill set and infrastructure strengthens our capability to focus on specific business requirements and customize our solutions as per the business needs across the industries.`,
  servicesheader: 'Java Development Services',
  servicecontent: 'Our custom Java software development services cater to a vast array of industries.',
  techexperiencebg : '/images/java-development-experience-banner.webp',
  techexperience: 'Java Development Experience',
  techexperiencecontent: `We build robust and scalable Java software to address the business challenges and deliver high-quality DevOps services providing optimum benefits to various industry clients.`,
  advancestech: 'Advanced Java Applications',
  advancestechcontent: `We enable businesses with custom Java development offerings for reliable, future-ready and robust application.`
}
export const technologies = [
{
  title: "Web Application Development",
  img: "/images/web_appl_development.svg",
  description: "Creating modular, scalable, reliable, and responsive solutions are what our custom java application development is all about. We serve a wide range of industries including finance, healthcare, and manufacturing."
},
{
  title: "Java API Development",
  img: "/images/application_maintanance_support.svg",
  description: "Our Java developers are experts in creating light-weight, high performance, and secure REST API which can be consumed by any front end or 3rd party applications."
},
{
  title: "Enterprise Java Integration",
  img: "/images/custom_appl_development.svg",
  description: "Our Java development team provides seamless communication between mission-critical heterogeneous business applications to help and automate your business."
},
{
  title: "Java Application Migration",
  img: "/images/java-migration.svg",
  description: "Our Java developers are competent to migrate your business application to incorporate continuously evolving business demands, modern architecture, intuitive UI/UX and latest web standards."
},
{
  title: "Java Maintenance & Support",
  img: "/images/application_migration.svg",
  description: "As a Java web development company, we offer full-fledged Java application maintenance and support services to add new features and modules in your application as and when required."
},
{
  title: "Dedicated Java Team",
  img: "/images/dedicated_development.svg",
  description: "Our highly motivated team of Java programmers can comfortably work as your extended team which can be ramped up or down as per the business needs and scenario which is proven to be a cost-effective solution."
}
]
export const techstack = [
  {
    title: "Core Libraries and Frameworks",
    icon: "/images/core-lib-blue-tech-ic.svg",
    items: [
      "Spring Framework",
      "Quarkus",
      "JSF",
      "Spring Cloud",
      "Apache Solr",
      "Spring WebFlux",
      "Kotlin + Spring Boot",
    ]
  },
  {
    title: "Frontend",
    icon: "/images/frontend-blue-tech-ic.svg",
    items: [
      "React",
      "Angular",
      "jQuery",
      "Bootstrap",
      "Next.js",
      "Grafana",
    ]
  },
  {
    title: "Build and Dependency Management",
    icon: "/images/build-dependency-blue-tech-ic.svg",
    items: [
      "Apache Maven",
      "Gradle",
      "Apache Ant",
    ]
  },
  {
    title: "Containerization and Deployment",
    icon: "/images/containerization-deployment-blue-tech-ic.svg",
    items: [
      "Docker",
      "JHipster",
      "Kubernetes",
    ]
  },
  {
    title: "Database",
    icon: "/images/database-blue-tech-ic.svg",
    items: [
      "MySQL",
      "PostgreSQL",
      "MongoDB",
      "Oracle",
      "Redis",
      "AWS RDS",
    ]
  },
  {
    title: "Cloud & DevOps",
    icon: "/images/cloud-devops-blue-tech-ic.svg",
    items: [
      "AWS",
      "Azure",
      "Jenkins",
      "Digital Ocean",
    ]
  },
  {
    title: "QA and Testing",
    icon: "/images/qa-testing-blue-tech-ic.svg",
    items: [
      "JUnit",
      "Cucumber",
      "TestNG",
      "Apache JMeter",
      "BrowserStack",
      "Postman",
      "Selenium",
      "Katalon Test Studio",
    ]
  },
  {
    title: "Apache Foundation",
    icon: "/images/apache-blue-tech-ic.svg",
    items: [
      "Maven",
      "Apache Camel",
    ]
  },
  {
    title: "Persistence and ORM Tools",
    icon: "/images/persistence-tools-blue-tech-ic.svg",
    items: [
      "Hibernate / JPA",
      "Spring Data",
    ]
  },
  {
    title: "Java Messaging",
    icon: "/images/java-messaging-blue-tech-ic.svg",
    items: [
      "Kafka",
      "Apache Active MQ",
    ]
  },
  {
    title: "Architecture",
    icon: "/images/architecture-blue-tech-ic.svg",
    items: [
      "Enterprise",
      "SOA",
      "Microservices",
      "Serverless",
    ]
  },
  {
    title: "Big Data and Analytics",
    icon: "/images/big-data-blue-tech-ic.svg",
    items: [
      "Apache Spark",
      "ElasticSearch",
      "Click House",
    ]
  },
  {
    title: "Other Tools and Technologies",
    icon: "/images/other-blue-tech-ic.svg",
    items: [
      "JPQL",
      "Thymeleaf",
      "JasperReports",
    ]
  },
];


export const services = [
  "Product Development, Maintenance & Support",
"Online Ecommerce store and Marketplace Development",
"Enterprise applications development",
"CRM portal development",
"Integration with ERP, Service Now, etc.",
"Social Networking and Social Commerce",
"Agile Software Development",
"Finance & Investment-related projects",
"Java Mobile App Development",
"Java CMS Development",
"GPS based transport solutions",
"Business Intelligence"
];

export const whyReproSoftData = [
{
  title: "Robust architecture",
  description: "We keep up a pragmatic approach to software architecture and design and develop a robust architecture that fits in for the business on a longer run.",
  imgSrc: "/images/why-raisepro-cycle-development.svg",
  altText: "code"
},
{
  title: "Proven Track Record",
  description: "Our work speaks louder in words. Our dexterous team of software professionals is adept with robust technical knowledge to deliver sturdy solutions.",
  imgSrc: "/images/why-raisepro-agile.svg",
  altText: "agile"
},
{
  title: "Assertive Communication",
  description: "At RaiseProSoft, we create a win-win situation by streamlining and establishing effective communication between clients and development teams.",
  imgSrc: "/images/why-raisepro-domain.svg",
  altText: "process"
},
{
  title: "Progressive Roadmap",
  description: "We are a result-driven software development company that meticulously comprehends details of the client's project and offers the best-fit technology solution.",
  imgSrc: "/images/why-raisepro-dedicated-team.svg",
  altText: "medal"
}
];
