import { Link } from "react-router-dom"

export const Tophome=()=> {
    return(
        <section className="home-section top-bg">
            <div className="container">
                <div className="row">
                    <div className="col-md-7">
                    <h1 style={{color:'#fff',maxWidth:'935px',width:'100%',lineHeight:'normal'}}>Transform Your Business with RiseProSoft Solutions: Your Trusted Expert in SAP Consulting, Web Design, Mobile App Development and Comprehensive Digital Solutions. </h1>
                    <button className="btn contactbtn" >
                        <Link to="/contactus"> Contact us <i className="fa fa-arrow-right " style={{color:'#1329e9'}}></i></Link>
                    </button>
                    </div>
                </div>
            </div>
        </section>
    )
}