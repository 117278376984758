export const reactnativetech = {
  header : 'React Native Application Development',
  content: `Whether it's React Native or iOS, RaiseProSoft aims at delivering end-to-end cross-platform React Native app development services. We build business-empowering, high performance applications featuring powerful UX for our customers`,
  bgimg : '/images/react-banner.webp',
  abouttech: `Featuring robustness of Facebook and ease of JavaScript, React Native puts an end to the age-old confusions mobile app entrepreneurs have been facing since ages while looking for a cost-effective solution. RaiseProSoft is one of the leading React Native development companies that incorporates all the refined approaches and methods which have been used to deploy app solutions across the industries for our clients. We have a team of React Native app developers who aren't just experienced but have surpassed the limitations of React Native to create anything you want by using a combination of the React Native and Native code. We leverage the best features of the technology to create cross-platform and innovative mobile apps which makes us a trusted React Native Development company. `,
  servicesheader: 'React Native Development Services',
  servicecontent: `We deliver best-in-class secure, scalable secure, sustainable, scalable and custom React Native development services.`,
  techexperiencebg : '/images/react-native-experience-banner.webp',
  techexperience: 'React Native App Development Experience',
  techexperiencecontent: `Our enduring experience in catering to different domains has assisted businesses to develop scalable and sustainable applications with fully managed React development services`,
  advancestech: 'Advanced React Native Applications',
  advancestechcontent: `We specialize in offering advanced React Native Application to build compatible, cross-platform and custom configurable mobile applications.`
}
export const technologies = [
{
  title: "React Native mobile app development consulting",
  img: "/images/react_native_mobile_app_development_consulting.svg",
  description: "Right from dealing with all kinds of React Native applications to offering development, design, testing, and maintenance services, our React Native developers have got you all covered."
},
{
  title: "React Native Application Development",
  img: "/images/react_native_application_development.svg",
  description: "We have developed top-rated apps for different industries and have expertise in managing multi-thread environment, crash free industry standard programming as well as providing architectural leadership."
},
{
  title: "UI/UX design",
  img: "/images/ui_ux_design.svg",
  description: "Keeping the user-centric and seamless app layout approach in mind, we ensure designing a smooth experience for your end-users. In addition, we pay great attention every step and design element involved."
},
{
  title: "Back-end engineering",
  img: "/images/back_end_engineering.svg",
  description: "Our team has delivered many React Native projects. They are seasoned to create secure and scalable backend solutions, implementing third-party API integrations along with taking care of the evolving business needs."
},
{
  title: "Testing & Maintenance",
  img: "/images/testing_maintenance.svg",
  description: "We are right here to take care of your product post release worries by helping you with bug fixing, performance tuning, system monitoring and performing on-demand-updates."
},
{
  title: "Team Augmentation",
  img: "/images/team_augmentation.svg",
  description: "We let your organization choose from our pool of resources and let you manage your requirements and team hassle free through direct communication with them."
}
]
export const techstack = [
{
  title: "Core Libraries, Languages, and Frameworks",
  icon: "/images/core-lib-lang-blue-tech-ic.svg",
  items: [
    "JavaScript (ES6+)",
    "TypeScript",
    "React Native"
  ]
},
{
  title: "State Management",
  icon: "/images/state-manage-blue-tech-ic.svg",
  items: [
    "Redux",
    "Context API",
    "Rematch"
  ]
},
{
  title: "UI and Styling",
  icon: "/images/ui-styling-blue-tech-ic.svg",
  items: [
    "React Native Elements",
    "React Native Paper",
    "React Native Gesture Handler & Reanimated",
    "React Native Lottie",
    "Styled-components",
    "React Native Vector Icons"
  ]
},
{
  title: "Navigation and Utilities",
  icon: "/images/nav-utility-blue-tech-ic.svg",
  items: [
    "React Navigation",
    "React Native Dotenv",
    "React Native Config",
    "Lodash"
  ]
},
{
  title: "Build, Debug, QA & Testing",
  icon: "/images/testing-blue-tech-ic.svg",
  items: [
    "Flipper",
    "Detox",
    "Enzyme",
    "jest-cucumber",
    "why-did-you-render",
    "React Native Testing Library",
    "Expo",
    "Jest",
    "Redux DevTools",
    "React Native CLI",
    "React Native Debugger"
  ]
},
{
  title: "Backend, Integration, and Data Management",
  icon: "/images/backend-integration-and-data-management-blue-tech-ic.svg",
  items: [
    "Axios",
    "Realm",
    "React Query",
    "React Native Localize",
    "React Native Firebase",
    "GraphQL and Apollo Client",
    "i18n-js",
    "redux-saga",
    "WatermelonDB",
    "react-native-sqlite-storage",
    "React Native Background Fetch"
  ]
}
];



export const services = [
  
        "Custom React Native applications",
        "Cross platform development with iOS/Android SDK",
        "Enterprise application with deployment",
        "Mobile ERP, POS and M-commerce",
        "React Native Widget/Extension development",
        "Augmented Reality apps",
        "SaaS based application development",
        "Custom components development",
        "Social networking app with instant messaging, video and voice",
        "Online Audio / Video Streaming Apps",
      
];

export const whyReproSoftData = [
{
  title: "Robust architecture",
  description: "We keep up a pragmatic approach to software architecture and design and develop a robust architecture that fits in for the business on a longer run.",
  imgSrc: "/images/why-raisepro-cycle-development.svg",
  altText: "code"
},
{
  title: "Proven Track Record",
  description: "Our work speaks louder in words. Our dexterous team of software professionals is adept with robust technical knowledge to deliver sturdy solutions.",
  imgSrc: "/images/why-raisepro-agile.svg",
  altText: "agile"
},
{
  title: "Assertive Communication",
  description: "At RaiseProSoft, we create a win-win situation by streamlining and establishing effective communication between clients and development teams.",
  imgSrc: "/images/why-raisepro-domain.svg",
  altText: "process"
},
{
  title: "Progressive Roadmap",
  description: "We are a result-driven software development company that meticulously comprehends details of the client's project and offers the best-fit technology solution.",
  imgSrc: "/images/why-raisepro-dedicated-team.svg",
  altText: "medal"
}
];


export const processSteps = [
  {
    step: "01",
    title: "Discovery",
    description: "Your project is important to us. Whether you are consulting us for the very first time or thinking of reviving your fledgling app, our team of React Native app developers will spend an adequate amount of time in assessing your project. We provide recommendations only after brainstorming different ideas, understanding your business processes, architectural and infrastructural concerns.   ",
    class: "heading-part"
  },
  {
    step: "02",
    title: "UI/UX Design",
    description: "Whether you are planning to develop an interface for a brand-new app or thinking of upgrading an existing one, our team provides you a design that targets your key performance indicators and hits the right spots in terms of usability and appeal. ",
    class: "heading-part green-box"
  },
  {
    step: "03",
    title: "Development",
    description: "After thriving for so long in an Agile development environment, we have gained a deep understanding and the importance of providing timely updates and deliverables to the client. We strongly believe in continuous delivery, update, and deployment of code.",
    class: "heading-part purple-box"
  },
  {
    step: "04",
    title: "API Integration",
    description: "Depending on the client requirements, we integrate their app with 3rd party and Custom API applications like Instagram, YouTube, payment gateways for carrying out secure transactions as well as numerous other applications and data sources.",
    class: "heading-part orange-box"
  },
  {
    step: "05",
    title: "Testing",
    description: "Quality assurance is a critical step that we take care of not only during the development lifecycle of the app but also post-development. We make sure that all types of necessary functional and non-functional testing are performed in the given lifecycle of app development. ",
    class: "heading-part blue-box"
  },
  {
    step: "06",
    title: "Deployment",
    description: "Lastly, our React Native app development team will assist you in choosing the appropriate enterprise solution with the app store as well.",
    class: "heading-part light-green-box"
  },
  {
    step: "07",
    title: "Maintenance",
    description: "The job is not over once the app is deployed initially since maintenance, support, and optimization are required on a regular basis as we move along. One also needs to evolve and cater to user feedback they receive and keep deploying updated versions of the app.",
    class: "heading-part violet-box"
  }
];