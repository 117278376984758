export const andriodtech = {
  header : 'Android App Development Company',
  content: `Developing high-performing, feature-packed custom android applications is what we are experts at. We build business-critical android applications for our clients that amplify user engagement &amp; works for every device.`,
  bgimg : '/images/android-services-banner.webp',
  abouttech: `Android apps are used by almost half of the population of the world which makes the android platform highly considerable when it comes to developing any kind of mobile app. RaiseProSoft is a reliable Android app development company that aims at improving your digital experience while providing an engaging, user-friendly mobile app. We have a team of experienced Android application developers who have successfully leveraged the full spectrum of mobile technologies and created some noteworthy android apps with great looks, feel, and functionality. We have catered to various industries like manufacturing, retail, education, fintech and telecom domains as well as consumer mobile apps. We have experience of more than a decade in creating dozens of applications for smartphones, tablets and wearables which involve activities ranging from initial concept development, UI/UX design, programming to testing to finally releasing them on Google play store. We also keep the android app idea confidential by signing an NDA with our clients.`,
  servicesheader: 'Android App Development Services',
  servicecontent: `Get sleek, productive and reliable Android development services that help you achieve your business objectives.`,
  techexperiencebg : '/images/android-experience-banner.webp',
  techexperience: 'Android App Development Experience',
  techexperiencecontent: `No matter what the device is, RaiseProSoft has years of experience in delivering customer-centric solutions when it comes to Android application development services.`,
  advancestech: 'Advanced Android Applications',
  advancestechcontent: `With Android development, opportunities are infinite. So, no matter how unachievable your problem may seem, RaiseProSoft has a proven track record of consistent delivery.`
}
export const technologies = [
{
  title: "Android Mobile App Development Consulting",
  img: "/images/android_mobile_app_development_consulting.svg",
  description: "We provide end-to-end Android App Development services including relevant consultation to transform your ideas into an app. Right from analysing the business needs to create a minimum viable product, we offer it all!"
},
{
  title: "Custom Android App Development",
  img: "/images/android_application_design_development.svg",
  description: "Years of expertise in the field of Android development make our mobile app developers capable enough to build an effective PoC. Also, we are well-equipped with Android Studio which makes Android application development faster."
},
{
  title: "UI/UX design",
  img: "/images/ui_ux_design.svg",
  description: "RaiseProSoft firmly believes in paying great attention to design elements by developing detailed mock-ups and wireframes. User-centric design and approach has always been in the heart of our bespoke solutions."
},
{
  title: "Back-end engineering",
  img: "/images/back_end_engineering.svg",
  description: "Our android developers make it easy to create a secure and scalable backend solution, implementing third-party API integrations along with taking care of the evolving business needs."
},
{
  title: "Testing & Maintenance",
  img: "/images/testing_maintenance.svg",
  description: "We are right here to take care of your product post-release worries by helping you with bug fixing, performance tuning, system monitoring, and performing on-demand updates."
},
{
  title: "Team Augmentation",
  img: "/images/team_augmentation.svg",
  description: "We let your organization choose from our pool of expert android app developers and let you manage your requirements and team hassle-free through direct communication with them."
}
]
export const techstack = [
{
  title: "Core Languages",
  icon: "/images/core-lang-blue-tech-ic.svg",
  items: ["Kotlin", "Java"]
},
{
  title: "State Management",
  icon: "/images/state-manage-blue-tech-ic.svg",
  items: ["Flow", "ViewModel", "LiveData", "Consumer", "RxJava", "RxKotlin", "Coroutine"]
},
{
  title: "UI and Styling",
  icon: "/images/ui-styling-blue-tech-ic.svg",
  items: ["Xml", "Jetpack Compose", "Material Design"]
},
{
  title: "Build, Debug, QA & Testing",
  icon: "/images/testing-blue-tech-ic.svg",
  items: [
    "Firebase Crashalytics", "Android Studio", "Unit Testing", "LeakCanary", "Stetho",
    "Instrumentation Testing", "Chunk Intercepter", "Code Coverage", "Logging"
  ]
},
{
  title: "Backend, Integration, and Data Management",
  icon: "/images/backend-integration-and-data-management-blue-tech-ic.svg",
  items: [
    "Retrofit", "OkHttp", "Volley", "WebSocket", "FCM", "SharedPreferences", "Room DB",
    "SQLite", "GraphQL", "Realm DB"
  ]
},
{
  title: "Design, Prototyping, and Asset Management",
  icon: "/images/design-prototype-asset-blue-tech-ic.svg",
  items: ["Figma", "Adobe XD", "Zeplin"]
}
];



export const services = [
   "Custom Android App Development",
   "Augmented Reality Apps",
   "Android Mobile, TV and Watch Applications ",
   "Android Widget Development",
   "Enterprise Application with Deployment",
   "Mobile ERP, POS and M-commerce ",
   "Custom Android Library/Framework Development",
   "BLE(Bluetooth Low Energy) / Beacon-enabled Mobile Applications",
   "SaaS Based Android Application Development",
   "Social Networking App with Instant Messaging, Video and Voice",

      
];

export const whyReproSoftData = [
{
  title: "Progressive Roadmap",
  description: "We explore the client's business and portfolio deeply and recommend a strategic plan of action considering multiple aspects in mind.",
  imgSrc: "/images/why-raisepro-cycle-development.svg",
  altText: "code"
},
{
  title: "Efficient Project Management",
  description: "Our holistic project management approach offers timely response to proposals, on-time schedules, meeting project deadlines, and fulfilling end-user expectations.",
  imgSrc: "/images/why-raisepro-agile.svg",
  altText: "agile"
},
{
  title: "Flexible Engagement Model",
  description: "With a unique amalgamation of skilled developers and cutting-edge technologies, we enable transparency and scalability at all engagement levels.",
  imgSrc: "/images/why-raisepro-domain.svg",
  altText: "process"
},
{
  title: "Consistent Delivery",
  description: "We use a plethora of software development techniques such as Scrum, Scrum ban and Scaled Agile Framework, depending on the requirement of the project.",
  imgSrc: "/images/why-raisepro-dedicated-team.svg",
  altText: "medal"
}
];


export const processSteps = [
  {
    step: "01",
    title: "Discovery",
    description: "Whether you are consulting us for the very first time or thinking of reviving your fledgling app, we will spend an adequate amount of time in assessing your project. We provide recommendations only after brainstorming different ideas, reviewing your business processes, architectural and infrastructural concerns.",
    class: "heading-part"
  },
  {
    step: "02",
    title: "UI/UX Design",
    description: "Whether you are planning to develop an interface for a brand-new mobile app or thinking of upgrading an existing one, our android application developers team provides you with a design that targets your key performance indicators and hits the right spots in terms of usability and appeal.",
    class: "heading-part green-box"
  },
  {
    step: "03",
    title: "Development",
    description: "After thriving for so long in an Agile development environment, we have gained a deep understanding and the importance of providing timely updates and deliverables to the client. We strongly believe in continuous delivery, update, and deployment of code.",
    class: "heading-part purple-box"
  },
  {
    step: "04",
    title: "API Integration",
    description: "Depending on the client requirements, we integrate their app with 3rd party and Custom API applications like Instagram, YouTube, payment gateways for carrying out secure transactions as well as numerous other applications and data sources.",
    class: "heading-part orange-box"
  },
  {
    step: "05",
    title: "Testing",
    description: "Quality assurance is a critical step that we take care of not only during the development lifecycle of the app but also post-development. We make sure that all types of necessary functional and non-functional testing are performed in the given lifecycle of app development.",
    class: "heading-part blue-box"
  },
  {
    step: "06",
    title: "Deployment",
    description: "Lastly, it's now time to deploy your app on Google Play Store and other similar platforms. We also assist in the app configuration process by taking care of various required factors. All other custom needs will be addressed before the final deployment of the app.",
    class: "heading-part light-green-box"
  },
  {
    step: "07",
    title: "Maintenance",
    description: "The job is not over once the app is deployed initially since maintenance, support, and optimization are required on a regular basis as we move along. One also needs to evolve and cater to user feedback they receive and keep deploying updated versions of the app.",
    class: "heading-part violet-box"
  }
];