export const sharepointtech = {
  header : 'SharePoint Development Company',
  content: `We help organizations in implementing document management, intranet and knowledge management systems seamlessly using the multipurpose SharePoint platform and Office 365.`,
  bgimg : '/images/sharepoint-banner.webp',
  abouttech: `Microsoft SharePoint is an enterprise information portal that offers a wide range of features and has been proven beneficial to more than 250,000 organizations. Mainly configured to create intranet, extranet and internet sites, the platform offers a wide range of collaboration and sharing tools, document management, data storage server, search engine, workflows and business intelligence tools, easy integration with Microsoft Office and Exchange Server, and much more. Being a Microsoft Gold Partner, our team of SharePoint Consultants, Architects and Developers are highly experienced in implementing SharePoint services that improve document management, sharing, collaboration, teamwork, workflow and information management.`,
  servicesheader: 'SharePoint Development Services',
  servicecontent: `Our team of SharePoint developers provide end to end customization and implementation services to solve your business challenges.`,
  techexperiencebg : '/images/sharepoint-experience-banner.webp',
  techexperience: 'SharePoint Development Experience',
  techexperiencecontent: 'We have expertise in providing full life cycle SharePoint development services from architecture design, development, support and maintenance to upgradation.',
  advancestech: 'Advanced SharePoint Development',
  advancestechcontent: `From startups to large businesses, we offer custom  SharePoint development services to establish a smooth and transparent communication within organization and with clients.`
}
export const technologies = [
{
  title: "SharePoint Development & Consulting",
  img: "/images/sharepoint_development_and_consulting.svg",
  description: "We have a team of SharePoint consultants who provide SharePoint solutions with help of the multipurpose Sharepoint platform and its implementation in alignment with the evolving nature of your business."
},
{
  title: "Business Process Automation and Workflow Development",
  img: "/images/business_process_automation_and_workflow_development.svg",
  description: "We have a team of SharePoint developers who are highly skilled in automating business processes through workflow development to improve efficiency and increase teamwork in your company."
},
{
  title: "SharePoint Branding and Design services",
  img: "/images/sharepoint_branding_and_design_services.svg",
  description: "Branding & design are key elements for any intranet and our UI design team works with SharePoint Development Team to implement engaging and beautiful UI which meets customer's unique brand vision."
},
{
  title: "SharePoint Migration",
  img: "/images/sharepoint_migration.svg",
  description: "If you are planning to migrate to a newer SharePoint version, we have expertise in version upgrade, data migration and optimization to the newer environment as a SharePoint Development Company."
},
{
  title: "SharePoint Healthcheck",
  img: "/images/sharepoint_healthcheck.svg",
  description: "Our SharePoint architects and developers identify current and potential issues in the infrastructure, architecture and performance and suggest corrective actions to make sure you avail full benefit of your implementation."
},
{
  title: "SharePoint Integration Services",
  img: "/images/sharepoint_integration_services.svg",
  description: "Our expert SharePoint Consultants understand your requirements and integrate enterprise systems like ERP, CRM, BI etc. inside the workspace of employee intranet using Microsoft SharePoint Services."
},
{
  title: "SharePoint Managed Services",
  img: "/images/sharepoint_managed_services.svg",
  description: "Our SharePoint programmers assist you in several areas such as ongoing monitoring of SharePoint implementation, adding features and functionalities, solving issues, improving performance, support and maintenance."
},
{
  title: "Hybrid Architecture",
  img: "/images/hybrid_architecture.svg",
  description: "We provide integration between SharePoint on premise and cloud applications like OneDrive as well as with SharePoint Online in Office 365 and on-premise applications like exchange server using hybrid API integration."
},
{
  title: "Office 365 & SharePoint Online",
  img: "/images/office_365_&_sharepoint_online.svg",
  description: "Our consultants customize SharePoint Online and other Office 365 tools to provide custom solutions for improving collaboration within the team and streamlining the document management system."
}
]
export const techstack = [
{
  title: "SharePoint",
  icon: "/images/sharepoint-blue-tech-ic.svg",
  items: ["SharePoint Server", "Office 365"]
},
{
  title: "Database",
  icon: "/images/database-blue-tech-ic.svg",
  items: ["SQL Server"]
},
{
  title: "Power Platform",
  icon: "/images/power-platform-blue-tech-ic.svg",
  items: ["Power Automate", "Power Apps", "Power BI", "Power Virtual Agent"]
},
{
  title: "Microsoft Azure",
  icon: "/images/micro-azure-blue-tech-ic.svg",
  items: ["Active Directory", "Function", "App Service", "Azure Storage"]
},
{
  title: "Other Frameworks",
  icon: "/images/other-framework-blue-tech-ic.svg",
  items: ["React", "TypeScript", "Node", "jQuery", "C#"]
},
{
  title: "Testing",
  icon: "/images/testing-blue-tech-ic.svg",
  items: ["Selenium", "Katalon Test Studio", "Test Rail", "Apache JMeter", "OWASP ZAP", "BrowserStack", "Postman"]
},
{
  title: "Project Management Tool",
  icon: "/images/project-management-blue-tech-ic.svg",
  items: ["Jira", "Slack", "Trello", "Microsoft Team"]
}
];



export const services = [
  
  "Custom SharePoint Application based on client requirement",
  "Business Intelligence Solutions",
  "SaaS based application development services",
  "SharePoint Migration / Up-gradation",
  "Office 365 app development, customization and integration",
  "PowerApp Functionality Development",
  "Content Migration using Tools - Sharegate, Metalogix, MetaVis",
  "SharePoint Consulting &amp; Managed Services",
  "Enterprise Collaboration Solution with Multi-level Workflow and dynamic business rules",
  "SharePoint Solutions with BI Capabilities using Reports, Charts, and Graphs",

];

export const whyReproSoftData = [
{
  title: "Progressive Roadmap",
  description: "We deeply explore the client's business and portfolio and recommend a strategic plan of action considering multiple aspects of SharePoint implementation.",
  imgSrc: "/images/why-raisepro-cycle-development.svg",
  altText: "code"
},
{
  title: "Efficient Project Management",
  description: "Our holistic project management approach offers timely response to proposals, on-time schedules, meeting project deadlines, and fulfilling end-user expectations.",
  imgSrc: "/images/why-raisepro-agile.svg",
  altText: "agile"
},
{
  title: "Flexible Engagement Model",
  description: "With a unique amalgamation of skilled developers and cutting-edge technologies, we enable transparency and scalability at all engagement levels.",
  imgSrc: "/images/why-raisepro-domain.svg",
  altText: "process"
},
{
  title: "Consistent Delivery",
  description: "We use a plethora of software development techniques such as Scrum, Scrum ban and Scaled Agile Framework, depending on the requirement of the project.",
  imgSrc: "/images/why-raisepro-dedicated-team.svg",
  altText: "medal"
}
];
