export const iostech = {
  header : 'iOS App Development',
  content: `We make your idea reach out to the world of iPhone users. Irrespective of complexity or industry, RaiseProSoft has the potential to come up with distinctive and eye-grabbing iOS applications with brilliant layouts and designs that meet your business goals and attract a loyal customer base.`,
  bgimg : '/images/mobile-app-development-services.webp',
  abouttech: `Our dedicated VueJs developers possess extensive knowledge and expertise in the Vue.js framework, enabling them to deliver advanced, flexible, and high-performing applications tailored to clients' needs. Our Vue.js app development services provide digital transformations, coupling efficiency with usability. Utilizing this framework, we craft sophisticated single-page web apps, user-friendly front-ends, and mobile applications. We leverage the advantages of this progressive JavaScript framework to cater to our clients' requirements, offering custom app development services across a range of industries including education, health, finance, and more.`,
  servicesheader: 'iOS Development Services',
  servicecontent: `Get sleek and high-performing iPhone Apps by taking advantage of our iOS app development services.`,
  techexperiencebg : '/images/ios-development-experience-banner.webp',
  techexperience: 'iOS Development Experience',
  techexperiencecontent: `We simplify complex iOS Solutions which not only suit your budget and timeline but are also equally appealing and provide a  great user experience.`,
  advancestech: 'Advanced iOS Applications',
  advancestechcontent: `We build high-performing and scalable apps with powerful UI/UX which make a complex solution look the opposite of it to the end-user.`
}
export const technologies = [
{
  title: "iOS mobile app development consulting",
  img: "/images/ios_mobile_app_development_consulting.svg",
  description: "Our team of dedicated iOS developers offers reliable consultation services including analysis of business needs, creation of a proof of concept (POC), and development of Minimum Viable Product (MVP)."
},
{
  title: "iOS App Design & Development",
  img: "/images/ios_application_design_development.svg",
  description: "We have developed top-rated iPhone apps for different industries and have expertise in managing a multi-thread environment, crash-free industry-standard programming as well as providing architectural leadership."
},
{
  title: "UI/UX design for iOS Apps",
  img: "/images/ui_ux_design.svg",
  description: "Keeping the user-centric and seamless app layout approach in mind, we ensure designing a smooth experience for your end-users. In addition, we pay great attention to every step and design element involved."
},
{
  title: "Back-end engineering",
  img: "/images/back_end_engineering.svg",
  description: "Our iPhone team makes it easy to create a secure and scalable backend solution, implementing third-party API integrations along with taking care of the evolving business needs."
},
{
  title: "Testing & Maintenance",
  img: "/images/testing_maintenance.svg",
  description: "We are right here to take care of your App post-release worries by helping you with bug fixing, performance tuning, system monitoring, and performing on-demand-updates."
},
{
  title: "Team Augmentation",
  img: "/images/team_augmentation.svg",
  description: "We let your organization choose from our pool of iOS developers and let you manage your requirements and team hassle-free through direct communication with them."
}
]
export const techstack = [
{
  title: "Core Languages",
  icon: "/images/core-lang-blue-tech-ic.svg",
  items: ["Swift", "Objective C"]
},
{
  title: "State Management",
  icon: "/images/state-manage-blue-tech-ic.svg",
  items: ["RXSwift", "Combine", "RxCocoa"]
},
{
  title: "UI and Styling",
  icon: "/images/ui-styling-blue-tech-ic.svg",
  items: ["UIKit", "SwiftUI"]
},
{
  title: "Navigation and Utilities",
  icon: "/images/nav-utility-blue-tech-ic.svg",
  items: ["UINavigationController", "NavigationLink"]
},
{
  title: "Build, Debug, QA & Testing",
  icon: "/images/testing-blue-tech-ic.svg",
  items: ["Instruments", "XCTestFramework", "Firebase Crashalytics", "Xcode"]
},
{
  title: "Backend, Integration, and Data Management",
  icon: "/images/backend-integration-and-data-management-blue-tech-ic.svg",
  items: ["Firebase", "GraphQL", "Realm", "SQLite", "Core Data", "Supabase"]
},
{
  title: "Design, Prototyping, and Asset Management",
  icon: "/images/design-prototype-asset-blue-tech-ic.svg",
  items: ["Figma", "Adobe XD", "Zeplin"]
}
];



export const services = [
        "Custom iOS application ",
        "iOS, MacOS, TVOS applications using iOS SDK",
        "Enterprise mobility solutions",
        "Mobile ERP and M-commerce ",
        "iOS Widgets/Extension development",
        "Augmented Reality apps",
        "Online Audio / Video Streaming Apps",
        "SaaS based application development",
        "Custom iOS library/Framework development",
        "Social networking app with instant messaging, video and voice",
      
];

export const whyReproSoftData = [
{
  title: "Database Decision-Making",
  description: "Based on information gathered, we create solutions to enhance your end customers' needs with a progressive outlook to the changing dynamics of the market.",
  imgSrc: "/images/why-raisepro-cycle-development.svg",
  altText: "code"
},
{
  title: "Assertive Communication",
  description: "Our straight-forward and transparent approach ensures an assertive communication internal and external for a clear vision of business objectives.",
  imgSrc: "/images/why-raisepro-agile.svg",
  altText: "agile"
},
{
  title: "Robust architecture",
  description: "RaiseProSoft emphasizes on significant areas of software development and builds solutions focused on system adaptability, sustainability and optimization.",
  imgSrc: "/images/why-raisepro-domain.svg",
  altText: "process"
},
{
  title: "Proven Track Record",
  description: "We have empowered clients with our qualitative solutions that are some added brownie points to our proven track record. Explore our portfolio for reference.",
  imgSrc: "/images/why-raisepro-dedicated-team.svg",
  altText: "medal"
}
];


export const processSteps = [
  {
    step: "01",
    title: "Discovery",
    description: "Whether you are consulting us for the very first time or thinking of reviving your fledgling app, we will spend an adequate amount of time in assessing your project. We provide recommendations only after brainstorming different ideas, reviewing your business processes, architectural and infrastructural concerns.",
    class: "heading-part"
  },
  {
    step: "02",
    title: "UX Design",
    description: "Whether you are planning to develop an interface for a brand-new app or thinking of upgrading an existing one, our team provides you a design that targets your key performance indicators and hits the right spots in terms of usability and appeal.",
    class: "heading-part green-box"
  },
  {
    step: "03",
    title: "Development",
    description: "After thriving for so long in an Agile development environment, we have gained a deep understanding and the importance of providing timely updates and deliverables to the client. We strongly believe in continuous delivery, update, and deployment of code.",
    class: "heading-part purple-box"
  },
  {
    step: "04",
    title: "API Integration",
    description: "Depending on the client requirements, we integrate their app with 3rd party and Custom API applications like Instagram, YouTube, payment gateways for carrying out secure transactions as well as numerous other applications and data sources.",
    class: "heading-part orange-box"
  },
  {
    step: "05",
    title: "Testing",
    description: "Quality assurance is a critical step that we take care of not only during the development lifecycle of the app but also post-development. We make sure that all types of necessary functional and non-functional testing are performed in the given lifecycle of iOS app development.",
    class: "heading-part blue-box"
  },
  {
    step: "06",
    title: "Deployment",
    description: "Lastly, our iOS development team will assist you in choosing the appropriate enterprise solution with the app store as well.",
    class: "heading-part light-green-box"
  },
  {
    step: "07",
    title: "Maintenance",
    description: "The job is not over once the app is deployed initially since maintenance, support, and optimization are required on a regular basis as we move along. One also needs to evolve and cater to user feedback they receive and keep deploying updated versions of the iOS app.",
    class: "heading-part violet-box"
  }
];