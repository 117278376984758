import React from "react";
import { Header } from "../header";
import { Footer } from "../home/footer";

const services = [
  {
    id: 1,
    imgSrc: "images/service-icon-4.webp",
    title: "SAP Service Offerings",
    description: `RiseProSoftis one of the handful of SAP solution
       providers with a wide spectrum of competencies
       under one umbrella, right from sizing and
       provisioning the hardware and network for your
       mission-critical SAP system (on-premise or
       on-cloud) to migration, implementation, rollouts,
       upgrade, disaster recovery, and AMS or shared
       support for your SAP ERP suite, SAP S/4 HANA or
       any other New Dimension Products from SAP.`,
  },
  {
    id: 2,
    imgSrc: "images/service-icon-4.webp",
    title: "SAP Application Management Support (AMS) Services",
    description: `Ensuring seamless operation of business processes
                  mapped in SAP is critical for the smooth
                  functioning of your organization. Our team of SAP
                  consultants, boasting both core and niche skills,
                  are dedicated to ensuring that these processes run
                  without interruption, whether it be delivery truck
                  logistics, finished goods inspection, or the
                  generation of monthly pay-slips for employees. We
                  offer a range of SAP Application Management
                  Support options, including dedicated onsite
                  support, remote shared services, and 24x7 and 16x8
                  support models. Our SAP Support for SAP Business
                  Suite, including SAP ECC6.0 and SAP S/4 HANA, has
                  been certified by SAP SE through rigorous
                  bi-annual audits.`,
  },
  {
    id: 3,
    imgSrc: "images/service-icon-4.webp",
    title: "SAP Implementation Services",
    description: `Growing businesses will have to invest in ERP
                  systems sooner or later. SAP is found to be the
                  best fit for most organizations. But implementing
                  a new ERP solution is a complicated process that
                  requires the expertise of&nbsp;an SAP consultant
                  with deep domain experience. RiseProSoft’s SAP
                  Implementation Services utilize the best industry
                  practices and methodologies to deliver a perfectly
                  planned and executed implementation of SAP system.`,
  },
  {
    id: 4,
    imgSrc: "images/service-icon-4.webp",
    title: "SAP Migration Services",
    description: `RiseProSoftCloudInfinit offers end-to-end managed
                  services for your business-critical SAP
                  application, ensuring a scalable, secure, and
                  seamless migration to future-ready infrastructure.
                  Our team of experts have successfully executed
                  various SAP migrations, both homogenous and
                  heterogeneous, with minimal downtime and zero
                  business disruption. We have experience migrating
                  to popular cloud platforms such as AWS and Azure,
                  and have successfully executed Suite on Hana (SoH)
                  and S4H migrations. Our experts work closely with
                  our infrastructure team to ensure even the most
                  complex SAP implementation can be migrated
                  seamlessly and cost-effectively.`,
  },
  {
    id: 5,
    imgSrc: "images/service-icon-4.webp",
    title: "SAP BASIS Services",
    description: `SAP Basis is the nerve center of your SAP
                  landscape. For the smooth functioning of your
                  mission- critical SAP application 24x7, it is
                  important to monitor and manage it correctly. Our
                  dedicated SAP Basis experts provide dedicated AMS
                  or Shared Services, in addition to the ongoing
                  Production Support, to help you upgrade your SAP
                  system, migrate your SAP landscape to cloud, host
                  greenfield implementations on cloud, configure SAP
                  DR (disaster recovery) or benefit from
                  single-point monitoring services from the
                  Solutions Manager or the change request tool.`,
  },
  {
    id: 6,
    imgSrc: "images/service-icon-4.webp",
    title: "SAP Upgrade Services",
    description: `RiseProSofthas extensive experience and expertise in
                  executing various SAP upgrade projects, including
                  non-Unicode to Unicode and version upgrades (e.g.,
                  from SAP 4.7 to SAP ERP 6.0) as well as version
                  upgrades along with functional upgrades. We have
                  successfully executed multiple upgrade projects
                  for our clients, many of whom have chosen to
                  combine their upgrades with migration to the
                  cloud. Our clients trust us for these services
                  because of our unique value proposition as a
                  one-stop solution provider for all their needs
                  related to the SAP system at every stage of its
                  lifecycle.`,
  },
];

export const Sapdev = () => {
  return (
    <React.Fragment>
      <Header />
      <section className="testing-section">
        <div className="container">
          <div className="banner-content-wrapper">
            <div className="container">
              <div className="banner-content text-center">
                <h1 className="text-white">
                  Accelerate your business automation with SAP on Cloud
                </h1>
                <p className="text-white">
                  Experience Next-Gen software testing and Quality Assurance
                  services with RiseProSoft Solutions. We guarantee significant
                  boost in deploying codes, improved Software development life
                  cycle and cost-effectiveness at an unparalleled speed and
                  quality.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            {/* Text Content Column */}
            <div className="col-12 col-md-5">
              <h1>SAP on Cloud made easy</h1>
              <div style={{ height: "32px" }}></div>
              <p>
                RiseProSofthas over a decade of experience in providing SAP
                Advisory and Consulting, Implementation, SAP Infrastructure, SAP
                Migration, SAP Management, and Support services.
              </p>
              <p>
                As a trusted partner, we provide SAP workloads on the cloud with
                a range of benefits including zero downtime, cost controls,
                elasticity, scalability, and complete managed services. Our
                offering includes both SAP basis and functional support,
                bringing the intelligence, security, and reliability of
                on-premise workloads to the cloud, all of which have been
                certified by SAP.
              </p>
            </div>
            {/* Image Column */}
            <div className="col-12 col-md-7">
              <img
                src="https://www.sifytechnologies.com/wp-content/uploads/2023/01/digitl-future-spot.gif"
                style={{ width: "100%" }}
                alt="Digital Future Spot"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
      <section style={{ backgroundColor: "#2236e612" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">
              <h1>SAP Cloud Infrastructure Services</h1>
              <div class="wpb_wrapper mt-5">
                <div class="border-yellow">
                  <p>
                    Enterprises that are looking for cloud adoption are often
                    unsure about what they need to assess and how, when to
                    start, and how to proceed and reap benefits. These need to
                    be key considerations in their cloud strategy for hosting
                    SAP workloads.
                  </p>
                </div>
                <div class="border-yellow margintop40">
                  <p>
                    With its SAP on Cloud Advisory services, RiseProSofthelps
                    organizations choose the cloud that best fits their SAP
                    environment along with a wide range of SAP Cloud Hosting
                    options, including Migration and Infrastructure Managed
                    Services.
                  </p>
                </div>
                <div class="border-yellow margintop40">
                  <p>
                    We help organizations accelerate their cloud adoption
                    through our multi-cloud offerings andflexibility in
                    deployments that include hosting on Sify’s SAP-certified
                    CloudInfinit grid, and SAP on AWS, Azure, and GCP.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <img
                src="https://www.sifytechnologies.com/wp-content/uploads/2023/01/SAP-Cloud-Hosting-Services-new-page.svg"
                alt=""
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      </section>
      <section style={{ paddingBottom: "0" }}>
        <div className="container">
          <div className="title-block text-center mb-4">
            <h2>Our service offerings</h2>
            <p>
              We offer businesses contractual engagement methods with an ability
              to flex software outsourcing as per business needs and project
              scope ensuring higher efficiency and top-notch resources. Our wide
              range of service offerings spans across different domains to
              provide ongoing project support with high-end software development
              solutions.
            </p>
          </div>
          <div className="work-grid-wrapper">
            <div className="row">
              {services.map((service) => (
                <div className="col-xl-4 col-md-6 col-sm-4 mb-4" key={service.id}>
                  <div className="dev-card">
                    <div className="icon">
                      <img src={service.imgSrc} alt={service.altText} />
                    </div>
                    <div className="card-body">
                      <h4 className="card-title">
                        <a href={service.link} title={service.title}>
                          {service.title}
                        </a>
                      </h4>
                      <p className="card-text">{service.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </React.Fragment>
  );
};
