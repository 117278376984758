
import React from "react";
import { Header } from "../header";
import { Footer } from "../home/footer";


export const Oilgas = () => {
  return (
    <React.Fragment>
      <Header />
      <section className="appdev-section">
        <div className="container">
          <div className="banner-content-wrapper">
            <div className="container">
              <div className="banner-content text-center">
                <h1 className="text-white">
                Oil & Gas, Mining
                </h1>
                <p className="text-white">
                We expertise in advanced IT Technologies and customized software for the Oil & 
                Gas and Mining industry, providing complete end-to-end digital solutions to solve the business challenges. 
                Our oil and gas software development knowledge and intelligent solutions enable in streamlining the operations with improved 
                decision-making ability for the industry players.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container pt-5">
          <div className="container">
              <div className="inner-page-description">
                <p>
                Oil and Gas industry holds a strategic importance in any economy and stands as one of the biggest markets of the world. 
                We, at TatvaSoft, emphasize on the critical technology challenges encountered by the organizations and provide the cutting-edge 
                solutions to address their strategic demands. We aim to simplify processes for our customers in modernizing their legacy software 
                applications with our innovative oil and gas software solutions and services.
                </p>
              </div>
            </div>
            <div className="industry-expertise-wrapper pt-5">
              <div className="container pb-5 indusry-page-description">
                <div className="risepro-inner-container">
                  <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-12">
                      <div className="risepro-tools-technology-item-inner p-0">
                        <div
                          className="risepro-tools-technology-content"
                          
                        >
                          <h2 className="industry-head-line">Our Expertise</h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-8 col-lg-6 col-md-12">
                      <div className="risepro-tools-technology-item-inner  p-0">
                        <div
                          className="risepro-tools-technology-content"
                          >
                          <p>
                          Our softwares for Oil & Gas and Mining industry enables the organizations to overcome the key 
                          industry challenges of process complexity, safety and regulatory compliances. We add great value to our 
                          clients in planning and forecasting with more integrated processes for downstream as well as management 
                          level operations. Leveraging our web and mobile technologies, oil and gas companies can enhance their business 
                          landscapes and integrate new IT systems. Our custom-built oil and gas software applications span over multiple 
                          technologies including cloud as well as mobile. Major mining companies have benefited with increased efficiency by 
                          utilizing our mining software services for automation and managing dynamic workforce.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                
                </div>
              </div>
            </div>
            <div className="industry-expertise-wrapper pt-5">
              <div className="container pb-5 indusry-page-description">
                <div className="risepro-inner-container">
                  <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-12">
                      <div className="risepro-tools-technology-item-inner p-0">
                        <div
                          className="risepro-tools-technology-content"
                          
                        >
                          <h2 className="industry-head-line">Well Dynamics</h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-8 col-lg-6 col-md-12">
                      <div className="risepro-tools-technology-item-inner  p-0">
                        <div
                          className="risepro-tools-technology-content"
                          >
                          <p>
                            A robust custom application for the Oil and Gas industry to analyze a specific well and calculate various parameters based on input data. 
                            The monitoring and modelling software facilitates the user to manage various risk factors at every foot of an oil well and enables the deeper 
                            insights to allow the user to make an informed decision.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                
                </div>
              </div>
            </div>
            <div class="reinsurance-bottom-block pt-5">
                <div className="row">
                  <div className="col-md-4">
                  <ul class="inner-page-listing checklogo">
                  <li><p>Raw Data Processing</p></li>
                    <li><p>Integrated Wellbore Modelling</p></li>
                    <li><p>High visual Outputs</p></li>
                    <li><p>Powerful algorithms</p></li>
                </ul>
                  </div>
                  <div className="col-md-4">
                  <ul class="inner-page-listing checklogo">
                    <li><p>Easy Calibration</p></li>
                    <li><p>Monitor well performance</p></li>
                    <li><p>Powerful 2D and 3D Charting</p></li>
                    <li><p>Fast Data Loading</p></li>
                </ul>
                  </div>
                  <div className="col-md-4">
                  <ul class="inner-page-listing checklogo">
                    <li><p>Leak Detection</p></li>
                    <li><p>Gas Lift Optimization</p></li>
                    <li><p>Accurate quantitative analysis</p></li>
                    <li><p>Nodal Analysis</p></li>
                </ul>
                  </div>
                </div>
              </div>
        </div>
      </section>

      <section className="energy-bgimg2">
        <div className="container">
            <div className="industry-expertise-wrapper">
              <div>
              <div className="container pb-5 indusry-page-description">
                <div className="risepro-inner-container">
                  <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-12">
                      <div className="risepro-tools-technology-item-inner p-0">
                        <div
                          className="risepro-tools-technology-content"
                          
                        >
                          <h2 className="industry-head-line">Gravel Pack Solution</h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-8 col-lg-6 col-md-12">
                      <div className="risepro-tools-technology-item-inner  p-0">
                        <div
                          className="risepro-tools-technology-content"
                          >
                          <p>
                            Professional software application to simulate, evaluate, optimize and design gravel packs under complex scenarios by integrating 
                            advanced modelling and data analysis techniques. The Gravel Pack Solution evaluates a large set of data for performance optimization and 
                            accomplishes a wide range of objectives with the use of latest technology. Used by the major operators and service companies for in-house 
                            simulation and evaluation of sand control completions, it facilitates both online and offline support.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                
                </div>
              </div>
            
              </div>
            </div>
            <div class="reinsurance-bottom-block pt-5">
                <div className="row">
                  <div className="col-md-4">
                  <h3>Simulate</h3>
                  <p>Model and predict the outcome of treatment prior to execution.</p>
                  <ul class="inner-page-listing checklogo">
                    <li><p>Well analysis</p></li>
                    <li><p>Manage fluid and gravel information</p></li>
                    <li><p>Real time progress of simulation</p></li>
                    <li><p>Compare multiple scenarios</p></li>
                </ul>
                  </div>
                  <div className="col-md-4">
                  <h3>Evaluate</h3>
                  <p>Analyse the measurements to define the success of treatment</p>
                  <ul class="inner-page-listing checklogo">
                    <li><p>Data Management</p></li>
                    <li><p>Range Management</p></li>
                    <li><p>In-depth investigation of failures</p></li>
                    <li><p>Advanced analysis techniques</p></li>
                </ul>
                  </div>
                  <div className="col-md-4">
                  <h3>Optimize</h3>
                  <p>Compare the predictions and results for continuous improvement</p>
                  <ul class="inner-page-listing checklogo">
                    <li><p>Advanced and intuitive</p></li>
                    <li><p>Accurate design optimization</p></li>
                    <li><p>Easy to use interface</p></li>
                    <li><p>Large DataSet Optimization</p></li>
                </ul>
                  </div>
                </div>
              </div>
        </div>
      </section>

      <section>
    <div class="container">
    <div class="industry-pension-fund-wrapper">
        <div className="row">
                  <div className="col-xl-5 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        
                      >
                        <h2 className="industry-head-line">GPS Fleet Tracking System</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        >
                        <p>
                          Oil & Gas industry requires a very high number of vehicles in their fleet for transportation which is regulated for health and safety purposes. 
                          GPS fleet tracking software system helps in managing and optimizing fleet usage, reducing fuel cost, scheduling maintenance, route optimization & 
                          time saving, driver overtime, driving quality improvements, accountability and meets health and safety compliances.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="reinsurance-bottom-block pt-5">
               <ul class="inner-page-listing two-column">
                <li><p>Integration with various types of devices and protocols and processing variable data</p></li>
                <li><p>Complex alert engine with user defined rules like driving without seat belt</p></li>
                <li><p>Socket base TCP/UDP communication to receive data from devices</p></li>
                <li><p>Reminder for all maintenance tasks like oil change, tyre change, registration renewal etc.</p></li>
                <li><p>Tracking of thousands of vehicles and real time plotting on maps</p></li>
                <li><p>Fuel report based on fuel sensors and driver scorecard report based on driving types and violations</p></li>
                <li><p>Integration of google maps, Bing maps and Open layer maps to plot live and historical locations</p></li>
                <li><p>Custom mapping solution for variable data integration</p></li>
                <li><p>Custom rules and geofences to keep track of any violation</p></li>
                <li><p>Saas architecture for different types of customers as well as resellers</p></li>
               </ul>
            </div>
    </div>
</div>
    </section>

    <section class="iotsection">
   <div class="text-center">
            <h2 className="text-white">Asset Optimization & Production Analysis</h2>
         </div>
      <div class="container bg-white p-5" style={{borderRadius:'16px'}}>
        
         <div class="industry-claim-policy-wrapper">
            <p>
            Mining companies can streamline and monitor their operational procedures enabling them to lower the total cost of ownership with improved business performance. 
		   To gain better control over the resources for the gold mines, the asset reporting web application helps to manage information effectively and makes 
		   it readily available for better decision making. The real time display of information results in improved asset reporting.
            </p>
            <ul class="inner-page-listing checklogo">
              <li><p>Capturing live information from Fleetmatic for fleet management</p></li>
              <li><p>Actual load against planned load transferred by different fleets</p></li>
              <li><p>Actual gold produced against gold mined and planned production</p></li>
              <li><p>Analysis of material, machines and equipment for production &amp; utilization</p></li>
              <li><p>Equipments, operators, trucks, excavators and fleet management</p></li>
              <li><p>Different performance analysis based on shifts, equipment and operators</p></li>
            </ul>
         </div>
      </div>
   </section>

   <section>
    <div class="container">
    <div class="industry-pension-fund-wrapper">
        <div className="row">
                  <div className="col-xl-5 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        
                      >
                        <h2 className="industry-head-line">Equipment monitoring & management</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        >
                        <p>
                        Equipment monitoring system is an easy way to interpret and analyse the mining data obtained from different kinds of equipment in 
                        order to monitor the working condition of various equipment pieces. By identifying possibility of any breakdown at an earlier stage, 
                        precautionary measures can be taken and impact can be minimized. The software system saves time and efforts by automating difficult calculations 
                        from the equipment data and displays result in easy to understand form.
                        </p>
                      </div>
                    </div>
                  </div>
        </div>
            <div class="reinsurance-bottom-block pt-5">
              <ul class="inner-page-listing two-column">
                <li><p>Data analysis from Haul Trucks, Dozers, Excavators, Shovels and others</p></li>
                <li><p>Overall status of equipment as well as component level drill down</p></li>
                <li><p>Integration with long wall monitoring and alert notifications</p></li>
                <li><p>Raw data aggregation and algorithm-based analysis for health check</p></li>
                <li><p>Fault measurement and recommended actions to resolve faults</p></li>
                <li><p>Trends analysis in equipment, integration with Proficy Historian</p></li>
               </ul>
            </div>
    </div>
    </div>
    </section>

      <Footer/>
    </React.Fragment>
  );
};
