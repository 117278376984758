export const vuetech = {
  header : 'VueJS Development Company',
  content: `Vue.js is a web app development framework that allows developers to create scalable, high-performing
          applications suitable for large-scale enterprises. It employs the MVVM (Model-View-ViewModel)
          architecture to deliver dynamic, real-time applications. Vue.js enables developers to provide
          futuristic applications to users by leveraging advanced techniques. This core library primarily
          focuses on the view layer, facilitating seamless integration with other JavaScript libraries.`,
  bgimg : '/images/vuejs-banner.webp',
  abouttech: `Our dedicated VueJs developers possess extensive knowledge and expertise in the Vue.js framework, enabling them to deliver advanced, flexible, and high-performing applications tailored to clients' needs. Our Vue.js app development services provide digital transformations, coupling efficiency with usability. Utilizing this framework, we craft sophisticated single-page web apps, user-friendly front-ends, and mobile applications. We leverage the advantages of this progressive JavaScript framework to cater to our clients' requirements, offering custom app development services across a range of industries including education, health, finance, and more.`,
  servicesheader: 'Vue.JS Development Services',
  servicecontent: `RaiseProSoft provides comprehensive Vue.js web application development services. Our developers are skilled in creating unique user interfaces and single-page applications using customized VueJs development services. Take advantage of our proficient developers to create a high-performing, custom Vue.js application at competitive rates.`,
  techexperiencebg : '/images/vuejs-experience-banner.webp',
  techexperience: 'Vue.JS Development Experience',
  techexperiencecontent: `Given our expertise in VueJS development and experience serving a multitude of industries, we provide businesses with scalable and agile solutions for web app development using Vue JS.`,
  advancestech: 'Advanced Vue JS Applications',
  advancestechcontent: `We provide businesses with a variety of Vue JS development services for flexible and futuristic web application development.`
}
export const technologies = [
{
  title: "Custom App Development",
  img: "/images/custom_appl_development.svg",
  description: "Engage our proficient Vue.js developers to craft a bespoke, performance-driven solution that meets your unique needs. Our software developers oversee the entire development cycle from inception to delivery, utilizing MVVM architectural patterns, modern tools, and supporting libraries. This approach ensures we offer a solution that aligns seamlessly with your business challenges."
},
{
  title: "API Development and Integration",
  img: "/images/api_development_and_integration.svg",
  description: "As a leading Vue JS development company, RaiseProSoft offers custom API development services that integrate seamlessly with third-party applications and legacy systems, whether on-premise or in the cloud. By analyzing multi-source data points, we leverage the Vue JS application development framework to create solutions that ensure agile, engaging, and seamless operations and functions."
},
{
  title: "Custom UI/UX using Vue JS",
  img: "/images/ui_ux_design.svg",
  description: "Take advantage of the latest themes, UI kits, and plugins for building scalable and proficient apps with RaiseProSoft's Vue JS developers. We offer flexible app customization within a robust architectural framework, leveraging our experience in uniquely combining modern designs with traditional development practices. Our experts ensure hassle-free, intuitive user interfaces and smooth backend operations."
},
{
  title: "Web App Modernization",
  img: "/images/web_app_modernization.svg",
  description: "Leverage our Vue development services to modernize your traditional applications into next-gen applications. We utilize features unique to Vue.js, such as two-way data binding, single-page applications, virtual DOM, the MVVM architectural pattern, and cross-functional features, to deliver advanced functionalities. Our top professionals at RaiseProSoft possess hands-on experience in Vue.js development, ensuring scalable and robust enterprise applications."
},
{
  title: "Maintenance and Support",
  img: "/images/maintenance-and-support.svg",
  description: "We provide comprehensive maintenance and support services, including system troubleshooting, software testing, performance management, and easy integration. Our services ensure overall optimization, high performance, and scalability for our clients' running applications. As a leading Vue JS development company, RaiseProSoft assists enterprises in identifying issues and upgrading the apps to meet their specific business requirements."
},
{
  title: "Staff Augmentation",
  img: "/images/staff_augmentation.svg",
  description: "Our highly skilled Vue JS developers possess profound knowledge of the VueJS framework and its implementation in web app development. Consider utilizing our staff augmentation services to tackle development challenges. We bring experience from successfully delivering over 1800 projects across various geographies, addressing evolving needs with state-of-the-art technology solutions. Our top professionals at RaiseProSoft ensure all-round app modernization."
}
]
export const techstack = [
{
  title: "Core Libraries and Frameworks",
  icon: "/images/core-lib-blue-tech-ic.svg",
  items: ["VueJs", "Vuetify", "Vuex", "Vue Cli", "Vue RouterVue", "Vee-Validate", "Axios"]
},
{
  title: "Backend",
  icon: "/images/backend-blue-tech-ic.svg",
  items: [".NET", "Java", "Node", "PHP", "ROR"]
},
{
  title: "Design Toolkit",
  icon: "/images/design-toolkit-blue-tech-ic.svg",
  items: ["Material UI", "Bootstrap"]
},
{
  title: "Database",
  icon: "/images/database-blue-tech-ic.svg",
  items: ["CosmosDB", "Microsoft SQL Server"]
},
{
  title: "QA and Testing",
  icon: "/images/qa-testing-blue-tech-ic.svg",
  items: ["JIRA", "Asana", "Postman", "Jest"]
},
{
  title: "Project Management Tools",
  icon: "/images/project-management-blue-tech-ic.svg",
  items: ["Github", "BitBucket", "Code Commit", "Jira", "Azure DevOps", "Trello", "Asana", "Test Rail"]
},
{
  title: "Other Tools and Libraries",
  icon: "/images/other-blue-tech-ic.svg",
  items: ["HTML5", "CSS3", "TypeScript / JavaScript"]
}
];



export const services = [
  "Custom Mobile App Development",
        "Component Development",
        "Real-Time App Development",
        "Vue JS Web Development",
        "Ecommerce Application Development",
        "Single-page Applications",
        "Vue JS Portal Development",
        "Vue JS Enterprise Solution",
];

export const whyReproSoftData = [
{
  title: "Robust architecture",
  description: "We follow a pragmatic approach to software architecture and design for developing a robust architecture that will serve the business well in the long term.",
  imgSrc: "/images/robust_information_architecture.svg",
  altText: "robust_information_architecture"
},
{
  title: "Flexible Engagement Model",
  description: "We offer flexible engagement models to suit all types of software development projects, ensuring on-time delivery and agility.",
  imgSrc: "/images/why-raisepro-domain.svg",
  altText: "why-raisepro-domain"
},
{
  title: "Consistent Delivery",
  description: "We maintain an agile and iterative approach to ensure timely deliveries, even in the face of tight deadlines, reworks, and rescheduling.",
  imgSrc: "/images/why-raisepro-dedicated-team.svg",
  altText: "why-raisepro-dedicated-team"
},
{
  title: "Proven Track Record",
  description: "Our work speaks for itself. Our team of skilled software professionals has the robust technical knowledge to deliver sturdy solutions.",
  imgSrc: "/images/proven_track_record.svg",
  altText: "proven_track_record"
}
];
