import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Homesection } from "./components/homecomponent";
import "react-multi-carousel/lib/styles.css";
import { Customdev } from "./components/development/customdev";
import { Webdev } from "./components/development/webdev";
import { Appdev } from "./components/development/appdev";
import { Productdev } from "./components/development/productdev";
import { Sapdev } from "./components/development/sapdev";
import { DedicatedTeam } from "./components/development/dedicatedteam";
import { Testing } from "./components/development/testing";
import ScrolltoTop from "./components/scrolltoTop";
import { About } from "./components/about";
import { Contactus } from "./components/contact";
import {Technologies} from "./components/home/technologies";
import {Finance } from "./components/industries/finance";
import { Insurance } from "./components/industries/insurance";
import { Education } from "./components/industries/education";
import { EnergyUtilities } from "./components/industries/energyutilities";
import {Healthcare } from "./components/industries/healthcare";
import {Media } from "./components/industries/media";
import {Oilgas } from "./components/industries/oilgas";
import {Retail } from "./components/industries/retail";
import {Logistics } from "./components/industries/logistics";
import {Travel } from "./components/industries/travel";
import {Publicsector } from "./components/industries/publicsector";
import { Dotnot } from "./components/technologies/dotnet";
import { Java } from "./components/technologies/java";
import { Nodetech } from "./components/technologies/nodetech";
import { Phppage } from "./components/technologies/php";
import { Rubypage } from "./components/technologies/ruby";
import { AngularPage } from "./components/technologies/angular";
import { Reactpage } from "./components/technologies/react";
import { VueJsPage } from "./components/technologies/vuejs";
import { IOSPage } from "./components/technologies/ios";
import { AndriodPage } from "./components/technologies/andriod";
import { ReactNativepage } from "./components/technologies/reactnative";
import { FlutterPage } from "./components/technologies/flutter";
import { XamarinPage } from "./components/technologies/xamarin";
import { SharepointPage } from "./components/technologies/sharepoint";
import { CloudDevopsPage } from "./components/technologies/cloudDevops";



function App() {
  
  return (
    <React.Fragment>
      <Router>
      <ScrolltoTop />
        <Routes>
          <Route path="/" Component={Homesection} />
          <Route path="/about" Component={About} />
          <Route path="/custom-development" Component={Customdev} />
          <Route path="/web-development" Component={Webdev} />
          <Route path="/app-development" Component={Appdev} />
          <Route path="/product-development" Component={Productdev} />
          <Route path="/sap-development" Component={Sapdev} />
          <Route path="/dedicated-development" Component={DedicatedTeam} />
          <Route path="/testing" Component={Testing} />
          <Route path="/contactus" Component={Contactus} />
          <Route path="/technologies" Component={Technologies}/>
          <Route path="/finance" Component={Finance}/>
          <Route path="/insurance" Component={Insurance}/>
          <Route path="/education" Component={Education}/>
          <Route path="/EnergyUtilities" Component={EnergyUtilities}/>
          <Route path="/healthcare" Component={Healthcare}/>
          <Route path="/media" Component={Media}/>
          <Route path="/oilgas" Component={Oilgas}/>
          <Route path="/retail" Component={Retail}/>
          <Route path="/logistics" Component={Logistics}/>
          <Route path="/travel" Component={Travel}/>
          <Route path="/publicsector" Component={Publicsector}/>
          <Route path="/technologies/dotnot" Component={Dotnot}/>
          <Route path="/technologies/java-development" Component={Java}/>
          <Route path="/technologies/node-js-development" Component={Nodetech}/>
          <Route path="/technologies/php-development" Component={Phppage}/>
          <Route path="/technologies/ruby-on-rails-development" Component={Rubypage}/>
          <Route path="/technologies/angular-development-services" Component={AngularPage}/>
          <Route path="/technologies/react-development-services" Component={Reactpage}/>
          <Route path="/technologies/vue-js-development-services" Component={VueJsPage}/>
          <Route path="/technologies/iphone-development" Component={IOSPage}/>
          <Route path="/technologies/android-app-development" Component={AndriodPage}/>
          <Route path="/technologies/react-native-apps" Component={ReactNativepage}/>
          <Route path="/technologies/flutter-app-development" Component={FlutterPage}/>
          <Route path="/technologies/xamarin-app-development" Component={XamarinPage}/>
          <Route path="/technologies/sharepoint-development" Component={SharepointPage}/>
          <Route path="/technologies/cloud-and-devops" Component={CloudDevopsPage}/>
        </Routes>
      </Router>
    </React.Fragment>
  );
}

export default App;
