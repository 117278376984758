import { useState } from "react"

export const Hiring =()=> {
   const [tab, setTab] = useState('dedicated')
    return (
       <section className="user-section pt-0" >
          <div className="container">
          <div class="container my-5">
  <div class="text-center mb-4">
    <h2>Business-Friendly Hiring Models</h2>
    <p>Take business to the next level by hiring our expert Node.js developers on monthly or fixed-priced models</p>
  </div>
  <div class="tab-wrapper">
    <ul className="d-flex align-items-center justify-content-center gap-4">
      <li onClick={()=> { setTab('dedicated')}} className={`hiring-tabs ${tab === 'dedicated' && 'activated'}`}>Dedicated Team</li>
      <li onClick={()=> { setTab('fixed')}} className={`hiring-tabs ${tab === 'fixed' && 'activated'}`}>Fixed Price Model</li>
    </ul>
    <div class="mt-4">
     
      <div >

        <div class="row text-center mt-4">
          { tab === 'dedicated' && (
            <div class="col-md-4 mb-3">
            <div class="card h-100">
              <div class="card-body">
                <h4 class="card-title">Hire Node.js Team</h4>
                <ul class="list-unstyled">
                  <li>Requirement discussion and team proposition</li>
                  <li>Evaluation and team finalization</li>
                  <li>Dedicated developers hiring and engagement</li>
                </ul>
              </div>
            </div>
          </div> )}
          { tab === 'fixed' && (
          <div class="col-md-4 mb-3">
            <div class="card h-100">
              <div class="card-body">
                <h4 class="card-title">Project Requirements</h4>
                <ul class="list-unstyled">
                  <li>Requirement gathering and gap analysis</li>
                  <li>Time and cost estimation</li>
                  <li>Project agreement signing</li>
                </ul>
              </div>
            </div>
          </div> )}
          <div class="col-md-4 mb-3">
            <div class="card h-100">
              <div class="card-body">
                <h4 class="card-title">Project Development</h4>
                <ul class="list-unstyled">
                  <li>Agile and Lean software development</li>
                  <li>Project milestones &amp; bi-weekly sprint designs</li>
                  <li>Iterative development and feedback</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <div class="card h-100">
              <div class="card-body">
                <h4 class="card-title">Project Delivery</h4>
                <ul class="list-unstyled">
                  <li>Cloud and DevOps integration</li>
                  <li>Manual / Automated testing</li>
                  <li>Reliable and Flexible delivery</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </div>
  </div>
</div>

          </div>
       </section>
    )
}