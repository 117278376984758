import React from "react";
import { Header } from "../header";
import { Footer } from "../home/footer";

// import { Contacttag } from "../development/customdev";
// import Accordion from "../development/accordian";
// import { ProductDevelopmentData } from "../development/customdev/accordianData";
// import { Hireteam } from "../development/hireteam";
// import TechnologyStackGrid from "../development/techstack/techStack";
// import { ProductTechnologyStackData } from "../development/technicalstacks";

// const services = [
//   {
//     id: 1,
//     imgSrc: "images/service-icon-4.webp",
//     title: "Prototyping & MVP Development",
//     description: "Discover, identify, and outline product specific demands by creating prototype based on the latest market trends and customer feedbacks. Utilize our prototyping approach and MVP development service to test your software product under specific market conditions and minimize risk."
//   },
//   {
//     id: 2,
//     imgSrc: "images/service-icon-4.webp",
//     title: "Product Architecture Design Service",
//     description: "Evolve with ground-breaking trends by drilling deep into the market and intellectually translating the needs into product features. Our skilled software product developers delve deep into each product architectural layer and derive insights to add competitive value to your business."
//   },
//   {
//     id: 3,
//     imgSrc: "images/service-icon-4.webp",
//     title: "UI/UX Design Service",
//     description: "Well defined UI/UX strategy to trace business workflows by creating information architecture, wireframing, and UX designing to engross more customers. Our UI/UX designers adapt design thinking approach to offer innovative product designs that are compelling, intuitive, and user-friendly in accordance with the current market."
//   },
//   {
//     id: 4,
//     imgSrc: "images/service-icon-4.webp",
//     title: "Product Development",
//     description: "Aligning to specific business needs, we offer end-to-end software product development services using multiple developments and operational methodologies like SCRUM, Agile, DevOps, Waterfall and Prototyping. Our adroit technical leads use razor-edge technology solutions, offer product innovation, accelerate time to market, and improve profitability."
//   },
//   {
//     id: 5,
//     imgSrc: "images/service-icon-4.webp",
//     title: "Product Testing",
//     description: "Adhering to standards of Quality Assurance, we abide by strict quality analysis from Validation, Functional, Performance, Smoke and UAT testing to assure the developed product is stable, scalable and secure. Our expert QA team ensures our developed software products are easily customizable, resilient, and integrable at all levels."
//   },
//   {
//     id: 6,
//     imgSrc: "images/service-icon-4.webp",
//     title: "Maintenance & Support Services",
//     description: "Our software maintenance and support teams upgrade and update the product with the latest technology to optimize processes and improve overall efficiency. Our experienced software developers ensure timely support and hassle-free services including but not limited to resolving issues, integration, migration, and enhancement to the clients."
//   }
// ];




export const Publicsector = () => {
  return (
    <React.Fragment>
      <Header />
      <section className="testing-section energy-bgimg">
        <div className="container">
          <div className="banner-content-wrapper">
            <div className="container">
              <div className="banner-content text-center">
                <h1 className="text-white">
                PUBLIC SECTOR
                </h1>
                <p className="text-white">
                Harnessing the power of digital and emerging technologies, we offer end-to-end technology solutions and services to empower governance.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section className="container">
          <div className="industry-expertise-wrapper">
            <div>
            <div className="container pb-5 indusry-page-description">
              <div className="risepro-inner-container">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="risepro-tools-technology-item-inner">
                      <div
                        className="risepro-tools-technology-content"
                        >
                        <p>
                          At RiseproSoft, we focus on addressing the IT challenges faced in the digitization process of legacy 
                          systems and help the organizations adapt to the digital and mobile platforms. Possessing outstanding IT skills, 
                          we have developed concrete solutions for the public sector to improve efficiency, and reduce their costs. Our latest 
                          and innovative technology solutions enable data privacy, scalability and security for digitizing the government processes.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
            </div>
          </div>
          <div className="row pt-5">
                  <div className="col-xl-4 col-lg-6 col-md-12">
                    <div className="risepro-tools-technology-item-inner">
                      <div
                        className="risepro-tools-technology-content"
                        
                      >
                        <h2 className="industry-head-line">Our Expertise</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-6 col-md-12">
                    <div className="risepro-tools-technology-item-inner">
                      <div
                        className="risepro-tools-technology-content"
                        >
                        <p>
                          Due to the evolving expectation of businesses and citizens, government agencies are 
                          implementing digital transformation initiatives across the globe. We deliver custom solutions to 
                          streamline the processes and enhance data- driven experiences.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
      </section>

      <section className="energy-bgimg2">
     <div class="container">
    <div class="industry-pension-fund-wrapper">
       
        <div className="row">
                  <div className="col-xl-5 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        
                      >
                        <h2 className="industry-head-line">Computer Aided Dispatch System</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        >
                        <p>
                          Computer Aided dispatch system is an advanced solution for the government agencies and municipalities 
                          to digitize their public safety operations and improve the services to the community. The CAD software enables 
                          automation for the 911 emergency call and makes the communication handling more efficient and augmented. 
                          The municipality public safety departments can be more responsive, be at the right place with the right manpower, 
                          to help the community faster with the use of cutting-edge technology.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
        <div class="pension-bottom-block pt-5">
            <ul class="inner-page-listing two-column">
              <li><p>Single CAD for multiple municipalities</p></li>
              <li><p>Manage public safety calls (911)</p></li>
              <li><p>Multiview screens for caller & incident location</p></li>
              <li><p>Real Time event que notifications</p></li>
              <li><p>Real time tracking of security units</p></li>
              <li><p>Recommendations of units to respond</p></li>
              <li><p>Alerts for non-responsive units</p></li>
              <li><p>Targeted addresses for specialized response</p></li>
              <li><p>Integration with GIS, AVL, MDT, COLLECT</p></li>
              <li><p>Dynamic report and audit functionality</p></li>
            </ul>
        </div>
    </div>
</div>
     </section>

     <section>
    <div class="container">
    <div class="industry-pension-fund-wrapper">
       
        <div className="row">
                  <div className="col-xl-5 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        
                      >
                        <h2 className="industry-head-line">Record Management System (RMS)</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        >
                        <p>
                        Record Management System is the most effective way to organize, track and store the vast 
                        amount of information that flows through your departments during the course of an incident or investigation. 
                        The reporting process can now be more streamlined and automated to run your department smoothly and efficiently. 
                        The law enforcement agencies and municipalities are benefitted by saving time and effort while having better access to the information needed.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="reinsurance-bottom-block pt-5">
               <ul class="inner-page-listing two-column">
                  <li><p>System compliance with NIBRS &amp; UCR</p></li>
                  <li><p>Dynamic workflow for incident reporting</p></li>
                  <li><p>Investigation management by staff</p></li>
                  <li><p>Crash reporting submission to DOT</p></li>
                  <li><p>Citation submission to CT Infractions Bureau</p></li>
                  <li><p>Arrest, booking and CISS integration</p></li>
                  <li><p>Property &amp; Evidence tracking</p></li>
                  <li><p>Multiple types of warrants</p></li>
                  <li><p>Field interviews by officers</p></li>
                  <li><p>Use of force investigation</p></li>
                  <li><p>Photo Line-up for victim/witnesses</p></li>
                  <li><p>Integration with CISS, COLLECT and CAD</p></li>
               </ul>
            </div>
    </div>
</div>
    </section>

    <section className="energy-bgimg2">
     <div class="container">
    <div class="industry-pension-fund-wrapper">
       
        <div className="row">
                  <div className="col-xl-5 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        
                      >
                        <h2 className="industry-head-line">Criminal Case Management</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        >
                        <p>
                        A complete solution for the prosecutors or defendants to manage the cases and store details right 
                        from the arrest to the sentence. This web based application streamlines the daily tasks with built-in workflows and 
                        collaboration features and organizes the record of the person related to the cases, who are witnesses, defendants, 
                        co-defendants and victims. While prosecutors are equipped with information to protect the society, this is a time saving 
                        solution that simplifies the daily workload.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
        <div class="pension-bottom-block pt-5">
            <ul class="inner-page-listing two-column">
            <li><p>Different case creation based on violation type</p></li>
            <li><p>Offender and victim management</p></li>
            <li><p>Create charges based on governing law</p></li>
            <li><p>Approval or amendment of charges by judge</p></li>
            <li><p>Jury information management</p></li>
            <li><p>Probations rules and violations</p></li>
            <li><p>Bond creation for offenders</p></li>
            <li><p>Disposition based on jury decision</p></li>
            <li><p>Final verdict and sentencing</p></li>
            <li><p>Traffic violation module</p></li>
            <li><p>Document management for each case</p></li>
            <li><p>Witness and witness classification</p></li>
            <li><p>Historical court dates with proceeding information</p></li>
            <li><p>Payment management for restitution</p></li>
            <li><p>Evidence information storage</p></li>
            <li><p>Dashboards for attorneys &amp; judges</p></li>
            </ul>
        </div>
    </div>
</div>
     </section>

     <section>
    <div class="container">
    <div class="industry-pension-fund-wrapper">
       
        <div className="row">
                  <div className="col-xl-5 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        
                      >
                        <h2 className="industry-head-line">Court Security System</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        >
                        <p>
                          Managing security and surveillance is an important aspect for the safety of judges, prosecutors and witnesses.
                          The software application allows rules-based monitoring to suit the requirements of different courtrooms using motion detectors, 
                          door monitors, access controls, DVR, metal detectors and fencing controllers. It provides a high degree of safety with real time 
                          updates on any incident. This innovative technology enables the operator to monitor all the events and take 
                          immediate necessary action as needed to minimize the dangerous incidents.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="reinsurance-bottom-block pt-5">
               <ul class="inner-page-listing two-column">
                <li><p>Integration of 3D floor view of premise</p></li>
                <li><p>Plotting of security devices on 3D map</p></li>
                <li><p>Real time status of device</p></li>
                <li><p>Customized-rules for each device</p></li>
                <li><p>Security notifications on map</p></li>
                <li><p>Emergency lockdown or evacuation</p></li>
                <li><p>Customized preset for video wall</p></li>
                <li><p>Video recording, playback and export</p></li>
                <li><p>Manage guard tour based on events</p></li>
                <li><p>Multi-site data synchronization</p></li>
               </ul>
            </div>
    </div>
</div>
    </section>

    <section className="energy-bgimg2">
     <div class="container">
    <div class="industry-pension-fund-wrapper">
       
        <div className="row">
                  <div className="col-xl-5 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        
                      >
                        <h2 className="industry-head-line">Jail Management Software</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        >
                        <p>
                          A web-based Jail Management System makes the process of booking and detention as fast and as easy as possible. 
                          The advanced solution that is adaptive and innovative, aims to provide a secured method to input and manage information. 
                          While the online solution promotes information sharing, it also serves as a reliable way to achieve higher efficiency 
                          throughout the operations of jail management, from intake to release.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="reinsurance-bottom-block pt-5">
               <div className="row">
                <div className="col-md-4">
                <ul class="inner-page-listing checklogo">
                <li><p>Inmate information management</p></li>
                <li><p>Medical check-up and history</p></li>
                <li><p>Detailed imaging module</p></li>
                <li><p>Threat Groups level</p></li>
                <li><p>Housed out for court appearance</p></li>
                <li><p>Parole management</p></li>
               </ul>
                </div>
                <div className="col-md-4">
                <ul class="inner-page-listing checklogo">
                <li><p>Court order of protection</p></li>
                <li><p>Billing for external jails</p></li>
                <li><p>Charges & Holds documents</p></li>
                <li><p>Inmate earnings cash in/out</p></li>
                <li><p>Inmate classification & cell assignment</p></li>
               </ul>
                </div>
                <div className="col-md-4">
                <ul class="inner-page-listing checklogo">
                <li><p>Grievances information and solution</p></li>
                <li><p>Pre-Release & Release process</p></li>
                <li><p>Visitor & mail/package information</p></li>
                <li><p>Inmate programs credits</p></li>
                <li><p>Officer watch tour alarms</p></li>
               </ul>
                </div>
               </div>
            </div>
    </div>
</div>
     </section>

     <section>
    <div class="container">
    <div class="industry-pension-fund-wrapper">
       
        <div className="row">
                  <div className="col-xl-5 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        
                      >
                        <h2 className="industry-head-line">Youthcare Software</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        >
                        <p>
                          Children are the future of the world and their wrong upbringing can result in their inability to differentiate 
                          between the right and the wrong. The government’s aim to restore their social integration with the right kind of 
                          training and therapy is supported by the online application tool. The software solution enables better decision making 
                          based on the inferences from various analyses and allows for their better treatment and care by officials and social workers. 
                          It helps in creating a clear roadmap for their rehabilitation and provides an effective way to implement a correct justice mechanism.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="reinsurance-bottom-block pt-5">
               <ul class="inner-page-listing two-column">
                <li><p>Juvenile admission with personal information</p></li>
                <li><p>Crime and sentencing information</p></li>
                <li><p>Psychological and behaviour analysis</p></li>
                <li><p>Different youthcare programs</p></li>
                <li><p>School enrolment for the child</p></li>
                <li><p>Regular Medical check-ups</p></li>
                <li><p>Release process for court hearing</p></li>
                <li><p>CJAMIT based assessment</p></li>
                <li><p>Multiple youthcare data synchronization</p></li>
                <li><p>Sentencing completion process</p></li>
               </ul>
            </div>
    </div>
</div>
    </section>

    <section class="iotsection">
   <div class="text-center">
            <h2 className="text-white">Law Enforcement System</h2>
         </div>
      <div class="container bg-white p-5" style={{borderRadius:'16px'}}>
        
         <div class="industry-claim-policy-wrapper">
            <p>
              Use of technology is very critical for law enforcement agencies in training and evaluation, 
              records management, incident management and more. The software application automates the incident reporting by 
              efficiently responding to an event, documenting the details and managing the evidence. Agencies can create, 
              store and access employee training records while also, review the performance in one, comprehensive application. 
              With better analysis capability, agencies can be more efficient in various aspects of law enforcement training.
            </p>
            <ul class="inner-page-listing two-column">
              <li><p>Job performance review for officers</p></li>
              <li><p>Officer's FTO program review</p></li>
              <li><p>Evaluation, training plan and certificates</p></li>
              <li><p>Training videos &amp; material management</p></li>
              <li><p>Vehicle accident and cost</p></li>
              <li><p>Internal affairs case reporting</p></li>
              <li><p>Suspect charges and violations</p></li>
              <li><p>Evidence management including body camera</p></li>
              <li><p>Use of force reporting</p></li>
              <li><p>Immigration enforcement reporting</p></li>
              <li><p>Identify pursuit trends within your agency</p></li>
              <li><p>Trend analysis for use of force in team</p></li>
              <li><p>Dynamic form creations for agencies</p></li>
              <li><p>Integration with CAD and PowerDMS</p></li>
            </ul>
         </div>
      </div>
   </section>

   <section>
     <div class="container">
    <div class="industry-pension-fund-wrapper">
       
        <div className="row">
                  <div className="col-xl-5 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        
                      >
                        <h2 className="industry-head-line">Driving License & Training</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        >
                        <p>
                          Driving licensing and renewal processes are time consuming due to strict government regulations and procedures 
                          and a very few authorities are permitted to grant the licences. The online solution provides a speedy way and 
                          automation to manage a large number of applicants. The entire process of training, licensing and renewal, 
                          which is mainly managed by the internal staff is streamlined in accordance with the RTA guidelines. 
                          The software application improves the accounting process for institutes and results in saving time and efforts. 
                          The enhanced capability and simpler process yields better revenue and efficiency for the authorities.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              <div class="reinsurance-bottom-block pt-5">
               <div className="row">
                <div className="col-md-4">
                <ul class="inner-page-listing checklogo">
                  <li><p>Issuance and renewal of license</p></li>
                  <li><p>Registration (Offline/Online)</p> </li>
                  <li><p>Lectures (Classroom/E-learning)</p></li>
                  <li><p>Mock Test</p> </li>
                  <li><p>Simulator Training</p></li>
               </ul>
                </div>
                <div className="col-md-4">
                <ul class="inner-page-listing checklogo">
                  <li><p>Practical Training</p></li>
                  <li><p>Transportation</p></li>
                  <li><p>Payments (Offline/Online)</p></li>
                  <li><p>Corporate service</p></li>
               </ul>
                </div>
                <div className="col-md-4">
                <ul class="inner-page-listing checklogo">
                  <li><p>Integration with RTA</p></li>
                  <li><p>Integration with SAP</p></li>
                  <li><p>Discounts and promotions</p></li>
                  <li><p>Live update (IVR/SMS/Email)</p></li>
               </ul>
                </div>
               </div>
            </div>
    </div>
</div>
     </section>

      <Footer />
    </React.Fragment>
  );
};
