export const rubytech = {
  header : 'Ruby on Rails Development Company',
  content: `Enhance web application development with a full-stack rapid development framework for your tailored business needs. At RaiseProSoft, we use Ruby on Rails framework to offer a plethora of custom web application development services with fast prototyping and robust architecture suitable for modern business.`,
  bgimg : '/images/ror-banner.webp',
  abouttech: `Ruby on Rails, a server-side web application framework is well-versed to offer full-stack development using MVC architecture for robust and resilient web app development. RoR framework inbuilt specialty of developing applications with cross-platform and high-security guarantees high-performance and augments the process of development and deployment. Ruby on Rails development framework is an Object-oriented language with an open-source platform that offers quick programming to easily integrate with existing applications and makes the process hassle-free. Ruby on Rails developers at RaiseProSoft follow an agile methodology using thousands of free libraries, called ruby gems, to develop scalable and complicated applications.`,
  servicesheader: 'Ruby on Rails Development Services',
  servicecontent: `Our custom Ruby on Rails web development services are capable of creating a variety of web app development to reinforce application foundation`,
  techexperiencebg : '/images/node-js-development-experience-banner.webp',
  techexperience: 'ROR Development Experience',
  techexperiencecontent: `Our prolonged experience in catering varied business domains ensures consistent delivery at every step of the Ruby on Rails rapid application development process.`,
  advancestech: 'Advanced Ruby on Rails Application Development',
  advancestechcontent: `Ruby on Rails dynamism has enabled us to offer modern and integrated services with additional features and less complexity.`
}
export const technologies = [
{
  title: "API Development & Integration",
  img: "/images/application-integration.svg",
  description: "Ruby on Rails developers at RaiseProSoft offer a custom API programming interface that covers web app development, integration, documentation, deployment and continuous maintenance of APIs. Our custom ROR applications ensure robustness, scalability and put high-performance at the forefront."
},
{
  title: "Custom RoR Development",
  img: "/images/custom-ror-development.svg",
  description: "Adhering to bespoke demands of businesses, our dedicated team of RoR developers provide custom Rails-based solutions to easily integrate new functionalities into existing systems. We, as an acquainted RoR development company, develop agile, reliable and flexible applications to ensure it perfectly fits legacy business needs."
},
{
  title: "Third-Party Integration",
  img: "/images/third-party-integration.svg",
  description: "To keep up a user-friendly app development environment and exemplary digital experience, we address each business' needs uniquely to develop stable applications. Our ruby on rails developers provide customized and seamless RoR integration services with third-party apps and virtual API's inclusive of legacy SOAP API."
},
{
  title: "RoR Migration",
  img: "/images/application-migration-and-reengineering.svg",
  description: "Take a leap to develop next-gen applications with RoR migration services which play a key role in secure migration and deployment. Ruby on Rails is a preferable choice because it manages the evolution of database schema and optimizes the migration process without any glitch in deployment or configuration."
},
{
  title: "Maintenance & Support",
  img: "/images/maintenance-and-support.svg",
  description: "Experts at RaiseProSoft, a ROR development company, keenly indulge in projects and offer extended support and maintenance for the application we have developed or have worked on. RaiseProSoft’s maintenance services include feature upgrades, speed optimization, software update, and more for companies."
},
{
  title: "Dedicated RoR Team",
  img: "/images/dedicated_qa_outsourcing_teams.svg",
  description: "RaiseProSoft’s dedicated Ruby on Rails development team and architects are proficient in developing applications using Ruby Gems and Rails modules. Our dedicated RoR development services have benefitted organizations in developing robust and sleek applications with ready-to-run instances for their projects."
}
]
export const techstack = [
{
  title: "Core Libraries and Frameworks",
  icon: "/images/core-lib-blue-tech-ic.svg",
  items: ["Ruby On Rails", "Sinatra"]
},
{
  title: "Frontend",
  icon: "/images/frontend-blue-tech-ic.svg",
  items: ["React", "Angular", "jQuery", "Bootstrap"]
},
{
  title: "Database and ORM",
  icon: "/images/db-orm-blue-tech-ic.svg",
  items: ["ActiveRecord", "PostgreySQL", "MongoDB", "Sqlite3"]
},
{
  title: "API Development",
  icon: "/images/api-dev-blue-tech-ic.svg",
  items: ["GraphQL-Ruby", "Sinatra"]
},
{
  title: "Deployment Environments",
  icon: "/images/deployment-env-blue-tech-ic.svg",
  items: ["AWS", "Heroku"]
},
{
  title: "Background Job Processing",
  icon: "/images/background-job-process-blue-tech-ic.svg",
  items: ["Sidekiq", "delayed_job", "whenever"]
},
{
  title: "Authentication",
  icon: "/images/authentication-blue-tech-ic.svg",
  items: ["Devise", "Auth0", "Omniauth"]
},
{
  title: "QA and Testing",
  icon: "/images/qa-testing-blue-tech-ic.svg",
  items: ["RSpec", "RuboCop"]
},
{
  title: "Project Management Tools",
  icon: "/images/project-management-blue-tech-ic.svg",
  items: ["Github", "BitBucket", "Code Commit", "Jira", "Azure DevOps", "Trello", "Asana", "Test Rail"]
},
{
  title: "Other Tools and Libraries",
  icon: "/images/other-blue-tech-ic.svg",
  items: ["Capistrano", "rspec-rails"]
}
];



export const services = [
   "Custom Ruby on Rails Web Applications Development",
   "SaaS-based Application Development",
   "Custom Mobile Apps Development",
   "Enterprise Modernization",
   "ROR CMS Development",
   "Staff Augmentation",
   "Innovative RoR Installation &amp; Development Solutions",
   "ROR Widget and Plug-in Development",
   "eCommerce Application Development",
   "ROR Integration Solutions",

];

export const whyReproSoftData = [
{
  title: "Robust architecture",
  description: "We keep up a pragmatic approach to software architecture and design and develop a robust architecture that fits in for the business on a longer run.",
  imgSrc: "/images/why-raisepro-domain.svg",
  altText: "process"
},
{
  title: "Proven Track Record",
  description: "Our work speaks louder in words. Our dexterous team of software professionals is adept with robust technical knowledge to deliver sturdy solutions.",
  imgSrc: "/images/why-raisepro-agile.svg",
  altText: "code"
},
{
  title: "Flexible Engagement Model",
  description: "We provide flexible engagement models using our skills for all types of software development projects assuring on-time delivery and agility.",
  imgSrc: "/images/why-raisepro-domain.svg",
  altText: "agile"
},
{
  title: "Business goals & Insights",
  description: "We delve deeper into the short-term and long-term vision of the project and after getting acquainted with the client's goals, we draft a customized software solution.",
  imgSrc: "/images/why-raisepro-dedicated-team.svg",
  altText: "medal"
}
];
