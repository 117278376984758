export const angulartech = {
  header : 'Angular Development Company',
  content: `Gear up your internet presence utilizing the best of our Angular development services to develop single page applications with fully-functional, easily integrable and modular structure. RaiseProSoft is a reputed Angular development company offering end-to-end and customized business solutions and dynamic business applications using this JavaScript framework.`,
  bgimg : '/images/angularjs-banner.webp',
  abouttech: `Open-source technologies contribute significantly towards the development of customized, fully-functional ready-to-market applications with convenient development facilities. Leverage the expertise of top talents at RaiseProSoft - a reputed angular development company. `,
  abouttech2: 'We hold an extensive understanding of critical business needs and leverage our angular development service experience to suggest the right combination of front end and back end technologies. We deliver businesses an innovative and cutting-edge solution that accelerates the overall process with specific considerations on budget and timelines. Our team of experienced Angular developers possess substantial experience in developing intuitive and interactive applications to shape the business to a mature ecosystem.',
  servicesheader: 'Angular Development Services',
  servicecontent: `Our expertise in application development using Angular technology has transformed the business landscape with diverse technology offerings`,
  techexperiencebg : '/images/angularjs-experience-banner.webp',
  techexperience: 'Angular Development Experience',
  techexperiencecontent: `Our diversified experience in angular development has enabled multiple businesses to build flexible scalable and agile applications`,
  advancestech: 'Advanced Angular Applications',
  advancestechcontent: `For advanced application functioning, we integrate enhanced features in the legacy application and shape it into the modern futuristic application.`
}
export const technologies = [
{
  title: "Custom App Development",
  img: "/images/custom_appl_development.svg",
  description: "Extend your ability to develop custom apps using angular for a well-structured and sustainable business app that persists and adapts with changes in the market. Our angular developers keep a fine balance between rapid development and quality for a user-friendly interface and compatibility. We also develop MVP with intricate validation and attract early adopters."
},
{
  title: "API Development and Integration",
  img: "/images/api_development_and_integration.svg",
  description: "RaiseProSoft is among the top angular application development companies that build custom applications with robust architecture, security, and scalability for a flawless application. RaiseProSoft's angular development & integration services facilitate rich user experiences, code maintainability, and improve scalability & responsiveness."
},
{
  title: "Custom UI/UX using Angular",
  img: "/images/ui_ux_design.svg",
  description: "Better UI/UX will help users to interact with the website & application more effectively and also enhances seamless navigation. Leverage RaiseProSoft’s angular development capability to develop custom UI/UX that offers reusable and simplified code, templates, modules, dynamic user interface and in-built validation capability for better user experience."
},
{
  title: "Web App Modernization",
  img: "/images/web_app_modernization.svg",
  description: "Seamlessly modernize legacy applications by upgrading or migrating to angular using our angular development services. We use typescript for streamlining codes into modules that improve build-time errors and help to ease inline documentation. We ensure seamless modernization services with advanced features, improved UI/UX and consistent user experience."
},
{
  title: "Maintenance and Support",
  img: "/images/maintenance-and-support.svg",
  description: "Pace up with evolving technological changes to continuously support and maintain upgrades tailored to specific business requirements for increased productivity and usability. RaiseProSoft facilitates consistent support services that optimize business workflows with seamless Angular app upgrades, improved efficiency and enhanced architectural operational support."
},
{
  title: "Staff Augmentation",
  img: "/images/staff_augmentation.svg",
  description: "Bridge the gap between technology implementation and skilled resources by harnessing our Staff Augmentation services for important projects. Our consistent experience in catering to different domains with over 1800 successful projects has improved client retention, IT excellence and brand presence in custom angular development services."
}
]
export const techstack = [
{
  title: "Core Libraries and Frameworks",
  icon: "/images/core-lib-blue-tech-ic.svg",
  items: [
    "Angular 2+",
    "AngularJS",
    "RxJS",
    "Angular Universal",
    "Jasmine",
    "Karma",
    "NGRX(Angular + Redux)"
  ]
},
{
  title: "Backend",
  icon: "/images/backend-blue-tech-ic.svg",
  items: [
    ".NET",
    "JAVA",
    "Node",
    "PHP",
    "RoR"
  ]
},
{
  title: "UI Component Libraries",
  icon: "/images/comp-lib-blue-tech-ic.svg",
  items: [
    "Angular Material",
    "PrimeNG",
    "ngx-charts",
    "ngx-datatable",
    "NG Bootstrap",
    "Tailwind UI"
  ]
},
{
  title: "State Management",
  icon: "/images/state-manage-blue-tech-ic.svg",
  items: [
    "NgRx",
    "RxJS"
  ]
},
{
  title: "QA and Testing",
  icon: "/images/qa-testing-blue-tech-ic.svg",
  items: [
    "Jest",
    "Spectator",
    "Protractor",
    "Cypress",
    "Mocha",
    "Puppeteer"
  ]
},
{
  title: "Database",
  icon: "/images/database-blue-tech-ic.svg",
  items: [
    "PostgreSQL",
    "Mongodb",
    "Firebase",
    "MySQL",
    "MSSQL"
  ]
},
{
  title: "Cloud Hosting",
  icon: "/images/cloud-hosting-blue-tech-ic.svg",
  items: [
    "AWS",
    "Azure",
    "Google Cloud Platform"
  ]
},
{
  title: "Project Management Tools",
  icon: "/images/project-management-blue-tech-ic.svg",
  items: [
    "Github",
    "BitBucket",
    "Jira",
    "Azure DevOps",
    "Trello",
    "Asana",
    "Test Rail"
  ]
},
{
  title: "Other Tools and Libraries",
  icon: "/images/other-blue-tech-ic.svg",
  items: [
    "ngx-cookie-service",
    "ngx-translate",
    "D3.js",
    "Chart.js"
  ]
}
];



export const services = [
  "Custom Web Application Development",
  "Custom UI/UX Development",
  "Single Page Application Development",
  "Angular Plug-in Development",
  "Angular Solutions for Enterprise",
  "Angular to Angular Version Migration",
  "Progressive Web Apps Development",
  "Dynamic Web Apps Development",

];

export const whyReproSoftData = [
{
  title: "Flexible Engagement Model",
  description: "With a unique amalgamation of skilled developers and cutting-edge technologies, we enable transparency and scalability at all engagement levels.",
  imgSrc: "/images/why-raisepro-domain.svg",
  altText: "why-raisepro-domain"
},
{
  title: "Progressive Roadmap",
  description: "We are a result-driven angular development company that meticulously comprehends details of the client’s project and offers the best-fit technology Solution.",
  imgSrc: "/images/why-raisepro-cycle-development.svg",
  altText: "code"
},
{
  title: "Scalable Teams",
  description: "With the changing demands of projects, we scale up proficient resources to meet your custom business demand and generate profitable results.",
  imgSrc: "/images/scalable-team.svg",
  altText: "scalable-team"
},
{
  title: "Robust architecture",
  description: "We keep up a pragmatic approach to software architecture and design and develop a component based architecture that fits in for the business on a longer run.",
  imgSrc: "/images/robust_information_architecture.svg",
  altText: "robust_information_architecture"
}
];
