export const dotnet = {
  header : '.NET Development Company',
  content: 'Robust, intuitive, and pragmatic approach to development is what we simply aim for. Being a Microsoft Gold Certified Partner, RaiseProSoft is a .NET Development Company that strives hard to develop high-end & mission-critical .NET software solutions for our esteemed clients. We provide efficient and secure business solutions by applying systematic .NET application development processes. ',
  bgimg : '/images/dotnet-banner.webp',
  abouttech: ` Microsoft .NET is one of the most popular frameworks used to develop
      secure, scalable, and high-performance enterprise applications. Being a
      Microsoft Certified Gold Partner, we make sure to assist our clients in
      the best possible manner when it comes to embracing high-quality and
      mission-critical bespoke .NET solutions. With over two decades of
      experience creating enterprise-grade full life cycle .NET solutions for
      our customers in different industries, we dive deep into our customer's
      business challenges and provide them quick measurable results. As a
      .NET application development company, our .NET software developers have
      created hundreds of web applications by using ASP.NET, .NET Core, C#,
      VB.NET, MVC WPF, MVVM, Entity Framework, Xamarin, SharePoint, and
      BizTalk Server.`,
  servicesheader: '.NET Development Services',
  servicecontent: ' Our custom .NET software development services cater to a vast array of industries.',
  techexperiencebg : '/images/dot-net-experience-banner.webp',
  techexperience: '.NET Development Experience',
  techexperiencecontent: `From on-premise to cloud applications, our .NET Developers have
      developed many bespoke solutions for different industry verticals that
      provide optimum business benefits.`,
  advancestech: 'Advanced .NET Applications',
  advancestechcontent: `As an experienced ASP.NET web development company, we use .NET
        environment and .NET Core frameworks to create high-end, scalable, and
        high-performance software products for various businesses.`
}
export const technologies = [
  {
    title: "Azure Development Services",
    img: "/images/azure_development.svg",
    description:
      "Our team of .NET developers expertise in leveraging Azure services like Azure apps, Blob & Table Storage, SQL Database, and Stream Analytics to build multi-tenant cloud-native solutions.",
  },
  {
    title: "ASP.NET Web Application Development",
    img: "/images/asp_dot_net_development.svg",
    description:
      "Right from start-ups to large enterprises, we develop highly functional robust and agile, custom ASP.NET web applications as per the business requirement. Get full-featured backend development for web, mobile, and desktop.",
  },
  {
    title: "Universal App Development",
    img: "/images/uwp_apps.svg",
    description:
      "As a full-fledged Microsoft .NET Development Company, we have created several Universal Windows Platform apps featuring high scalability, security, availability, and performance capabilities on various devices.",
  },
  {
    title: ".NET Core Development",
    img: "/images/dot_net_core_development.svg",
    description:
      "We provide .NET services by utilizing .NET Core, .NET Frameworks and latest front-end technologies to create high-performance and cloud-based .NET web and mobile apps with cross-platform compatibility.",
  },
  {
    title: "Application Lifecycle Management",
    img: "/images/lifecycle_management.svg",
    description:
      "We provide full life cycle custom .NET development services that incorporate idea conceptualization, design, implementation, software quality assurance, support & Maintenance, CI/CD, and DevOps.",
  },
  {
    title: "WPF Application Development",
    img: "/images/wpf_application_development.svg",
    description:
      "Using the Windows Presentation Foundation application development framework, we create well-optimized lightweight applications with increased speed within the set deadline and budget.",
  },
  {
    title: "Xamarin Development",
    img: "/images/xamarin_development.svg",
    description:
      "Using Xamarin, we transform your ideas into apps, be it for iOS, Android, or Windows devices. Our cross-platform development capabilities also allow for quicker development time and reduced development costs.",
  },
  {
    title: "ASP.NET Enterprise Solutions",
    img: "/images/asp_dot_net_enterprise_solution.svg",
    description:
      "Using ASP.NET software development services, we create and manage user-centric, cloud-based, and secure SAAS applications that seamlessly interact with other business solutions in an enterprise.",
  },
  {
    title: "Dedicated Development Team",
    img: "/images/dedicated_development_team.svg",
    description:
      "Professionals at our company easily collaborate with your in-house development team and work as good as your development team, thus providing you the scalability to develop your .NET projects in-house.",
  },
]
export const techstack = [
  {
    title: "Core Libraries and Frameworks",
    icon: "/images/core-lib-blue-tech-ic.svg",
    items: [
      ".NET Core",
      ".NET Framework",
      "WPF",
      "Azure Cloud Development",
      "ASP.NET MVC",
      "MAUI",
      "Blazor",
    ],
  },
  {
    title: "Front End",
    icon: "/images/frontend-blue-tech-ic.svg",
    items: ["React", "Angular", "Vue", "jQuery", "Bootstrap", "UWP Apps"],
  },
  {
    title: "Cloud & DevOps",
    icon: "/images/cloud-devops-blue-tech-ic.svg",
    items: [
      "Microsoft Azure",
      "Amazon Web Services (AWS)",
      "Google Cloud",
      "Docker",
      "Kubernetes",
      "Terraform",
    ],
  },
  {
    title: "Components, Libraries & APIs",
    icon: "/images/comp-lib-blue-tech-ic.svg",
    items: [
      "Entity Framework",
      "JSON.NET",
      "ASP.NET Web API",
      "ASP.NET Core Web API",
      "REST API",
      "SignalR",
    ],
  },
  {
    title: "Database",
    icon: "/images/database-blue-tech-ic.svg",
    items: [
      "SQL Server",
      "Postgre SQL",
      "My SQL",
      "MongoDB",
      "Azure SQL",
      "Amazon RDS",
      "Oracle",
      "Cassandra",
      "Elasticsearch",
      "Dynamo",
    ],
  },
  {
    title: "Automation",
    icon: "/images/automation-blue-tech-ic.svg",
    items: ["Azure Devops", "AWS pipeline", "Bamboo", "Jenkins"],
  },
  {
    title: "Security",
    icon: "/images/security-blue-tech-ic.svg",
    items: [
      "OAuth 2.0",
      "HTTPS/SSL",
      "NTLM",
      "X.509",
      "SSO",
      "Encryption",
      "Kerberos",
      "Auditing",
      "Logging",
    ],
  },
  {
    title: "Architecture",
    icon: "/images/architecture-blue-tech-ic.svg",
    items: ["MVC", "MVP", "Microservices", "MVVM"],
  },
  {
    title: "Caching",
    icon: "/images/caching-blue-tech-ic.svg",
    items: ["Memcached", "Redis"],
  },
  {
    title: "QA and Testing",
    icon: "/images/qa-testing-blue-tech-ic.svg",
    items: [
      "Selenium",
      "Katalon Test Studio",
      "OWASP ZAP",
      "BrowserStack",
      "Postman",
      "JIRA",
      "Azure DevOps",
      "NUnit",
    ],
  },
  {
    title: "Project Management Tools",
    icon: "/images/project-management-blue-tech-ic.svg",
    items: [
      "Github",
      "BitBucket",
      "Code Commit",
      "Jira",
      "Azure DevOps",
      "Trello",
      "Asana",
      "Test Rail",
    ],
  },
  {
    title: "Others Tools and Libraries",
    icon: "/images/others-tools-blue-tech-ic.svg",
    items: [
      "Power BI",
      "Sharepoint",
      "GraphQL",
      "ML.Net",
      "Office 365",
      "Biztalk Server",
    ],
  },
]

export const services = [
  "Web Applications using .NET Core Framework",
  "Re-engineering Legacy Application with .NET",
  "Custom .NET Web Development Services",
  "Integration with SAP for data management",
  ".NET Desktop Application Development",
  "Business Intelligence Tools & Dashboards",
  "Portals & Business Applications",
  "Enterprise Resource Planning Solutions",
  "SaaS-based Application Development",
  "CRM, POS, CMS & other product development"
];

export const whyReproSoftData = [
{
  title: "Progressive Roadmap",
  description: "We explore the client's business and requirements deeply and recommend a strategic plan of action considering multiple aspects in mind.",
  imgSrc: "/images/why-raisepro-cycle-development.svg",
  altText: "code"
},
{
  title: "Efficient .NET Project Management",
  description: "Our holistic project management approach offers timely responses to proposals, on-time schedules, meeting project deadlines, and fulfilment of end-user expectations.",
  imgSrc: "/images/why-raisepro-agile.svg",
  altText: "agile"
},
{
  title: "Flexible Engagement Model",
  description: "With a unique amalgamation of skilled dot net developers and cutting-edge technologies, we enable transparency and scalability at all engagement levels.",
  imgSrc: "/images/why-raisepro-domain.svg",
  altText: "process"
},
{
  title: "Consistent Delivery of .NET Solutions",
  description: "We use a plethora of .NET software development techniques such as Scrum, Scrum ban and Scaled Agile Framework, depending on the requirement of the .NET project.",
  imgSrc: "/images/why-raisepro-dedicated-team.svg",
  altText: "medal"
}
];
