import React from "react";
import { Header } from "../header";
import { Footer } from "../home/footer";

// import { Contacttag } from "../development/customdev";
// import Accordion from "../development/accordian";
// import { ProductDevelopmentData } from "../development/customdev/accordianData";
// import { Hireteam } from "../development/hireteam";
// import TechnologyStackGrid from "../development/techstack/techStack";
// import { ProductTechnologyStackData } from "../development/technicalstacks";

// const services = [
//   {
//     id: 1,
//     imgSrc: "images/service-icon-4.webp",
//     title: "Prototyping & MVP Development",
//     description: "Discover, identify, and outline product specific demands by creating prototype based on the latest market trends and customer feedbacks. Utilize our prototyping approach and MVP development service to test your software product under specific market conditions and minimize risk."
//   },
//   {
//     id: 2,
//     imgSrc: "images/service-icon-4.webp",
//     title: "Product Architecture Design Service",
//     description: "Evolve with ground-breaking trends by drilling deep into the market and intellectually translating the needs into product features. Our skilled software product developers delve deep into each product architectural layer and derive insights to add competitive value to your business."
//   },
//   {
//     id: 3,
//     imgSrc: "images/service-icon-4.webp",
//     title: "UI/UX Design Service",
//     description: "Well defined UI/UX strategy to trace business workflows by creating information architecture, wireframing, and UX designing to engross more customers. Our UI/UX designers adapt design thinking approach to offer innovative product designs that are compelling, intuitive, and user-friendly in accordance with the current market."
//   },
//   {
//     id: 4,
//     imgSrc: "images/service-icon-4.webp",
//     title: "Product Development",
//     description: "Aligning to specific business needs, we offer end-to-end software product development services using multiple developments and operational methodologies like SCRUM, Agile, DevOps, Waterfall and Prototyping. Our adroit technical leads use razor-edge technology solutions, offer product innovation, accelerate time to market, and improve profitability."
//   },
//   {
//     id: 5,
//     imgSrc: "images/service-icon-4.webp",
//     title: "Product Testing",
//     description: "Adhering to standards of Quality Assurance, we abide by strict quality analysis from Validation, Functional, Performance, Smoke and UAT testing to assure the developed product is stable, scalable and secure. Our expert QA team ensures our developed software products are easily customizable, resilient, and integrable at all levels."
//   },
//   {
//     id: 6,
//     imgSrc: "images/service-icon-4.webp",
//     title: "Maintenance & Support Services",
//     description: "Our software maintenance and support teams upgrade and update the product with the latest technology to optimize processes and improve overall efficiency. Our experienced software developers ensure timely support and hassle-free services including but not limited to resolving issues, integration, migration, and enhancement to the clients."
//   }
// ];




export const Media = () => {
  return (
    <React.Fragment>
      <Header />
      <section className="custom-head-section">
        <div className="container">
          <div className="banner-content-wrapper">
            <div className="container">
              <div className="banner-content text-center">
                <h1 className="text-white">
                Media & Entertainment
                </h1>
                <p className="text-white">
                Be a part of the digital revolution by utilizing our entertainment software development services to change the way your content is managed and distributed.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container" >
            <div className="row">
                        <div className="col-xl-5 col-lg-6 col-md-12">
                          <div className="">
                            <div
                              className="risepro-tools-technology-content"
                              
                            >
                              <h2 className="industry-head-line">Our Expertise</h2>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-12">
                          <div className="">
                            <div
                              className="risepro-tools-technology-content"
                              >
                              <p>
                                In the past few years, the way we consume data has completely changed due to the 
                                availability of rich and personalised content that various media companies have to offer. 
                                Our rich entertainment software development experience in the industry allows us to guide 
                                you to upgrade to the latest technologies, deliver timely content across multiple distribution 
                                channels at lower costs, streamline the media planning process, track consumer behaviour, and generate new revenue streams.
                              </p>
                          
                            </div>
                          </div>
                        </div>
                      </div>
        </div>
      </section>

      <section className="energy-bgimg2">
     <div class="container">
    <div class="industry-pension-fund-wrapper">
       
        <div className="row">
                  <div className="col-xl-5 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        
                      >
                        <h2 className="industry-head-line">Digital Media Processing Platform</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        >
                        <p>
                          Managing supply chain in the digital industry becomes very complex and time consuming when a client has thousands of media assets and each asset has to 
                          support multiple formats and languages on different retailer platforms. Digital distribution platform simplifies the asset finalization and distribution 
                          process by providing powerful workflows for titles, assets and metadata. Dashboard provides current status of 
                          assets while reports provide drilled down details.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
        <div class="pension-bottom-block pt-5">
            <ul class="inner-page-listing two-column">
              <li>
                <p>Title metadata curation for accuracy</p>
              </li>
              <li>
                <p>Create order and provide title</p>
              </li>
              <li>
                <p>Single streamlined order processing system for digital media distribution</p>
              </li>
              <li>
                <p>Asset Acquisition module for media qualification as deliverable asset</p>
              </li>
              <li>
                <p>Mastering &amp; finalization for media review and resolving issues</p>
              </li>
              <li>
                <p>Upload metadata like title, languages, genre, crew etc. for various platforms and territories</p>
              </li>
              <li>
                <p>Provide media file via upload on Amazon S3 or send physical media</p>
              </li>
              <li>
                <p>Searching/aggregating large volume of records for generating dashboard data</p>
              </li>
              <li>
                <p>Media owner can select languages, territories and retailers to publish movie or TV shows</p>
              </li>
              <li>
                <p>A centralized model to view sales and other financial information from different platforms</p>
              </li>
            </ul>
        </div>
    </div>
</div>
     </section>

     <section>
     <div class="container">
    <div class="industry-pension-fund-wrapper">
       
        <div className="row">
                  <div className="col-xl-5 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        
                      >
                        <h2 className="industry-head-line">Media distribution platform management</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        >
                        <p>
                          Analysing the market, managing titles and selling them on digital platforms is very challenging for well established studios due to the huge 
                          quantity in inventory and manual efforts. SaaS-based media distribution platform eliminates these challenges by making efficient workflows, 
                          automated pricing updates and reduced cost of sales and marketing. Platform also helps in connecting consumers with content at the 
                          correct price to increase revenue. Platform provides valuable insights about pricing trends using which content owners can run different campaigns.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
        <div class="pension-bottom-block pt-5">
            <ul class="inner-page-listing two-column">
              <li>
                <p>Import module for studios</p>
              </li>
              <li>
                <p>Location-wise historical & current title position</p>
              </li>
              <li>
                <p>Pre-live module to make movie titles available on different platforms on specific dates</p>
              </li>
              <li>
                <p>Update module to modify metadata including pricing on retailer platforms</p>
              </li>
              <li>
                <p>Price comparison module for analytics purposes</p>
              </li>
              <li>
                <p>Performance metrics to check updates and errors</p>
              </li>
              <li>
                <p>Title library providing information about pricing, territory, languages, resolution, sales type, etc.</p>
              </li>
              <li>
                <p>Exception module providing information like mismatch in status, pricing, incorrect rating, date availability, etc., between provider studios' data and retailers like iTunes and Google Play</p>
              </li>
            </ul>
        </div>
    </div>
</div>
     </section>


    <section class="iotsection">
      <div class="text-center">
              <h2 className="text-white">Digital Signage System (Online Vision)</h2>
          </div>
        <div class="container bg-white p-5" style={{borderRadius:'16px'}}>
          
          <div class="industry-claim-policy-wrapper">
              <p>
                Digital signage management system allows owners to design their own video wall and send messages specific to the audience, 
                location and time. This system has three parts - Player, content management system and designer. System owners can design their 
                own message content using images, video, audio and text files using designer and manage schedule and location from the content 
                management system.
              </p>
              <ul class="inner-page-listing two-column">
                <li><p>Multi-screen synchronized playback</p></li>
                <li><p>Billing and payment module</p></li>
                <li><p>Signage status and log reporting</p></li>
                <li><p>Create playlist for different zones</p></li>
                <li><p>Integrates images, videos, web pages, YouTube, and many other media types</p></li>
                <li><p>Media converter to convert uploaded media files in supported formats</p></li>
                <li><p>Video wall designer for multi-screen and multi-zone</p></li>
                <li><p>Supports multiple screen sizes, resolutions, and orientations</p></li>
                <li><p>Scheduling playlist based on date and time</p></li>
                <li><p>Managing signage like IP address, resolution, and other settings</p></li>
                <li><p>Live mirroring for real-time content &amp; health check</p></li>
                <li><p>Multiple customized reports for analysis purposes</p></li>
              </ul>

          </div>
        </div>
    </section>

    <section>
        <div className="container" >
            <div className="row">
                        <div className="col-xl-5 col-lg-6 col-md-12">
                          <div className="">
                            <div
                              className="risepro-tools-technology-content"
                              
                            >
                              <h2 className="industry-head-line">Digital Content DAM</h2>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-12">
                          <div className="">
                            <div
                              className="risepro-tools-technology-content"
                              >
                              <p>
                                Digital asset storage and management solutions aim at organizing media files and permission for digital rights. 
                                The time to go through thousands of files manually for hours in order to search for the one is gone. Our digital asset 
                                management solutions are the one-stop shop to store and manage all the images, videos, audios, metadata and other relevant 
                                files for your organization. Such an efficient solution makes storage and remote sharing of content rich files with your 
                                customers and team mates, regular back up, right management, addition of keywords for quick search much easier & hassle free.
                              </p>
                          
                            </div>
                          </div>
                        </div>
                      </div>
        </div>
      </section>

      <Footer/>
    </React.Fragment>
  );
};
