import React from "react"
import { Header } from "../header"
import { Footer } from "../home/footer"
import { Hireteam } from "../development/hireteam"
import { processSteps, reactnativetech, services, technologies, techstack, whyReproSoftData } from "../../techutils.jsx/reactnative"

import { GlobalPresence } from "./globalpresence"
import { Hiring } from "./hirings"
import { Solutions } from "./solutions"
import { ContactHire } from "./contactushiring"

export const ReactNativepage =()=> {
   
    return(
        <React.Fragment>
            <Header />
          
  <div className="dot-net-development-wrapper ">
  <section className="img-background" style={{backgroundImage: `url(${reactnativetech.bgimg})`}}>
    <div className="container text-white">
    <h1>{reactnativetech.header}</h1>
    <p>{reactnativetech.content}</p>
    </div>
  </section>
  <section className="repro-service-section ">
  <div className="container">
    <div className="inner-decription mb-4">
      <p>
       {reactnativetech.abouttech}
      </p>
    </div>
    
    <div className="title-block text-center mb-5">
      <h2 className="mb-3">{reactnativetech.servicesheader}</h2>
      <p>
      {reactnativetech.servicecontent}
      </p>
    </div>
  </div>
  <div className="container">
    <div className="row">
      {technologies.map((item, index) => (
        <div
          key={index}
          className="col-lg-4 col-md-6 d-flex align-items-stretch mb-4"
        >
          <div className="card h-100 text-center border">
            <div className="card-body text-start">
              <img
                src={item.img}
                alt={item.title}
                className="mb-3"
                style={{ width: "60px", height: "auto" }}
              />
              <h3 className="card-title h5">{item.title}</h3>
              <p className="card-text">{item.description}</p>
             
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
</section>
<section className="pt-0">
      <div className="container">
        <div className="title-block mb-5 text-center">
          <h2>Our App Development Process</h2>
          <p>We ensure delivering consistent responses at every step of the app development lifecycle.</p>
        </div>
        <div className="process-wrapper">
          {processSteps.map((step, index) => (
            <div className="process-outer" key={index}>
              <div className="process-inner">
                <div className={step.class}>
                  <span>
                    <em>{step.step}</em>
                  </span>
                  <h3>{step.title}</h3>
                </div>
                <p>{step.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
    <ContactHire text={'a React Native'} />
<section className="pt-5">
    <div class="container">
        <h2 class="text-center mb-5">Why Choose React Native?</h2>
        <div class="row">
            <div class="col-12 col-md-6 mb-4">
                <div className="checkbox">
                    <h3 class="h5">Single Codebase and Reusable Code</h3>
                    <p>Mainly based on JavaScript, React Native can be used for both web and mobile platforms. A single code works for both platforms eliminating the need to develop separate apps.</p>
                </div>
            </div>
            <div class="col-12 col-md-6 mb-4">
                <div className="checkbox">
                    <h3 class="h5">Live & Hot Reloading</h3>
                    <p>Featuring an option of live reloading, react native simplifies the process of making changes to the code. Basically, you don't need to reload the app manually to see the changes.</p>
                </div>
            </div>
            <div class="col-12 col-md-6 mb-4">
                <div className="checkbox">
                    <h3 class="h5">Pre-Built components</h3>
                    <p>Another interesting advantage of using React Native is that it provides easy access to a library of pre-built components to speed up development procedure.</p>
                </div>
            </div>
            <div class="col-12 col-md-6 mb-4">
                <div className="checkbox">
                    <h3 class="h5">Modular Architecture</h3>
                    <p>React Native incorporates intuitive modules allowing developers enough flexibility to update and upgrade applications easily. Also, these modules speed up the development process.</p>
                </div>
            </div>
            <div class="col-12 col-md-6 mb-4">
                <div className="checkbox">
                    <h3 class="h5">Simple UI</h3>
                    <p>In comparison to other frameworks such as AngularJS and MeteorJS, React Native is way more mobile-friendly, responsive with quicker load times, and provides a smooth experience.</p>
                </div>
            </div>
            <div class="col-12 col-md-6 mb-4">
                <div className="checkbox">
                    <h3 class="h5">Rapid Development</h3>
                    <p>With classic support of 3rd party plugins and the extensive library of React components.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="repro-app-dev-experience " style={{ backgroundImage: `url(${reactnativetech.techexperiencebg})`,backgroundRepeat:'no-repeat' }}>
 
  <div className="container position-relative">
    <div className="text-center mb-5">
      <h2 className="text-white">{reactnativetech.techexperience}</h2>
      <p className="text-white">
        {reactnativetech.techexperiencecontent}
      </p>
    </div>
    <div className="repro-experience-wrapper bg-light p-4 rounded shadow-sm">
      <h3 className="mb-4 text-center">{reactnativetech.advancestech}</h3>
      <div className="experience-content-wrapper">
        <p>
          {reactnativetech.advancestechcontent}
        </p>
        <div class="experience-content-wrapper">
				
				<ul className="inner-page-listing two-column">
            {services.map((service, index) => (
                <li key={index}>
                    <p>{service}</p>
                </li>
            ))}
        </ul>
			</div>
      </div>
    </div>
  </div>
</section>

    {/* new start */}
    <section className="new-repro-technology-stack-section">
  <div className="container">
    <h2 className="text-center mb-5">Technology Stack</h2>
    <div >
      {techstack.map((category, index) => (
        <div key={index} >
            <div className="row">
                <div className="col-md-3 mb-5">
                <div className="techstack-title">
              <em className="me-3">
                <img src={category.icon} alt={category.title} />
              </em>
              <p className="m-0">{category.title}</p>
            </div>
          
                </div>
                <div className="col-md-9 mb-5">
                <div className="techstack-right">
              <ul className="list-unstyled">
                {category.items.map((item, i) => (
                  <li key={i} className="mb-2">
                    <i className="bi bi-check-circle-fill text-success me-2"></i>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
                </div>
            </div>
        </div>
      ))}
    </div>
  </div>
</section>


<Solutions/>
<section className="repro-why-section py-5">
  <div className="container">
    <h2 className="text-center mb-4">Why Choose RiseProSoft for React App Development??</h2>
    <div className="row justify-content-center">
      {whyReproSoftData.map((item, index) => (
        <div key={index} className="col-md-6 col-lg-3 mb-4">
          <div className="card h-100 text-center">
            <div className="card-body">
              <span className="d-inline-block mb-3">
                <img
                  alt={item.altText}
                  className="img-fluid"
                  width={60}
                  height={60}
                  src={item.imgSrc}
                />
              </span>
              <h5 className="card-title">{item.title}</h5>
              <p className="card-text">{item.description}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
</section>


<Hiring/>
   <Hireteam title={'React Native Developers'}/>
   <GlobalPresence />
  </div>

<Footer/>

        </React.Fragment>
    )
}