export const services = [
    {
      id: 1,
      title: 'Custom Software Development',
      link: '/custom-development',
    },
    {
      id: 2,
      title: 'Web Development',
      link: '/web-development',
    },
    {
      id: 3,
      title: 'Mobile App Development',
      link: '/app-development',
    },
    {
      id: 4,
      title: 'Product Development',
      link: '/product-development',
    },
    {
      id: 5,
      title: 'Dedicated Team',
      link: '/dedicated-development',
    },
    {
      id: 6,
      title: 'SAP Services',
      link: '/sap-development',
    },
    {
      id: 7,
      title: 'Testing & QA',
      link: '/testing',
    },
  ];
  export  const industries = [
    {
      id: 1,
      title: 'Finance',
      link: '/finance',
    },
    {
      id: 2,
      title: 'Insurance',
      link: '/insurance',
    },
    {
      id: 3,
      title: 'Education',
      link: '/education',
    },
    {
      id: 4,
      title: 'Energy and Utilities',
      link: '/energyutilities',
    },
    {
      id: 5,
      title: 'Healthcare',
      link: '/healthcare',
    },
    {
      id: 6,
      title: 'Media & Entertainment',
      link: '/media',
    },
    {
      id: 7,
      title: 'Oil &  Gas, Mining',
      link: '/oilgas',
    },
    {
      id: 8,
      title: 'Retail eCommerce',
      link: '/retail',
    },
    {
      id: 9,
      title: 'Logistics & Distribution',
      link: '/logistics',
    },
    {
      id: 10,
      title: 'Travel Hospitality',
      link: '/travel',
    },
    {
      id: 11,
      title: 'Public Sector',
      link: '/publicsector',
    },
  ];

  export  const techmenu = [
    {
      title: "Backend",
      subItems: [
        { name: ".NET", link: "/technologies/dotnot" },
        { name: "Java", link: "/technologies/java-development" },
        { name: "Node Js", link: "/technologies/node-js-development" },
        { name: "PHP", link: "/technologies/php-development" },
        { name: "ROR", link: "/technologies/ruby-on-rails-development" }
      ]
    },
    {
      title: "Frontend",
      subItems: [
        { name: "Angular", link: "/technologies/angular-development-services" },
        { name: "React", link: "/technologies/react-development-services" },
        { name: "Vue Js", link: "/technologies/vue-js-development-services" }
      ]
    },
    {
      title: "Mobile",
      subItems: [
        { name: "iOS", link: "/technologies/iphone-development" },
        { name: "Android", link: "/technologies/android-app-development" },
        { name: "React Native", link: "/technologies/react-native-apps" },
        { name: "Flutter", link: "/technologies/flutter-app-development" },
        { name: "Xamarin", link: "/technologies/xamarin-app-development" }
      ]
    },
    {
      title: "Others",
      subItems: [
        { name: "Sharepoint", link: "/technologies/sharepoint-development" },
        { name: "Cloud & Devops", link: "/technologies/cloud-and-devops" }
      ]
    }
  ];