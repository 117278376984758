import { useNavigate } from "react-router-dom";

export const ContactHire = ({ text }) => {
    const navigate = useNavigate();
  return (
    <div className="reprosoft-development-challenge-address mb-5 contact-sharing-section">
      <div className="container" style={{ maxWidth: "1000px" }}>
        <div className="row align-items-center">
          <div className="col-md-8 text-md-start text-center">
            <h2>Have {text} Software Development challenge to address?</h2>
          </div>
          <div className="col-md-4 text-md-end text-end mt-3 mt-md-0">
             <div className="d-flex align-items-center" style={{fontSize:'20px',cursor:'pointer'}} onClick={()=> {navigate('/contactus')}}>Contact Us <i className="fa fa-angle-right contact-fa d-flex align-items-center justify-content-center text-white mx-1"></i></div>
          </div>
        </div>
      </div>
    </div>
  );
};
