import React from "react";
import { Header } from "../header";
import { Footer } from "../home/footer";



export const Logistics = () => {
  return (
    <React.Fragment>
      <Header />

      <section className="product-section">
        <div className="container">
          <div className="banner-content-wrapper">
            <div className="container">
              <div className="banner-content text-center">
                <h1 className="text-white">
                Logistics & Transportation Software Development
                </h1>
                <p className="text-white">
                Build the right solution that fulfils the business and operational needs to track,<br/> control, and plan your logistics.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container pt-5">
        <div className="industry-expertise-wrapper pt-5">
            <div className="container pb-5 indusry-page-description">
                <div className="row">
                  <div className="col-12">
                    <div className="risepro-tools-technology-item-inner  p-0">
                      <div
                        className="risepro-tools-technology-content"
                        >
                        <p>
                          Logistics business is not merely about shipping boxes from one stop to another. 
                          It involves timely and safe delivery of goods to the customer while competing with other logistics providers and 
                          fighting for a piece of profit when the margins are low. Intelligent technology systems can help you optimize your 
                          supply chain and give you an edge over your competitors.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div className="pt-5 indusry-page-description pb-5">
            <div className="row">
              <div className="col-md-6">
              <h3>Warehouse Management</h3>
              <p>
                Scalable and easily adaptable warehouse management helps in improving order and inventory management, stock arrangements, 
                manpower management, cutting transportation costs, and provides enhanced customer satisfaction. Get rid of the old paper-pen and 
                spreadsheet methods and pave a way for streamlined logistics software development services that simplify your business.
              </p>
              </div>
              <div className="col-md-6">
              <h3>Transportation Management</h3>
              <p>
                Transportation Management Systems automate complex transportation processes that increase operational efficiency, 
                help in-vehicle monitoring, and receive real-time updates. They also contribute to making the overall transportation 
                cycle efficient which includes activities like pricing, invoicing, route and transport scheduling, status tracking and 
                reporting, generating invoices, and so on.
              </p>
              </div>
            </div>
          </div>
          <div className="industry-expertise-wrapper pt-5">
            <div>
            <div className="container pb-5 indusry-page-description">
              <div className="risepro-inner-container">
                <div className="row">
                  <div className="col-xl-4 col-lg-6 col-md-12">
                    <div className="risepro-tools-technology-item-inner p-0">
                      <div
                        className="risepro-tools-technology-content"
                        
                      >
                        <h2 className="industry-head-line">Warehouse Management System</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-6 col-md-12">
                    <div className="risepro-tools-technology-item-inner  p-0">
                      <div
                        className="risepro-tools-technology-content"
                        >
                        <p>
                          Automate your material flow irrespective of the goods and industry, gain clarity on warehousing operations, 
                          plan and manage material handling and tasks assigned to the employees with the help of our Warehouse Management Software, 
                          customized to fit your business requirements.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
               
              </div>
            </div>
           
            </div>
          </div>
          <div class="reinsurance-bottom-block pt-5">
               <div className="row">
                <div className="col-md-4">
                <ul class="inner-page-listing checklogo">
                  <li><p> Warehouse Automation </p></li>
                  <li><p> Remote Accessibility</p></li>
                  <li><p> Integration with 3rd Party Applications </p></li>
               </ul>
                </div>
                <div className="col-md-4">
                <ul class="inner-page-listing checklogo">
                  <li><p> Customized Workflow</p></li>
                  <li><p> Order & Account Management</p></li>
                  <li><p> Inbound and Outbound Operations</p></li>
               </ul>
                </div>
                <div className="col-md-4">
                <ul class="inner-page-listing checklogo">
                  <li><p> Task Management & Automation</p></li>
                  <li><p> Inventory Control</p></li>
               </ul>
                </div>
               </div>
            </div>
      </div>

      <section>
        <div class="container">
        <div class="industry-pension-fund-wrapper">
            <div className="row">
                      <div className="col-xl-5 col-lg-6 col-md-12">
                        <div>
                          <div
                            className="risepro-tools-technology-content"
                          >
                            <h2 className="industry-head-line">Airport Baggage Handling Systems</h2>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-7 col-lg-6 col-md-12">
                        <div>
                          <div
                            className="risepro-tools-technology-content"
                            >
                            <p>
                            Since the airport baggage handling systems are one of the important assets for running airport operations smoothly, 
                            we make sure to guarantee that our software solutions in this domain are robust, scalable, and extensible. 
                            Our cutting-edge airport baggage handling solution is constantly improving airport operations all around the world, 
                            making our partners more efficient and accurate while also providing passengers with a more pleasant experience. 
                            We also ensure to fulfill such technical objectives according to the airport system requirements so that the system 
                            remains always up and runs with the user friendly interface.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="reinsurance-bottom-block pt-5">
                  <ul class="inner-page-listing two-column">
                    <li><p>Interactive user interface, compatible with the airport environment, provides unparalleled system resilience.</p></li>
                    <li><p>Self-service check-in system to minimize maintenance and improve overall boarding service.</p></li>
                    <li><p>Advanced screening solution for airport security and quick luggage sorting.</p></li>
                    <li><p>Conveyor and luggage carousel management solution based on the unique airport requirements.</p></li>
                    <li><p>Early Bag Storage system to improve efficiency during the baggage makeup phase.</p></li>
                    <li><p>High-speed and efficient solutions for transportation and sortation of passenger bags.</p></li>
                    <li><p>Control and update flight information on various digital displays in waiting areas, terminals, and others.</p></li>
                    <li><p>Complete luggage tracking and monitoring through Baggage Tray Solution.</p></li>
                  </ul>
                </div>
        </div>
        </div>
      </section>

      <section className="energy-bgimg2">
      <div class="container">
      <div class="industry-pension-fund-wrapper">
        
          <div className="row">
                    <div className="col-xl-5 col-lg-6 col-md-12">
                      <div className="">
                        <div
                          className="risepro-tools-technology-content"
                          
                        >
                          <h2 className="industry-head-line">GPS Tracking Software</h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-7 col-lg-6 col-md-12">
                      <div className="">
                        <div
                          className="risepro-tools-technology-content"
                          >
                          <p>
                          GPS fleet tracking system acts as a crucial component in managing and optimizing fleet usage, reducing fuel cost, 
                          carrying scheduled maintenance, route optimization, tracking drivers’ overtime, ensuring implementation of health and safety compliances. 
                          Comprehensive and optimized GPS tracking software helps in speeding up the cargo loading and processing, freight movement, 
                          real-time data provisioning, electronic document sharing, and optimization of logistic activities.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
          <div class="pension-bottom-block pt-5">
              <ul class="inner-page-listing two-column">
                <li><p>Integration with multiple kinds of devices</p></li>
                <li><p>Route Management &amp; optimized routes</p></li>
                <li><p>Socket base TCP/UDP communication&nbsp;</p></li>
                <li><p>Driver Behaviour Analysis</p></li>
                <li><p>Tracking of vehicles and real-time plotting on maps</p></li>
                <li><p>Delivery Status and Order Tracking&nbsp;</p></li>
                <li><p>Geofencing for asset monitoring</p></li>
                <li><p>Notifications based on events</p></li>
                <li><p>Calendars/Orders/Tasks Synchronisation</p></li>
                <li><p>Vehicle maintenance report</p></li>
              </ul>
          </div>
      </div>
  </div>
      </section>

      <section>
        <div class="container">
        <div class="industry-pension-fund-wrapper">
          <div className="row">
                  <div className="col-xl-5 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        
                      >
                        <h2 className="industry-head-line">Fleet Maintenance System</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        >
                        <p>
                        Invest in an intelligent solution that covers all your fleet maintenance concerns. 
                        A streamlined fleet maintenance system lets you track repairs, manages and sends notifications 
                        for preventative maintenance of vehicles, engine faults, work orders, accident management and generates comprehensive reports.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="reinsurance-bottom-block pt-5">
               <ul class="inner-page-listing two-column">
                <li><p>Vehicle details management</p></li>
                <li><p>Garage Invoices</p></li>
                <li><p>Maintenance information</p></li>
                <li><p>Spare Parts Return Management</p></li>
                <li><p>Estimation of Repair</p></li>
                <li><p>Credit Note of Returned Items</p></li>
                <li><p>Insurance Claim Evaluation</p></li>
                <li><p>Invoice Export / Accounting System Integration</p></li>
                <li><p>Repair work review & authorization</p></li>
                <li><p>Order management & supplier Invoices</p></li>
                <li><p>Vehicle repair tracking</p></li>
                <li><p>Static & Dynamic Reports</p></li>
                <li><p>Tyre Supplier Service Integration (Pirelli / Michelin / Continental / Yokohama)</p></li>
               </ul>
            </div>
          </div>
        </div>
      </section>

      <section class="iotsection">
      <div class="text-center">
                <h2 className="text-white">Freight Management System</h2>
            </div>
          <div class="container bg-white p-5" style={{borderRadius:'16px'}}>
            
            <div class="industry-claim-policy-wrapper">
                <p>
                Experience smooth international freight by beginning your digital journey with a trusted software development company. 
                Our custom freight management solutions are designed for importers, brokers, freight forwarders and exporters to engage with 
                reliable stakeholders to accelerate and manage the logistics cycle in its entirety including instant pricing, quoting, 
                freight rate management and integration of shipping services.
                </p>
                <ul class="inner-page-listing two-column">
                <li><p>Manage shipment and consolidate movements from multiple carriers by monitoring shipment status and tracking data</p></li>
                <li><p>Consolidation of shipments and profit percentage, foreign agent invoices, integration with accounting software</p></li>
                <li><p>Empty container pickup, Container stuffed, ETD container from the port, Gate in empty, Gate in full for export, ETA, and many more features.</p></li>
                <li><p>Document management for Air AMS customs requirements, shipment tracking and secured API for overseas system integration</p></li>
                <li><p>ABI/ACE compatible module for a brokerage with customs regulation compliance and validation of data</p></li>
                <li><p>NVOCC for import and integration with a brokerage, Arrival notices, Pre alerts, Devanning, invoicing, ABI &amp; inbound</p></li>
                <li><p>Ease of export with ocean master bills and ocean bills, document generation for customs compliance</p></li>
                <li><p>Accounting of all the invoices, checks, ledgers, journals and financial statements for profit and loss</p></li>
                </ul>
            </div>
          </div>
      </section>

      <div>
          <div class="container bg-white p-5" style={{borderRadius:'16px'}}>
            
            <div class="industry-claim-policy-wrapper">
            <h1>Importance of technology in logistics</h1>
            <div className="pt-5">
            <div className="row ">
              <div className="col-md-6">
                <h3>Improved fleet productivity & optimal vehicle utilization</h3>
                <p class="" style={{height: "96.0938px"}}>
                  Reduced total cost of ownership, vehicle lifecycle management, fuel expense tracking and avoiding fleet idling 
                  time helps increase the overall fleet productivity
                </p>
              </div>
              <div className="col-md-6">
                <h3>Seamless communication between stakeholders</h3>
                <p class="" style={{height: "96.0938px"}}>
                  Ease of data sharing and communication between stakeholders 
                  like manufacturers, retailers, suppliers, distributors, etc. allows better decision making
                </p>
              </div>
              <div className="col-md-6">
              <h3>Automation to avoid errors</h3>
              <p class="" style={{height: "96.0938px"}}>
                Automate business processes to avoid inconsistencies, inefficient & redundant 
                data and manual error to fasten the overall shipping cycle
              </p>
              </div>
              <div className="col-md-6">
              <h3>Operational Efficiency and Reduced Costs</h3>
              <p class="" style={{height: "96.0938px"}}>
                Automated back-end, seamless transfer of data to the ERP system, effective inventory 
                management help in increasing efficiency and decreasing overall costs
              </p>
              </div>
            </div>
          </div>
                {/* <ul class="inner-page-listing two-column">
                <li><p>
                    <div class="content-wrapper">
                        <h3>Improved fleet productivity &amp; optimal vehicle utilization</h3>
                        <p class="" style="height: 96.0938px;">Reduced total cost of ownership, 
                        vehicle lifecycle management, fuel expense tracking and avoiding fleet idling 
                        time helps increase the overall fleet productivity</p>
                    </div>
                </li>
                <li><p>
                    <div class="content-wrapper">
                        <h3>Seamless communication between stakeholders</h3>
                        <p class="" style="height: 96.0938px;">Ease of data sharing and communication between stakeholders 
                        like manufacturers, retailers, suppliers, distributors, etc. allows better decision making</p>
                    </div>
                </li>
                <li><p>
                    <div class="content-wrapper">
                        <h3>Automation to avoid errors</h3>
                        <p class="" style="height: 96.0938px;">Automate business processes to avoid inconsistencies, inefficient & redundant 
                        data and manual error to fasten the overall shipping cycle</p>
                    </div>
                </p></li>
                <li>
                  <p>
                    <div class="content-wrapper">
                        <h3>Operational Efficiency and Reduced Costs</h3>
                        <p class="" style="height: 96.0938px;">Automated back-end, seamless transfer of data to the ERP system, effective inventory 
                        management help in increasing efficiency and decreasing overall costs</p>
                    </div>
                </p></li>
                </ul> */}
            </div>
          </div>
      </div>
      
      <Footer/>
    </React.Fragment>
  );
};
