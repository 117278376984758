import React from "react";
import { Header } from "../header";
import { Footer } from "../home/footer";
import TechnologyStackGrid from "./techstack/techStack";
import { TechnologyStackData } from "./technicalstacks";
import { Hireteam } from "./hireteam";



const services = [
  {
    imgSrc: "images/service-icon-1.webp",
    title: "Faster Resource Accessibility",
    description: "By hiring a dedicated development team, you gain immediate access to skilled team members like software developers, architects, QA engineers, team leads, and project managers. The team is ready to deploy modern tools and agile methodologies, ensuring efficient project management and timely updates through regular scrum meetings.",
  },
  {
    imgSrc: "images/service-icon-2.webp",
    title: "Faster Time to Market",
    description: "Dedicated developers have the skills and resources to complete projects on time. Dedicated teams work in an optimized environment focused on your project, supporting seamless development. They follow established workflows and procedures to ensure timely deliveries.",
  },
  {
    imgSrc: "images/service-icon-3.webp",
    title: "Focus on Core business",
    description: "Concurrently manage your core business agendas and software development project goals without any hassle. Dedicated software engineers develop and project managers manage your software development demands from scratch while you focus on your core business competencies.",
  },
  {
    imgSrc: "images/service-icon-4.webp",
    title: "Cost Effective & Flexible Solutions",
    description: "Hiring a dedicated development team saves you the costs of recruiting and training the professionals. A dedicated team model is a transparent model with no hidden costs. You can also easily scale or modify dedicated teams to adjust to changing requirements, budgets, and technologies.",
  },
  {
    imgSrc: "images/service-icon-5.webp",
    title: "Larger Technology Pool",
    description: "A Dedicated team comprises versatile IT professionals with diverse skill sets. They are well-trained and experienced in modern technologies, tools, and methodologies. Access to a diverse range of resources helps you drive innovation and gain a competitive edge.",
  },
];


export const DedicatedTeam = () => {
  return (
    <React.Fragment>
      <Header />
      <section className="team-section">
        <div className="container">
          <div className="banner-content-wrapper">
            <div className="container">
              <div className="banner-content text-center">
                <h1 className="text-white">
                Dedicated Software Development Team
                </h1>
                <p className="text-white">
                Bring your vision to life with the help of our dedicated development team that works as an extension of your own. We provide top-tier talent, and you have the freedom to screen, pick, and manage your dedicated team.
                </p>
              
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{paddingBottom:'0'}}>
        <div className="container">
          <div className="title-block text-center mb-4">
           <h2>Dedicated Development Team Services</h2>
            <p>
            We offer flexible engagement models as per business needs and project scope ensuring higher efficiency and top-notch resources. Our wide range of service offerings spans across different domains to provide ongoing project support with high-end software development solutions.
            </p>
          </div>
          <div className="work-grid-wrapper">
            <div className="row">
              {services.map((service) => (
                <div className="col-md-4 mb-4" key={service.id}>
                  <div className="dev-card">
                    <div className="icon">
                      <img src={service.imgSrc} alt={service.altText} />
                    </div>
                    <div className="card-body">
                      <h4 className="card-title">
                        <a href={service.link} title={service.title}>
                          {service.title}
                        </a>
                      </h4>
                      <p className="card-text">{service.description}</p>
                      <p className="text-end text-sm more-content">More <i className="fa fa-arrow-right" style={{color:'#1329e9'}}></i></p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="risepro-technology-stack-section">
  <div className="container">
    <h2 className="text-center mb-3">Technology Stack</h2>
    <TechnologyStackGrid data={TechnologyStackData} />
  </div>
</section>
<Hireteam title={'Dedicated Developers'} />

      <Footer/>
    </React.Fragment>
  );
};