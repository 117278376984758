export const phptech = {
  header : 'PHP DEVELOPMENT COMPANY',
  content: `As a PHP Development company with 50+ active involvement in PHP-based projects, RaiseProSoft excels in creating dynamic, database-driven, high-performing web applications using Agile methodologies and custom PHP development techniques.`,
  bgimg : '/images/php-banner.webp',
  abouttech: `PHP, a widely used programming language, offers flexibility and scalability, enabling complex functionalities for websites. Whether it's a simple portal or a complex enterprise solution with mission-critical tasks, RaiseProSoft, with its extensive experience in PHP web development, empowers business growth and competitive advantage. We specialize in developing custom PHP web apps, including e-learning platforms, multilingual and multi-currency e-commerce websites, community networking sites, business applications, CRMs, and CMSs, all enriched with unique features and functionalities. Our deep understanding of various industry domains, combined with our developers' technical expertise, positions us to deliver unparalleled digital services.`,
  servicesheader: 'PHP Development Services',
  servicecontent: `Our PHP web development services are purpose-built to be stable, maintainable, and flexible, offering seamless integration and interfacing capabilities with your existing assets.`,
  techexperiencebg : '/images/node-js-development-experience-banner.webp',
  techexperience: 'PHP Development Experience',
  techexperiencecontent: `Our consistent delivery of high-performance PHP projects has established us as a reliable and effective service provider, supporting your business needs.`,
  advancestech: 'Advanced PHP Applications',
  advancestechcontent: `We utilize top-tier tools, resources, and expertise to develop rapid, responsive, and resilient PHP applications for businesses.`
}
export const technologies = [
{
  title: "Custom PHP Development Project",
  img: "/images/custom_php_development.svg",
  description: "Our PHP developers have expertise in building secure, sustainable, scalable and custom PHP projects irrespective of the PHP framework such as Laravel, Yii, Zend or CodeIgniter.",
  bulletPoints: [
    "Dynamic & User-Friendly Solution",
    "Reusable Codebase",
    "Rapid Development"
  ]
},
{
  title: "API Development and Integration",
  img: "/images/api_development_and_integration.svg",
  description: "Our expertise as a PHP web development company extends to crafting fast, secure, and scalable APIs. These APIs facilitate seamless communication between the front-end and external systems.",
  bulletPoints: [
    "REST APIs",
    "API Security",
    "Scalable Solution"
  ]
},
{
  title: "eCommerce Development",
  img: "/images/e_commerce_development.svg",
  description: "Specializing in Magento, OpenCart, Shopify, and WooCommerce, RaiseProSoft delivers comprehensive services from strategy to implementation for result-driven online stores.",
  bulletPoints: [
    "Comprehensive",
    "Robust Features",
    "Secure Gateways"
  ]
},
{
  title: "CMS Development",
  img: "/images/cms_development.svg",
  description: "Utilizing PHP-based CMS platforms such as WordPress and Drupal, we deliver complex designs and functionalities in such a way that it becomes easier to manage.",
  bulletPoints: [
    "Flexible Modules",
    "Simplified Functionalities",
    "Easier App Management"
  ]
},
{
  title: "PHP App Maintenance",
  img: "/images/php_application_maintenance.svg",
  description: "Our services encompass code cleanup, version updates, bug fixing, troubleshooting, enhancing processing capabilities, security updates, and more.",
  bulletPoints: [
    "App Optimization",
    "Version Control",
    "Performance Improvement"
  ]
},
{
  title: "Cloud Solutions",
  img: "/images/cloud-migration.svg",
  description: "Utilize our cloud-based solutions to meet your SaaS needs. Our deep understanding of cloud technologies ensures a seamlessly compatible application for your business.",
  bulletPoints: [
    "Deployment on Cloud",
    "Cloud Monitoring",
    "Database Hosting"
  ]
}
]
export const techstack = [
  {
    title: "Framework & Libraries",
    icon: "/images/core-lib-blue-tech-ic.svg",
    items: [
      "Laravel",
      "Lumen",
      "Symfony",
      "Yii",
      "CodeIgniter",
      "Drupal"
    ]
  },
  {
    title: "Frontend",
    icon: "/images/frontend-blue-tech-ic.svg",
    items: [
      "React",
      "Angular",
      "Vue",
      "jQuery",
      "Bootstrap"
    ]
  },
  {
    title: "Database",
    icon: "/images/database-blue-tech-ic.svg",
    items: [
      "SQL Server",
      "PostgreSQL",
      "MySQL",
      "MongoDB",
      "Azure SQL",
      "Amazon RDS",
      "Oracle",
      "Cassandra",
      "Elasticsearch",
      "Dynamo"
    ]
  },
  {
    title: "Cloud & DevOps",
    icon: "/images/cloud-devops-blue-tech-ic.svg",
    items: [
      "AWS",
      "Azure",
      "Google Cloud",
      "Docker",
      "Kubernetes",
      "Jenkins",
      "Terraform"
    ]
  },
  {
    title: "Packages",
    icon: "/images/packages-blue-tech-ic.svg",
    items: [
      "Magento",
      "Shopify",
      "Wordpress",
      "Drupal",
      "Moodle"
    ]
  },
  {
    title: "E-commerce Platforms",
    icon: "/images/e-commerce-blue-tech-ic.svg",
    items: [
      "Magento",
      "Shopify"
    ]
  },
  {
    title: "Testing",
    icon: "/images/qa-testing-blue-tech-ic.svg",
    items: [
      "Selenium",
      "Katalon Test Studio",
      "Test Rail",
      "Apache JMeter",
      "OWASP ZAP",
      "BrowserStack",
      "Postman"
    ]
  },
  {
    title: "Caching",
    icon: "/images/caching-blue-tech-ic.svg",
    items: [
      "Memcached",
      "Redis"
    ]
  },
  {
    title: "Project Management Tools",
    icon: "/images/project-management-blue-tech-ic.svg",
    items: [
      "Jira",
      "Slack",
      "Trello",
      "Microsoft Team"
    ]
  }
];



export const services = [
   "SaaS and product development",
   "Hospitality solution",
   "eCommerce stores",
   "Crowdfunding portal development",
   "Real Estate portal",
   "Healthcare application",
   "Logistics solutions",
   "Education and eLearning solution",
   "Service portal implementation",
   "Social networking portal",
   "Marketplace development",
   "Corporate CMS websites",
];

export const whyReproSoftData = [
{
  title: "Data-Driven Solutions",
  description: "Informed by comprehensive data, we tailor solutions to meet evolving market dynamics and enhance customer satisfaction.",
  imgSrc: "/images/data-driven-solution.svg",
  altText: "code"
},
{
  title: "Robust Architecture",
  description: "RaiseProSoft prioritizes robust and simplified software architecture, ensuring adaptability, sustainability, and optimized system performance.",
  imgSrc: "/images/why-raisepro-agile.svg",
  altText: "agile"
},
{
  title: "Proven Track Record",
  description: "Our track record is proven by the high-quality solutions that have consistently empowered our clients. Visit our portfolio for examples.",
  imgSrc: "/images/why-raisepro-domain.svg",
  altText: "process"
},
{
  title: "Clear Strategic Communication",
  description: "Our straightforward and transparent approach fosters clear communication, clarifying business objectives internally and externally.",
  imgSrc: "/images/clear-strategic-solution.svg",
  altText: "medal"
}
];
