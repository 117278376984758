export const fluttertech = {
  header : 'Flutter App Development',
  content: `The competition in the app industry is cut-throat and we at RaiseProSoft aim at assisting you in indulging your customers with user friendly, high performing, functional & mesmerizing apps. We deep dive into your business idea for the app and use the revolutionary Flutter framework to implement the project effectively.`,
  bgimg : '/images/flutter-banner.webp',
  abouttech: `Mainly based on the Dart programming language, Flutter provides widgets that are completely out of the box. As one of the leading Flutter development companies, RaiseProSoft provides an extensive range of next-generation app development services. Leveraging all the differentiating functionalities Flutter has to offer, we craft high-quality native interfaces on the cross-platform in the least possible time. Our team of Flutter developers have vast experience in developing beautiful & functional-rich mobile apps, crafted to meet and deliver the expectations of our clients.`,
  servicesheader: 'Flutter Development Services',
  servicecontent: `We deliver best-in-class highly secure, sustainable and scalable custom Flutter development services`,
  techexperiencebg : '/images/flutter-development-experience-banner.webp',
  techexperience: 'Flutter App Development Experience',
  advancestech: 'Advanced Flutter Applications',
  advancestechcontent: `We specialize in offering advanced Flutter Application to build compatible, cross-platform and custom configurable mobile applications.`
}
export const technologies = [
{
  title: "Flutter Mobile App Development Consulting",
  img: "/images/flutter_mobile_app_development_consulting.svg",
  description: "Our team understands your business needs and creates powerful mobile app or MVP out of them using Flutter. We make sure the solution we deliver satisfies the end-users and current market needs."
},
{
  title: "Flutter Application Development",
  img: "/images/flutter_application_development.svg",
  description: "Our Flutter development services combine the business requirements, leading industry tools and technologies in a smart and effective manner to surpass the expectations of our clients."
},
{
  title: "UI/UX design",
  img: "/images/ui_ux_design.svg",
  description: "A successful app not only focuses on programming but also on building eye-catching interface. Our app designers adhere to the best practices and industry standards to deliver as per your UI requirements."
},
{
  title: "Back-end engineering",
  img: "/images/back_end_engineering.svg",
  description: "Our team makes it easy to create a secure and scalable backend solution, implementing third-party API integrations along with taking care of the evolving business needs."
},
{
  title: "Testing & Maintenance",
  img: "/images/testing_maintenance.svg",
  description: "We provide end-to-end flutter QA services to ensure that the app performance is smooth across all platforms."
},
{
  title: "Team Augmentation",
  img: "/images/team_augmentation.svg",
  description: "We make it easy for you to handle, closely manage and ramp up the resources to accommodate the ever changing business needs."
}
]
export const techstack = [
{
  title: "Development Tools",
  icon: "/images/development-tools-blue-tech-ic.svg",
  items: ["Android Studio", "Visual Studio Code (VS Code)"]
},
{
  title: "State Management",
  icon: "/images/state-manage-blue-tech-ic.svg",
  items: ["Freezed", "Bloc Pattern"]
},
{
  title: "Database & API Integration",
  icon: "/images/database-api-blue-tech-ic.svg",
  items: ["Firebase", "GraphQL", "SQLite"]
},
{
  title: "QA and Testing",
  icon: "/images/testing-blue-tech-ic.svg",
  items: ["Firebase Crashlytics", "Mockito"]
},
{
  title: "CI/CD and Version Control",
  icon: "/images/ci-cd-blue-tech-ic.svg",
  items: ["GitHub Actions", "Git", "GitHub", "GitLab", "Bitbucket"]
},
{
  title: "Design, Prototyping, and Asset Management",
  icon: "/images/design-prototype-asset-blue-tech-ic.svg",
  items: ["Figma", "Adobe XD", "Shimmer", "Zeplin"]
},
{
  title: "Platforms Management",
  icon: "/images/platform-blue-tech-ic.svg",
  items: ["Android", "IOS", "Web"]
},
{
  title: "Other Tools and Libraries",
  icon: "/images/other-blue-tech-ic.svg",
  items: ["Overlays", "Flutter Flavorizr", "DartPad with Flutter", "Very Good CLI", "FVM (Flutter Version Management)"]
}
];



export const services = [
          "Custom Flutter applications",
          "Augmented Reality apps",
          "Cross-platform development with iOS/Android SDK",
          "SaaS based extensible applications",
          "Enterprise application with deployment",
          "Custom components development",
          "Mobile ERP, POS and M-commerce",
          "Social networking app with instant messaging, video and voice",
          "Flutter Dart App development",
          "Online Audio / Video Streaming Apps",
];

export const whyReproSoftData = [
{
  title: "Advantageous Experience",
  description: "Our broad experience in catering to multiple industries and addressing their individual needs has made us the most versatile software development company.",
  imgSrc: "/images/why-raisepro-cycle-development.svg",
  altText: "code"
},
{
  title: "Faster Onboarding",
  description: "We hold an upper hand in diversified projects of any technology or industry or size of the project and make solutions imperceptible in regards to time, culture and location.",
  imgSrc: "/images/why-raisepro-agile.svg",
  altText: "agile"
},
{
  title: "Database Decision-Making",
  description: "We have a knack of business knowledge and can convert your business objectives into reality with our tech-enabled and data-driven customized solutions.",
  imgSrc: "/images/why-raisepro-domain.svg",
  altText: "process"
},
{
  title: "Business goals & Insights",
  description: "We delve deeper into short-term and long-term vision of the project and after getting acquainted with client's goals, we draft a customized software solution.",
  imgSrc: "/images/why-raisepro-dedicated-team.svg",
  altText: "medal"
}
];


export const processSteps = [
  {
    step: "01",
    title: "Discovery",
    description: "Whether you are consulting us for the very first time or thinking of reviving your fledgling app, we will spend an adequate amount of time in assessing your project. We provide recommendations only after brainstorming different ideas, reviewing your business processes, architectural and infrastructural concerns.",
    class: "heading-part"
  },
  {
    step: "02",
    title: "UI/UX Design",
    description: "Whether you are planning to develop an interface for a brand-new mobile app or thinking of upgrading an existing one, our android application developers team provides you with a design that targets your key performance indicators and hits the right spots in terms of usability and appeal.",
    class: "heading-part green-box"
  },
  {
    step: "03",
    title: "Development",
    description: "After thriving for so long in an Agile development environment, we have gained a deep understanding and the importance of providing timely updates and deliverables to the client. We strongly believe in continuous delivery, update, and deployment of code.",
    class: "heading-part purple-box"
  },
  {
    step: "04",
    title: "API Integration",
    description: "Depending on the client requirements, we integrate their app with 3rd party and Custom API applications like Instagram, YouTube, payment gateways for carrying out secure transactions as well as numerous other applications and data sources.",
    class: "heading-part orange-box"
  },
  {
    step: "05",
    title: "Testing",
    description: "Quality assurance is a critical step that we take care of not only during the development lifecycle of the app but also post-development. We make sure that all types of necessary functional and non-functional testing are performed in the given lifecycle of app development.",
    class: "heading-part blue-box"
  },
  {
    step: "06",
    title: "Deployment",
    description: "Lastly, it's now time to deploy your app on Google Play Store and other similar platforms. We also assist in the app configuration process by taking care of various required factors. All other custom needs will be addressed before the final deployment of the app.",
    class: "heading-part light-green-box"
  },
  {
    step: "07",
    title: "Maintenance",
    description: "The job is not over once the app is deployed initially since maintenance, support, and optimization are required on a regular basis as we move along. One also needs to evolve and cater to user feedback they receive and keep deploying updated versions of the app.",
    class: "heading-part violet-box"
  }
];