export const GlobalPresence =()=> {
   return (
      <section className="user-section global-section" style={{backgroundImage: `url('/images/global-presence-map-3.webp')`}}>
         <div className="container">
            <div className="">
               <div className="">
               <div class="content-inner" style={{maxWidth:'640px'}}>
                 <h1 className="mb-3">Global Presence</h1>
                 <p className="mb-4">As a Node.js development company, RaiseProSoft has marked its global presence with offices in five countries, including US, UK, Canada, Australia and India, with a skilled team specializing in different tech stacks.</p>
                 <p>We are tapping into the power of the digital world, creating high impact solutions to boost business efficiency. RaiseProSoft as a Custom Software Development Company is committed to provide End-to End Customized Solutions to our global customers and  tackle the technology problems faced by businesses.</p>
               </div>
               </div>
            </div>
         </div>
      </section>
   )
}