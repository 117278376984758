import React from 'react';
import { Link } from 'react-router-dom';

const services = [
  {
    id: 1,
    imgSrc: 'images/service-icon-1.webp',
    altText: 'Custom Software Development',
    title: 'Custom Software Development',
    description: 'Translate unique client requirements into software solutions with premier quality and advanced technologies.',
    link: '/custom-development',
  },
  {
    id: 2,
    imgSrc: 'images/service-icon-2.webp',
    altText: 'Web Development',
    title: 'Web Development',
    description: 'Revolutionize your business ideas using web development services for innovative and next-gen web apps.',
    link: '/web-development',
  },
  {
    id: 3,
    imgSrc: 'images/service-icon-3.webp',
    altText: 'Mobile App Development',
    title: 'Mobile App Development',
    description: 'Achieve digital transformation by developing scalable, engaging and feature-rich mobile applications.',
    link: '/app-development',
  },
  {
    id: 4,
    imgSrc: 'images/service-icon-4.webp',
    altText: 'Product Development',
    title: 'Product Development',
    description: 'Intellectually comprehend client\'s business ideas to develop innovative software solutions with scalable product architecture.',
    link: '/product-development',
  },
  {
    id: 5,
    imgSrc: 'images/service-icon-5.webp',
    altText: 'Dedicated Team',
    title: 'Dedicated Team',
    description: 'Grow your business development team as per project size and meet intermittent deadlines utilizing our flexible resource model.',
    link: '/dedicated-development',
  },
  {
    id: 6,
    imgSrc: 'images/service-icon-6.webp',
    altText: 'SAP Services',
    title: 'SAP Services',
    description: 'Maintain Quality Assurance by rectifying errors and debugging applications for a high-performing application.',
    link: '/sap-development',
  },
  {
    id: 7,
    imgSrc: 'images/service-icon-6.webp',
    altText: 'Testing & QA',
    title: 'Testing & QA',
    description: 'Maintain Quality Assurance by rectifying errors and debugging applications for a high-performing application.',
    link: '/testing',
  },
];

export const Development = () => {
  return (
   <React.Fragment>

    <section className="servicesection" id="services">
      <div className="container">
        <div className="text-center mb-5">
          <h1 className='pt-5'style={{color:"white"}}>Services We Provide</h1>
        </div>

        <div className="container">
          <div className="row">
            {services.map((service) => (
              <div key={service.id} className="col-md-4 mb-4">
                <div className="dev-card" style={{backgroundColor:"white"}} >
                  <div className="icon">
                    <img src={service.imgSrc} alt={service.altText} />
                  </div>
                  <div className="card-body">
                    <h4 className="card-title">
                      <a href={service.link} title={service.title}>
                        {service.title}
                      </a>
                    </h4>
                    <p className="card-text">
                      {service.description}
                    </p>
                    <Link className='learnmore' to={service.link}><i className='fa fa-arrow-right text-black'></i></Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
   </React.Fragment>
  );
};
