export const nodetech = {
  header : 'Node JS DEVELOPMENT COMPANY',
  content: `RaiseProSoft, a reliable NodeJS Development company that offers
              end-to-end services ranging from application development to
              NodeJS migration, consulting services, and maintenance &
              support. We ensure superior reliability, scalability & stability
              in every manner for your business.`,
  bgimg : '/images/nodejs-banner.webp',
  abouttech: `Node.js is gaining popularity because of its rich data exchange
            experience when multi-media, chat services and other high data
            demanding services like real time tracking are involved. RaiseProSoft
            is a leading Node JS web development company with expertise in
            launching cloud based and server less applications. Building
            NodeJS applications that require asynchronous programming,
            scalability and high performance such as chatting, IoT data
            processing and collaboration tools is what we do best. We provide
            NodeJS development services to build highly scalable and
            real-time web apps, consistently delivering state-of-the-art
            solutions as per the business needs of our clients.`,
  servicesheader: 'Node js Development Services',
  servicecontent: `Our highly skilled Node.js developers have a consistent track
            record of improving and scaling the business operations for
            customers across major industries`,
  techexperiencebg : '/images/node-js-development-experience-banner.webp',
  techexperience: 'Node.js Development Experience',
  techexperiencecontent: `As one of the experienced Node.js development companies, RaiseProSoft has delivered some amazingly fast, scalable and flexible applications to our clients.`,
  advancestech: 'Advanced NODE.JS Applications',
  advancestechcontent: `We, as a Node.js development service provider, build lightweight, high-performance and scalable apps for various businesses with the help of our Node.js web development team of experts.`
}
export const technologies = [
{
  title: "Node.js API development and Integration",
  img: "/images/node.js_API_development_and_Integration.svg",
  description: "Right from the implementation of custom APIs using Node.js to ensure seamless integration with any solutions, we deliver real-time and data-intensive scalable solutions irrespective of the development environment."
},
{
  title: "Serverless Node.js Microservices",
  img: "/images/serverless_node.js_microservices.svg",
  description: "Utilizing the right set of tools and industry best practices, we as a Node.js development company build robust applications thus providing on-demand real world applications with faster development and deployment."
},
{
  title: "Node.js consulting",
  img: "/images/node.js_consulting.svg",
  description: "We follow a precise development process for studying and analyzing the current client architecture, we leverage our Node.js software app development expertise to scale up, debug, advice and incorporate Node.js best practices."
},
{
  title: "Node.js Migration",
  img: "/images/node.js_migration.svg",
  description: "Our Node.js developers have migrated many complex applications developed on different platforms to NodeJS and successfully improved scalability, various performance metrics, and customer experience."
},
{
  title: "Node.js Plugin development",
  img: "/images/node.js_plugin_development.svg",
  description: "With our extensive experience in creating a variety of world-class plugins and API integrations, we cater to a wide range of small and large sized business organizations for their application development and enhancement."
},
{
  title: "Dedicated Node.js Team",
  img: "/images/dedicated_node.js_team.svg",
  description: "We have a dedicated team of Node.js developers who are highly skilled, creative and experienced in the technology, always available and enthusiastic to provide an extended arm and assist you on your project's evolving needs."
}
]
export const techstack = [
  {
    title: "Core Libraries and Frameworks",
    icon: "/images/core-lib-blue-tech-ic.svg",
    items: [
      "Express.js",
      "Nest.js"
    ]
  },
  {
    title: "Frontend",
    icon: "/images/frontend-blue-tech-ic.svg",
    items: [
      "React",
      "Angular",
      "Vue",
      "jQuery",
      "Bootstrap"
    ]
  },
  {
    title: "Database ORM",
    icon: "/images/db-orm-blue-tech-ic.svg",
    items: [
      "Sequelize",
      "Mongoose",
      "TypeORM"
    ]
  },
  {
    title: "Database",
    icon: "/images/database-blue-tech-ic.svg",
    items: [
      "MYSQL",
      "PostGreSQL",
      "MongoDB",
      "Firebase"
    ]
  },
  {
    title: "Caching",
    icon: "/images/caching-blue-tech-ic.svg",
    items: [
      "Memcached",
      "Redis",
      "Node Cache Manager"
    ]
  },
  {
    title: "Web Server",
    icon: "/images/web-server-blue-tech-ic.svg",
    items: [
      "Nginx",
      "Apache"
    ]
  },
  {
    title: "Architecture",
    icon: "/images/architecture-blue-tech-ic.svg",
    items: [
      "Microservices",
      "Monolithic",
      "Serverless"
    ]
  },
  {
    title: "Cloud & DevOps",
    icon: "/images/cloud-devops-blue-tech-ic.svg",
    items: [
      "AWS",
      "Azure",
      "Google Cloud Platform",
      "Docker",
      "Kubernetes"
    ]
  },
  {
    title: "CI/CD",
    icon: "/images/ci-cd-blue-tech-ic.svg",
    items: [
      "Code Pipeline",
      "Jenkins",
      "CircleCI"
    ]
  },
  {
    title: "API",
    icon: "/images/api-blue-tech-ic.svg",
    items: [
      "REST API",
      "GraphQL"
    ]
  },
  {
    title: "QA and Testing",
    icon: "/images/qa-testing-blue-tech-ic.svg",
    items: [
      "SuperTest",
      "Jest"
    ]
  },
  {
    title: "Project Management Tools",
    icon: "/images/project-management-blue-tech-ic.svg",
    items: [
      "Github",
      "BitBucket",
      "Code Commit"
    ]
  },
  {
    title: "Other Libraries and Tools",
    icon: "/images/other-blue-tech-ic.svg",
    items: [
      "Puppeteer",
      "Socket.io",
      "node cron",
      "Lodash",
      "Nodemailer",
      "Winston",
      "Moment",
      "Axios",
      "JWT"
    ]
  }
];


export const services = [
  "Smart City Application with real time data and security features",
  "Socket io and Web RTC based audio, Video and Text chat",
  "Real time tracking, automation and booking for boats",
  "Food ordering and Tracking app",
  "eCommerce with drop shipping functionality",
  "Chat bot applications",
  "Integration with Amplify Framework on AWS",
  "Content Management Application",
  "Serverless Microservices based app Development",
  "Dashboard and Analytics"
];

export const whyReproSoftData = [
{
  title: "Flexible Engagement Model",
  description: "We provide flexible engagement models using our skills for all types of software development projects assuring on-time delivery and agility.",
  imgSrc: "/images/why-raisepro-cycle-development.svg",
  altText: "code"
},
{
  title: "Consistent Delivery",
  description: "We combine our efforts with an agile methodology and iterative approach to ensure timely deliveries despite tight deadlines, rework, and reschedules.",
  imgSrc: "/images/why-raisepro-agile.svg",
  altText: "agile"
},
{
  title: "On-Scale Demand",
  description: "We employ software engineers into different projects and assignments as per the need. Let your unique business demand meet our multi-skilled specialists!",
  imgSrc: "/images/why-raisepro-domain.svg",
  altText: "process"
},
{
  title: "Advantageous Experience",
  description: "With a multitude of projects handled and solutions catered to various industries, we have demonstrated excellent business results due to our domain expertise.",
  imgSrc: "/images/why-raisepro-dedicated-team.svg",
  altText: "medal"
}
];
