import React from "react";
import { Header } from "../header";
import { Footer } from "../home/footer";


export const EnergyUtilities = () => {
  return (
    <React.Fragment>
      <Header />
      <section className="testing-section energy-bgimg">
        <div className="container">
          <div className="banner-content-wrapper">
            <div className="container">
              <div className="banner-content text-center">
                <h1 className="text-white">
                ENERGY AND UTILITIES
                </h1>
                <p className="text-white">
                We provide state-of-the-art energy software services which help enterprises deliver sustainable 
				energy products by finding efficient ways to reinvent their business with innovative technology solutions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container">
          <div className="industry-expertise-wrapper">
            <div>
            <div className="container pb-5 indusry-page-description">
              <div className="risepro-inner-container">
                <div className="row">
                  <div className="col-xl-4 col-lg-6 col-md-12">
                    <div className="risepro-tools-technology-item-inner">
                      <div
                        className="risepro-tools-technology-content"
                        
                      >
                        <h2 className="industry-head-line">Our Expertise</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-6 col-md-12">
                    <div className="risepro-tools-technology-item-inner">
                      <div
                        className="risepro-tools-technology-content"
                        >
                        <p>
                          Majority of energy and utility companies are determined to transform their 
                          business portfolio such that it not only meets the desired financial goals but also the regulatory 
                          requirements and renewable energy solutions. RaiseProSoft offers latest energy software services which 
                          increase productivity and  keep reliability and ecological safety at the centre of it. The world of energy 
                          is advancing with the use of data science, analytics, machine learning and cloud to build, distribute and 
                          manage renewable energy sources, power grids, smart cities and many other innovations and our energy software 
                          development team is ready to embark on this journey with you.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
            </div>
          </div>
          <div className="row pt-5">
                  <div className="col-xl-4 col-lg-6 col-md-12">
                    <div className="risepro-tools-technology-item-inner">
                      <div
                        className="risepro-tools-technology-content"
                        
                      >
                        <h2 className="industry-head-line">Smart City Software</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-6 col-md-12">
                    <div className="risepro-tools-technology-item-inner">
                      <div
                        className="risepro-tools-technology-content"
                        >
                        <p>
                          Using the latest technologies, cloud infrastructure and connected devices, cities are turning into smart cities. 
                          Utilize our latest smart city software development service which tackles the challenges like lights management, 
                          air pollution and flood risks by integrating sensors with smart city platforms. Our custom energy software solutions 
                          are easy to install and manage, facilitate citizen safety, reduce energy usage and generate dynamic reports  for analysis.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
          <div class="reinsurance-bottom-block">
               <ul class="inner-page-listing two-column">
                <li><p>Multiple types of IoT device integration on platform</p></li>  
                <li><p>Smart data insights for right actions and decisions</p></li>  
                <li><p>Certificate generation for secure communication</p></li>  
                <li><p>Configurable ETL for third party API integration</p></li>  
                <li><p>Device status, service & maintenance</p></li>  
                <li><p>Billing metrics based on the duration and usage</p></li>  
                <li><p>Use of IoT for alarm notification to concerned authority</p></li>  
                <li><p>Third party plug-in creation on platform</p></li>  
                <li><p>Graphical visualization of devices on Google map</p></li>  
                <li><p>Dashboard customization and data visualization</p></li>
               </ul>
            </div>
      </section>

     <section className="energy-bgimg2">
     <div class="container">
    <div class="industry-pension-fund-wrapper">
       
        <div className="row">
                  <div className="col-xl-5 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        
                      >
                        <h2 className="industry-head-line">Intelligent Street Lights Management</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        >
                        <p>
                          Intelligent street lighting is transforming the way cities and utility providers manage the needs. 
                          Smart lighting system allows full control and monitoring of each individual lighting unit using the ZIGBEE network. 
                          Installing such a system leads to better satisfaction of citizens, improved safety and financial savings.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
        <div class="pension-bottom-block pt-5">
            <ul class="inner-page-listing two-column">
                <li> <p> Node Installation & Authorization </p></li>
                <li> <p> Motorway Road Lighting Control System (Morlics Services) Support </p></li>
                <li> <p> Submaster setup in the system </p></li>
                <li> <p> Motion Sensor Based Street Lighting On/Off </p></li>
                <li> <p> Node & Submaster Communication via Zigbee Network </p></li>
                <li> <p> Real Time Network Status on Google Map </p></li>
                <li> <p> Overpower & Underpower Fault Management </p></li>
                <li> <p> Integration of Dali Commands </p></li>
                <li> <p> Submaster Management Via GPRS </p></li>
                <li> <p> Fault Trends, Heatmap & Rectifications </p></li>
                <li> <p> On-demand & Scheduled Lighting </p></li>
                <li> <p> Energy Consumption Analysis </p></li>
                <li> <p> Multiple Firmware Support </p></li>
                <li> <p> Talk Compliant CMS </p></li>
                <li> <p> Remote Firmware Upgrades </p></li>
                <li> <p> Mobile App with Intelligent Fault Finding </p></li>
            </ul>
        </div>
    </div>
</div>
     </section>

    <section>
    <div class="container">
    <div class="industry-pension-fund-wrapper">
       
        <div className="row">
                  <div className="col-xl-5 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        
                      >
                        <h2 className="industry-head-line">Electric Vehicle Charging App</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        >
                        <p>
                          With the growing demand of EVs, the need for charging stations is also increasing. 
                          EV charging apps have become necessary in order to find nearby charging stations and manage payments. 
                          Our  custom-built  apps offer powerful features which make vehicle charging, navigation and payment process hassle free for the consumers. 
                          Cloud servers integrate with ChargePoint using OCPI to provide quick charging and real time billing.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="reinsurance-bottom-block pt-5">
               <ul class="inner-page-listing two-column">
                <li> <p>Search for nearby charging stations and direction</p> </li>
                <li> <p>Low cost Rapid EV Charging via mobile app</p> </li>
                <li> <p>Ability to start and stop charge via mobile app.</p> </li>
                <li> <p>Real time charging progress on mobile app</p> </li>
                <li> <p>Charging and payment history</p> </li>
                <li> <p>Locate amenities near charging station</p> </li>
                <li> <p>Integration with ChargePoint using OCPI protocol</p> </li>
                <li> <p>Credit card or wallet account for drivers</p> </li>
                <li> <p>VAT receipt for each transaction</p> </li>
                <li> <p>Promotions, loyalty points management and redemption</p> </li>
                <li> <p>RFID cards for charging payments</p> </li>
                <li> <p>Dashboards and reporting for stakeholders</p> </li>
               </ul>
            </div>
    </div>
</div>
    </section>


   <section class="iotsection">
   <div class="text-center">
            <h2 className="text-white">Recloser Management Software</h2>
         </div>
      <div class="container bg-white p-5" style={{borderRadius:'16px'}}>
        
         <div class="industry-claim-policy-wrapper">
            <p>
                Automatic reclosers are key components for safety and security purposes when it comes to overhead power 
                distribution lines and smart grid applications. Web and mobile apps help in provisioning users with an easy 
                interface to navigate and manage these reclosers, analysing historical information, applying safety settings 
                and automation features.
            </p>
            <ul class="inner-page-listing two-column">
                <li><p> Reclosure management for power supply &amp; safety</p></li>
                <li><p> Configurable protection settings for safety</p></li>
                <li><p> Historical operation information for analysis</p></li>
                <li><p> Remote device configuration and control</p></li>
                <li><p> Simulation system for functionality testing</p></li>
                <li><p> Real time power supply and event review</p></li>
                <li><p> Reverse power supply management</p></li>
                <li><p> Smart grid automation configuration</p></li>
                <li><p> Communication support by IPV6, 4G and 5G</p></li>
                <li><p> GOOSE events for complex datasets transfer</p></li>
                <li><p> TCC curves configuration to avoid fire and damage due to overcurrent</p></li>
                <li><p> Supporting different protocols like DNP3, IEC 60870-5-101 and many more</p></li>
            </ul>
         </div>
      </div>
   </section>
   
   <section>
    <div class="container">
    <div class="industry-pension-fund-wrapper">
       
        <div className="row">
                  <div className="col-xl-5 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        
                      >
                        <h2 className="industry-head-line">Meter Reading Software</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-6 col-md-12">
                    <div className="">
                      <div
                        className="risepro-tools-technology-content"
                        >
                        <p>
                        Manual meter reading has its long list of shortcomings like inaccurate readings, requirement of huge manpower, high maintenance costs, etc. 
						In order to overcome these challenges, more reliable and robust web based meter reading portals were introduced that communicate with IoT data 
						controllers to fetch data from meters via GPS. These automated meter reading systems reduce human efforts, provide real time data and improve billing management.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="reinsurance-bottom-block pt-5">
               <ul class="inner-page-listing two-column">
                <li><p>Web portal for smart water meters</p></li>  
                <li><p>Utility companies configuration management</p></li>  
                <li><p>Real time monitoring of water consumption</p></li>  
                <li><p>IoT Data Collectors connected to water meters</p></li>  
                <li><p>Battery status and alerts for water meters & DC</p></li>  
                <li><p>Smart meter data consumption and alerts for recharge</p></li>  
                <li><p>Tariff, billing dates and frequency configuration</p></li>  
                <li><p>Manage site locations and consumers</p></li>  
                <li><p>Custom notifications for over consumption, billing etc.</p></li>  
                <li><p>Multi-tenant web portal for Utility companies</p></li>

               </ul>
            </div>
    </div>
</div>
    </section>
      <Footer/>
    </React.Fragment>
  );
};