export const xamarintech = {
  header : 'Xamarin App Development',
  content: `Being an experienced Xamarin development company, RaiseProSoft develops highly powerful cross-platform native mobile apps with the help of Xamarin which contributes in improving the operational efficiency, capitalizing on asset utilization and unlocking decisive business acumen of our clients.`,
  bgimg : '/images/xamrin-banner.webp',
  abouttech: `Backed by Microsoft, Xamarin is a robust platform that enables you to create functional apps across multiple ranges of platforms comprising that native look and feel. RaiseProSoft has years of experience in developing user friendly, result-driven and game-changing Xamarin applications. Over the years, we have succeeded in delivering solutions for a wide range of clients ranging from enthusiastic start-ups to established companies. We have a team of Xamarin app developers who are skilled and possess extensive knowledge regarding the platform. Our credibility and experience make us your go-to partner if you are looking for developing an application using the best software tools like Xamarin Insights and Xamarin Platform.`,
  servicesheader: 'Xamarin Development Services',
  servicecontent: `We deliver best-in-class secure, sustainable, customized and scalable Xamarin development services`,
  techexperiencebg : '/images/xamarin-development-experience-banner.webp',
  techexperience: 'Xamarin Development Experience',
  techexperiencecontent: 'We at RaiseProSoft end up offering nothing but the best Xamarin cross-platform application development solutions from our doorsteps.',
  advancestech: 'Advanced Xamarin Applications',
  advancestechcontent: `We specialize in offering advanced Xamarin Application to build compatible, cross-platform and custom configurable mobile applications.`
}
export const technologies = [
{
  title: "Xamarin Mobile App Development Consulting",
  img: "/images/xamarin_mobile_app_development_consulting.svg",
  description: "Our team consists of highly skilled Xamarin developers who offer reliable consultation services including analysis of business needs, creation of a proof of concept (POC) and development of Minimum Viable Product (MVP)."
},
{
  title: "Xamarin Application Design & Development",
  img: "/images/xamarin_application_design_development.svg",
  description: "We have developed top-rated apps for different industries and have expertise in managing multi-thread environment, crash free industry standard programming as well as providing architectural leadership."
},
{
  title: "UI/UX design",
  img: "/images/ui_ux_design.svg",
  description: "With user-centric and seamless app layout approach in mind, we ensure designing a smooth experience for your end-users. In addition, we pay great attention every step and design element involved."
},
{
  title: "Back-end engineering",
  img: "/images/back_end_engineering.svg",
  description: "Our team makes it easy to create a secure and scalable backend solution, implementing third-party API integrations along with taking care of the evolving business needs."
},
{
  title: "Testing & Maintenance",
  img: "/images/testing_maintenance.svg",
  description: "Once development is completed, we provide testing and bug fixing service as well as maintenance of apps as they require finetuning and on demand updates from users."
},
{
  title: "Team Augmentation",
  img: "/images/team_augmentation.svg",
  description: "We let your organization choose from our pool of resources and let you manage your requirements and team hassle free through direct communication with them."
}
]
export const techstack = [
{
  title: "Backend",
  icon: "/images/backend-blue-tech-ic.svg",
  items: [".NET", "Java", "PHP", "Node", "Ruby on Rails"]
},
{
  title: "Frontend",
  icon: "/images/frontend-blue-tech-ic.svg",
  items: ["Xaml", "C#"]
},
{
  title: "Database",
  icon: "/images/database-blue-tech-ic.svg",
  items: ["SQLite", "Realm", "CoreData", "Firebase"]
},
{
  title: "DevOps",
  icon: "/images/devops-blue-tech-ic.svg",
  items: ["CI/CD", "GitHub Actions"]
},
{
  title: "Testing",
  icon: "/images/testing-blue-tech-ic.svg",
  items: ["Appium", "Katalon Test Studio", "BrowserStack"]
},
{
  title: "Project Management Tools",
  icon: "/images/project-management-blue-tech-ic.svg",
  items: ["Jira", "Slack", "Trello", "Microsoft Team"]
}
];



export const services = [
   "Custom Xamarin applications",
   "Cross-platform development with iOS/Android SDK",
   "Enterprise application with deployment",
   "Mobile ERP, POS and M-commerce",
   "Augmented Reality apps",
   "SaaS based extensible application development",
   "Social networking app with instant messaging, video and voice",
   "Online Audio / Video Streaming Apps",
];

export const whyReproSoftData = [
{
  title: "Flexible Engagement Model",
  description: "We provide flexible engagement models using our skills for all types of software development projects assuring on-time delivery and agility.",
  imgSrc: "/images/why-raisepro-cycle-development.svg",
  altText: "code"
},
{
  title: "Consistent Delivery",
  description: "We combine our efforts with an agile and iterative approach to ensure timely deliveries despite tight deadlines, rework, and reschedules.",
  imgSrc: "/images/why-raisepro-agile.svg",
  altText: "agile"
},
{
  title: "On-Scale Demand",
  description: "We employ software developers into different projects and assignments as per the need. Let your unique business demand meet our multi-skilled specialists!",
  imgSrc: "/images/why-raisepro-domain.svg",
  altText: "process"
},
{
  title: "Advantageous Experience",
  description: "With multitude of projects handled and solutions catered to various industries, we have demonstrated excellent business results with our domain expertise.",
  imgSrc: "/images/why-raisepro-dedicated-team.svg",
  altText: "medal"
}
];


export const processSteps = [
  {
    step: "01",
    title: "Discovery",
    description: "Whether you are consulting us for the very first time or thinking of reviving your fledgling app, we will spend an adequate amount of time in assessing your project. We provide recommendations only after brainstorming different ideas, reviewing your business processes, architectural and infrastructural concerns.",
    class: "heading-part"
  },
  {
    step: "02",
    title: "UI/UX Design",
    description: "Whether you are planning to develop an interface for a brand-new mobile app or thinking of upgrading an existing one, our android application developers team provides you with a design that targets your key performance indicators and hits the right spots in terms of usability and appeal.",
    class: "heading-part green-box"
  },
  {
    step: "03",
    title: "Development",
    description: "After thriving for so long in an Agile development environment, we have gained a deep understanding and the importance of providing timely updates and deliverables to the client. We strongly believe in continuous delivery, update, and deployment of code.",
    class: "heading-part purple-box"
  },
  {
    step: "04",
    title: "API Integration",
    description: "Depending on the client requirements, we integrate their app with 3rd party and Custom API applications like Instagram, YouTube, payment gateways for carrying out secure transactions as well as numerous other applications and data sources.",
    class: "heading-part orange-box"
  },
  {
    step: "05",
    title: "Testing",
    description: "Quality assurance is a critical step that we take care of not only during the development lifecycle of the app but also post-development. We make sure that all types of necessary functional and non-functional testing are performed in the given lifecycle of app development.",
    class: "heading-part blue-box"
  },
  {
    step: "06",
    title: "Deployment",
    description: "Lastly, it's now time to deploy your app on Google Play Store and other similar platforms. We also assist in the app configuration process by taking care of various required factors. All other custom needs will be addressed before the final deployment of the app.",
    class: "heading-part light-green-box"
  },
  {
    step: "07",
    title: "Maintenance",
    description: "The job is not over once the app is deployed initially since maintenance, support, and optimization are required on a regular basis as we move along. One also needs to evolve and cater to user feedback they receive and keep deploying updated versions of the app.",
    class: "heading-part violet-box"
  }
];