import React from "react"
import { Header } from "../header"
import { Footer } from "../home/footer"
import { Hireteam } from "../development/hireteam"
import { javatech, services, technologies, techstack, whyReproSoftData } from "../../techutils.jsx/java"

import { GlobalPresence } from "./globalpresence"
import { Hiring } from "./hirings"
import { Solutions } from "./solutions"
import { ContactHire } from "./contactushiring"

export const Java =()=> {
   
    return(
        <React.Fragment>
            <Header />
          
  <div className="dot-net-development-wrapper ">
  <section className="img-background" style={{backgroundImage: `url(${javatech.bgimg})`}}>
    <div className="container text-white">
    <h1>{javatech.header}</h1>
    <p>{javatech.content}</p>
    </div>
  </section>
  <section className="repro-service-section ">
  <div className="container">
    <div className="inner-decription mb-4">
      <p>
       {javatech.abouttech}
      </p>
    </div>
    
    <div className="title-block text-center mb-5">
      <h2 className="mb-3">{javatech.servicesheader}</h2>
      <p>
      {javatech.servicecontent}
      </p>
    </div>
  </div>
  <div className="container">
    <div className="row">
      {technologies.map((item, index) => (
        <div
          key={index}
          className="col-lg-4 col-md-6 d-flex align-items-stretch mb-4"
        >
          <div className="card h-100 text-center border">
            <div className="card-body">
              <img
                src={item.img}
                alt={item.title}
                className="mb-3"
                style={{ width: "60px", height: "auto" }}
              />
              <h3 className="card-title h5">{item.title}</h3>
              <p className="card-text">{item.description}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
</section>
<ContactHire text={'a Java'} />

<section className="repro-app-dev-experience " style={{ backgroundImage: `url(${javatech.techexperiencebg})`,backgroundRepeat:'no-repeat' }}>
 
  <div className="container position-relative">
    <div className="text-center mb-5">
      <h2 className="text-white">{javatech.techexperience}</h2>
      <p className="text-white">
        {javatech.techexperiencecontent}
      </p>
    </div>
    <div className="repro-experience-wrapper bg-light p-4 rounded shadow-sm">
      <h3 className="mb-4 text-center">{javatech.advancestech}</h3>
      <div className="experience-content-wrapper">
        <p>
          {javatech.advancestechcontent}
        </p>
        <div class="experience-content-wrapper">
				
				<ul className="inner-page-listing two-column">
            {services.map((service, index) => (
                <li key={index}>
                    <p>{service}</p>
                </li>
            ))}
        </ul>
			</div>
      </div>
    </div>
  </div>
</section>

    {/* new start */}
    <section className="new-repro-technology-stack-section">
  <div className="container">
    <h2 className="text-center mb-5">Technology Stack</h2>
    <div >
      {techstack.map((category, index) => (
        <div key={index} >
            <div className="row">
                <div className="col-md-3 mb-5">
                <div className="techstack-title">
              <em className="me-3">
                <img src={category.icon} alt={category.title} />
              </em>
              <p className="m-0">{category.title}</p>
            </div>
          
                </div>
                <div className="col-md-9 mb-5">
                <div className="techstack-right">
              <ul className="list-unstyled">
                {category.items.map((item, i) => (
                  <li key={i} className="mb-2">
                    <i className="bi bi-check-circle-fill text-success me-2"></i>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
                </div>
            </div>
        </div>
      ))}
    </div>
  </div>
</section>


<Solutions/>
<section className="repro-why-section py-5">
  <div className="container">
    <h2 className="text-center mb-4">Why reproSoft?</h2>
    <div className="row justify-content-center">
      {whyReproSoftData.map((item, index) => (
        <div key={index} className="col-md-6 col-lg-3 mb-4">
          <div className="card h-100 text-center">
            <div className="card-body">
              <span className="d-inline-block mb-3">
                <img
                  alt={item.altText}
                  className="img-fluid"
                  width={60}
                  height={60}
                  src={item.imgSrc}
                />
              </span>
              <h5 className="card-title">{item.title}</h5>
              <p className="card-text">{item.description}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
</section>


<Hiring/>
   <Hireteam title={'Java Developers'}/>
   <GlobalPresence />
  </div>

<Footer/>

        </React.Fragment>
    )
}