import { Link } from "react-router-dom"

export const Solutions =()=> {
    return (
      <section class="pt-0">
      <div class="container">
        <div class="text-center mb-4">
          <h2>Delivering the Right Solution</h2>
          <p>
            We excel in delivering the best solution as per the custom needs, be it small start-ups in their ideation phase
            or mid-size businesses focusing on growth or large enterprises actively optimizing processes across varied
            industries.
          </p>
        </div>
        <div class="row align-items-center">
          <div class="col-md-4 mb-4 mb-md-0">
          <ul className="list-unstyled industry-links">
               <li>
                  <Link to="/banking-finance-insurance-software-solutions" title="Fintech & Insurance">Fintech & Insurance <i className="fa fa-arrow-right"></i> </Link>
               </li>
               <li>
                  <Link to="/oil-gas-mining-software-solutions" title="Oil & Gas, Mining">Oil & Gas, Mining <i className="fa fa-arrow-right"></i> </Link>
               </li>
               <li>
                  <Link to="/education-software-solutions" title="Education">Education <i className="fa fa-arrow-right"></i> </Link>
               </li>
               <li>
                  <Link to="/retail-software-solutions" title="Retail & Ecommerce">Retail & Ecommerce <i className="fa fa-arrow-right"></i> </Link>
               </li>
               <li>
                  <Link to="/energy-utilities-software-solutions" title="Energy and Utilities">Energy and Utilities <i className="fa fa-arrow-right"></i> </Link>
               </li>
               <li>
                  <Link to="/shipping-transportation-logistics-software-solutions" title="Logistics & Distribution">Logistics & Distribution <i className="fa fa-arrow-right"></i> </Link>
               </li>
               <li>
                  <Link to="/healthcare-software-solutions" title="Healthcare">Healthcare <i className="fa fa-arrow-right"></i> </Link>
               </li>
               <li>
                  <Link to="/travel-hospitality-software-solutions" title="Travel & Hospitality">Travel & Hospitality <i className="fa fa-arrow-right"></i> </Link>
               </li>
               <li>
                  <Link to="/media-entertainment-software-solutions" title="Media & Entertainment">Media & Entertainment <i className="fa fa-arrow-right"></i> </Link>
               </li>
               <li>
                  <Link to="/public-sector" title="Public Sector">Public Sector <i className="fa fa-arrow-right"></i> </Link>
               </li>
               </ul>
          </div>
          <div class="col-md-8 text-center">
              <img src="/images/customer-logo.png" alt="Customers" class="img-fluid" />
          </div>
        </div>
      </div>
    </section>
    
    )
}