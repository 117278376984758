import { useEffect, useState } from "react";

export const BlinktextAnimation=()=> {
    
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [displayedText, setDisplayedText] = useState("");
    const [isDeleting, setIsDeleting] = useState(false);
    // const [isLoading, setIsLoading] = useState(false); 
    useEffect(() => {
      const words = ["Think", "Emerger", "Connect","Hustle"];
      const currentWord = words[currentWordIndex];
      let typingSpeed = isDeleting ? 50 : 150; // Faster when deleting
  
      const typeEffect = setTimeout(() => {
        if (!isDeleting) {
          // Typing effect: Add one letter at a time
          setDisplayedText((prev) => currentWord.slice(0, prev.length + 1));
          if (displayedText === currentWord) {
            setTimeout(() => setIsDeleting(true), 1000); // Pause before deleting
          }
        } else {
          // Deleting effect: Remove one letter at a time
          setDisplayedText((prev) => prev.slice(0, -1));
          if (displayedText === "") {
            setIsDeleting(false);
            setCurrentWordIndex((prev) => (prev + 1) % words.length); // Move to next word
          }
        }
      }, typingSpeed);
  
      return () => clearTimeout(typeEffect);
    }, [displayedText, isDeleting, currentWordIndex]);
    return (
                <div className="typewriter">
                  <span className="typed-text"><span style={{color:'#ffb11b'}}>{displayedText.slice(0,1)}</span>{displayedText.substring(1)}</span>
                  <span className="cursor">|</span>
                </div>
    )
}